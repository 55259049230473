import { DatePicker, Modal, Radio, message, Select } from 'antd'
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

import { IconExecuteMotionNow, IconExecuteMotionSchedule } from 'components/common/Icons/Icons'
import {
  cronDaysOfWeek,
  defaultRefreshSegmentIncludeOptions,
  motionExecutionModalCadenceSelectionClass,
  refreshSegmentDisabledOption,
} from 'components/MotionExecutionModal/index.utils'
import MotionExecutionModalFooter from 'components/MotionExecutionModal/MotionExecutionFooter'
import { useDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import { DateService, MonthFormatEnum } from 'services/Utils/date'
import useStore from 'store/useStore'

import type { RangePickerProps } from 'antd/lib/date-picker'
import type { Dayjs } from 'dayjs'

import type {
  MotionExecute,
  MotionSchedule,
  RefreshSegmentCheckboxInterface,
  CheckboxValueType,
} from 'models/motion.model'
import { RefreshSegmentOptions } from 'models/motion.model'

// Default the TZ to UTC explicitly for DatePicker & any dayjs objects instansiated in this component
dayjs.extend(utc)
dayjs.extend(timezone)

const MotionExecutionModal = observer(() => {
  const { motionStore } = useStore()
  const { displayExecuteModal, motionToExecute, get, getAll, setDisplayExecuteModal, createOrUpdateMotionSchedule } =
    motionStore

  useDisplayErrorNotification(motionStore)

  /**
   * Cadence is whether the Motion is a once off, or a mutli-run execution
   * Options are:
   *   - once
   *   - multiple
   */
  const [motionExecutionCadence, setMotionExecutionCadence] = useState<'once' | 'multiple'>('once')

  /**
   * Schedule is whether the Motion runs immediately, or on a cron-like schedule
   * Options are:
   *   - immediately
   *   - scheduled
   */
  const [motionExecutionSchedule, setMotionExecutionSchedule] = useState('immediately')

  const [scheduleDateTime, setScheduleDateTime] = useState<Dayjs | null>(null)
  const [multipleRunRecurringCadence, setMultipleRunRecurringCadence] = useState<
    'daily' | 'weekdaily' | 'weekly' | 'monthly'
  >('weekly')

  const [refreshSegment, setRefreshSegment] = useState<boolean>(true)
  const [refreshSegmentIncludeOptions, setRefreshSegmentIncludeOptions] = useState<RefreshSegmentCheckboxInterface[]>(
    defaultRefreshSegmentIncludeOptions,
  )
  const [selectedRefreshSegmentIncludeOptions, setSelectedRefreshSegmentIncludeOptions] = useState<CheckboxValueType[]>(
    [RefreshSegmentOptions.ACCOUNTS],
  )

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handleRefreshSegmentIncludeOptionsChange = (checkedValues: CheckboxValueType[]) => {
    setSelectedRefreshSegmentIncludeOptions(checkedValues)

    /**
     * If both checkboxes are ticked, it means we want to include _everything_ in the segment at point in time i.e. refreshSegment = false
     * If <= 1 checkboxes are ticked, it means we want to perform some calculation with the previous segments and the current segment to get the delta
     */

    if (checkedValues.length === 1 && checkedValues[0] === RefreshSegmentOptions.USERS) {
      setRefreshSegment(true)
      setRefreshSegmentIncludeOptions(refreshSegmentDisabledOption)
      setSelectedRefreshSegmentIncludeOptions([])
    } else if (checkedValues.length <= 1) {
      setRefreshSegment(true)
      setRefreshSegmentIncludeOptions(defaultRefreshSegmentIncludeOptions)
    } else {
      setRefreshSegment(false)
    }
  }

  const handleOnExecute = async () => {
    if (motionToExecute === null) {
      void message.error('Oops, looks like you have not selected a Motion', 4)
      return
    }

    setIsLoading(true)

    const { playbookId, version } = motionToExecute

    if (motionExecutionCadence === 'once' && motionExecutionSchedule === 'immediately') {
      // For one time executions and immediate start, hit the /execute endpoint
      const executeData: MotionExecute = {
        playbookId,
        version,
        executionCadence: motionExecutionCadence,
        refreshSegment: false,
        includePreviousAccounts: false,
        includePreviousUsers: false,
      }

      try {
        await motionStore.execute(executeData)
        void message.success('Successfully updated Motion to begin executing', 4)
        // Refresh the Motions to prevent the user from having to manually refresh the entire application
        await getAll(true)
      } catch {
      } finally {
        setDisplayExecuteModal(false, null)
        setIsLoading(false)
      }
    } else {
      if (motionExecutionSchedule === 'scheduled') {
        // Validate whether datetime has been selected
        if (scheduleDateTime === null) {
          void message.error('You must select a specific date time for the start of your Motion')
          setIsLoading(false)
          return
        }

        // We already have validation that prevents the user from selecting a date on the datepicker in the past (or end of current day)
        // Validate the datetime selected is not in the past nor is it within the next 10mins
        const currentDateTimePlusTenMins = dayjs().utc().add(10, 'minutes')
        if (scheduleDateTime.isBefore(currentDateTimePlusTenMins)) {
          void message.error(
            'You must select a date in the future, or use immediately for dates in the next 10 minutes',
          )
          setIsLoading(false)
          return
        }
      }

      // For all other scenarios, we need to hit the schedule endpoint
      if (scheduleDateTime !== null) {
        const payload: MotionSchedule = {
          playbookId,
          version,
          cronSchedule: {
            enabled: true,
            minutes: scheduleDateTime.minute(),
            hours: scheduleDateTime.hour(),
            dayOfMonth: scheduleDateTime.date(),
            dayOfWeek: cronDaysOfWeek[scheduleDateTime.day()],
            month: DateService.months(MonthFormatEnum.Short)[scheduleDateTime.month()],
            executionCadence: motionExecutionCadence,
            refreshSegment: false,
            includePreviousAccounts: false,
            includePreviousUsers: false,
          },
        }

        // If the user selects multiple run & start immediately, add 5 minutes onto the start time
        // To allow for the backend resources to be provisioned
        if (motionExecutionSchedule === 'immediately' && motionExecutionCadence === 'multiple') {
          const currentTimePlusFiveMins = dayjs().utc().add(5, 'minutes')
          payload.cronSchedule.minutes = currentTimePlusFiveMins.minute()
          payload.cronSchedule.hours = currentTimePlusFiveMins.hour()
        }

        if (motionExecutionCadence === 'multiple') {
          payload.cronSchedule.recurringCadence = multipleRunRecurringCadence
          payload.cronSchedule.refreshSegment = refreshSegment
          payload.cronSchedule.includePreviousAccounts = selectedRefreshSegmentIncludeOptions.some(
            (option: CheckboxValueType) => option === RefreshSegmentOptions.ACCOUNTS,
          )
          payload.cronSchedule.includePreviousUsers = selectedRefreshSegmentIncludeOptions.some(
            (option: CheckboxValueType) => option === RefreshSegmentOptions.USERS,
          )
        }

        try {
          await createOrUpdateMotionSchedule(payload)

          // Refresh the Motions to prevent the user from having to manually refresh the entire application
          await getAll(true)
          await get({ playbookId: payload.playbookId, version: Number(payload.version) })

          void message.success('Successfully scheduled Motion for execution', 4)
          setDisplayExecuteModal(false, null)
          setIsLoading(false)
        } catch (error: unknown) {
          void message.error('Schedule of execution has failed, please try again', 4)
          setIsLoading(false)
        }
      }
    }
  }

  const handleModalClose = () => {
    // Reset state when modal closes
    setMotionExecutionCadence('once')
    setMotionExecutionSchedule('immediately')
    setScheduleDateTime(null)
  }

  const handleOneTimeRunClick = () => {
    setMotionExecutionCadence('once')
    setScheduleDateTime(null)
  }

  const handleMultipleRunClick = () => {
    setMotionExecutionCadence('multiple')
    setScheduleDateTime(dayjs().utc())
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current.toDate() < dayjs().utc().endOf('day').toDate()
  }

  return (
    <Modal
      className='motion-execution-modal'
      title='Motion Schedule'
      open={displayExecuteModal}
      centered
      onOk={handleOnExecute}
      afterClose={handleModalClose}
      onCancel={() => setDisplayExecuteModal(false, null)}
      footer={
        <MotionExecutionModalFooter
          submitButtonIsLoading={isLoading}
          submitButtonDisabled={motionExecutionSchedule === 'scheduled' && scheduleDateTime === null}
          setDisplayExecuteModal={setDisplayExecuteModal}
          onExecute={handleOnExecute}
          displayRefreshSegmentToggle={motionExecutionCadence === 'multiple'}
          refreshSegmentIncludeOptions={refreshSegmentIncludeOptions}
          selectedRefreshSegmentIncludeOptions={selectedRefreshSegmentIncludeOptions}
          handleRefreshSegmentIncludeOptionsChange={handleRefreshSegmentIncludeOptionsChange}
        />
      }>
      <div>
        <p>Please define the running schedule for the Motion</p>
      </div>
      <div className='motion-execution-modal-cadence-container'>
        <div
          onClick={handleOneTimeRunClick}
          className={
            motionExecutionCadence === 'once'
              ? `${motionExecutionModalCadenceSelectionClass}-active`
              : motionExecutionModalCadenceSelectionClass
          }
          data-testid='motion-execution-one-time-run'>
          <IconExecuteMotionNow />
          One-time run
        </div>
        <div
          data-testid='motion-execution-cadence-multiple'
          onClick={handleMultipleRunClick}
          className={
            motionExecutionCadence === 'multiple'
              ? `${motionExecutionModalCadenceSelectionClass}-active`
              : motionExecutionModalCadenceSelectionClass
          }>
          <IconExecuteMotionSchedule />
          Multiple runs
        </div>
      </div>
      <div className='motion-execution-modal-schedule-container'>
        <Radio.Group
          onChange={(event) => setMotionExecutionSchedule(event.target.value as string)}
          value={motionExecutionSchedule}>
          <Radio data-testid='motion-execution-immediately' value={'immediately'}>
            Start immediately
          </Radio>
          <Radio data-testid='motion-execution-scheduled' value={'scheduled'}>
            Schedule a specific point to start
          </Radio>
        </Radio.Group>
      </div>
      <div className='motion-execution-modal-schedule-detail-container'>
        {motionExecutionSchedule === 'scheduled' && (
          <div className='motion-execution-modal-schedule-entity-container'>
            <label className='motion-execution-modal-schedule-label'>First start on (UTC)</label>
            <DatePicker
              style={{ borderRadius: 4, height: 40, width: 270 }}
              disabledDate={disabledDate}
              onChange={(value) => (value !== null ? setScheduleDateTime(value.utc()) : setScheduleDateTime(null))}
              onOk={(value) => setScheduleDateTime(value.utc())}
              showNow={false}
              format='YYYY-MM-DD HH:mm'
              showTime={{ format: 'HH:mm' }}
              value={scheduleDateTime}
            />
          </div>
        )}
        {motionExecutionCadence === 'multiple' && (
          <div className='motion-execution-modal-schedule-entity-container'>
            <label className='motion-execution-modal-schedule-label'>Recurring Frequency</label>
            <div>
              <Select
                className='motion-execution-modal-schedule-entity-select'
                defaultValue='weekly'
                style={{ borderRadius: 4, width: 190 }}
                onChange={(value: 'daily' | 'weekdaily' | 'weekly' | 'monthly') =>
                  setMultipleRunRecurringCadence(value)
                }>
                <Select.Option value='daily'>Every Day</Select.Option>
                <Select.Option value='weekdaily'>Every Weekday</Select.Option>
                <Select.Option value='weekly'>Every Week</Select.Option>
                <Select.Option value='monthly'>Every Month</Select.Option>
              </Select>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
})

export default MotionExecutionModal
