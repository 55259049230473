import { observer } from 'mobx-react-lite'
import { useContext, useEffect } from 'react'

import { SegmentBuilder } from 'components/MotionBuilder/SegmentBuilder/SegmentBuilder'
import Flow from 'components/MotionTarget/Flow'
import { addNode, moveElementsPosition } from 'components/MotionTarget/motionTarget.utils'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

interface MotionTargetProps {
  onShowDrawer: (data: any) => void
}

export const MotionTarget = observer(({ onShowDrawer }: MotionTargetProps) => {
  const { motionStore, mergePanelStore } = useStore()
  const { droppedElement, dragOverElement, setDroppedElement, goUp, goDown } = motionStore

  const { elements, setElements, setElementsWithOptimizedPositions } = useContext(SegmentBuilderContext)

  // Move elements position based on hover over edge droppable section
  useEffect(() => {
    if (dragOverElement && (goDown || goUp)) {
      const updatedPositionElements = moveElementsPosition(elements, dragOverElement, goDown)
      setElements(updatedPositionElements)
    }
  }, [goUp, goDown])

  useEffect(() => {
    if (droppedElement) {
      const newStateElements = addNode(elements, droppedElement, {
        onShowDrawer,
        setElements: setElementsWithOptimizedPositions,
      })

      setElementsWithOptimizedPositions(newStateElements)
      setDroppedElement(null)
      mergePanelStore.setMergeBasedOnElements(newStateElements)
    }
  }, [droppedElement])

  return (
    <div className='motion-flow'>
      <div className='motion-flow__wrapper'>
        <SegmentBuilder />
        <div className='motion-flow__container'>
          <div className='motion-flow__section'>
            <Flow />
          </div>
        </div>
      </div>
    </div>
  )
})
MotionTarget.displayName = 'MotionTarget'
