import { useMemo } from 'react'
import isEqual from 'utils/isEqual'

import { isDSLDifferent, isOperatorAndActionDSLDifferent, isSegmentDSLDifferent } from 'pages/Motions/Motion.utils'
import { reactFlowToDSL } from 'services/Utils/dslConversion/reactFlowToDSL/reactFlowToDSL.utils'
import useStore from 'store/useStore'

import type { Elements } from 'react-flow-renderer'

/**
 * A custom react hook that provides state and functions for checking the possible differences in a Motion's state
 * @param {Elements<any>} elements The elements of the Motion flow.
 * @returns {Object} An object containing the state and functions for checking the differences.
 */
const useCheckMotionDifferences = (elements: Elements<any>) => {
  const { motionStore, motionGoalsStore } = useStore()

  /**
   * Returns an array of boolean values indicating if there are any differences between the Motion DSL
   * and the local DSL in terms of overall structure, segment structure, operation and action structure.
   * @returns {boolean[]} An array of 3 boolean values, corresponding to hasOverallDSLChanged, hasSegmentDSLChanged, hasOperationAndActionDSLChanged.
   */
  const [hasOverallDSLChanged, hasSegmentDSLChanged, hasOperationAndActionDSLChanged] = useMemo(() => {
    if (!motionStore.currentMotion) {
      return [true, true, true]
    }

    const dsl = { ...motionStore.currentMotion.dsl }
    const localDSL = reactFlowToDSL({
      elements,
      aggregations: motionStore.currentMotion?.dsl?.aggregations,
    })

    const overallDSLDifferent = isDSLDifferent(dsl, localDSL)
    const segmentDSLDifferent = isSegmentDSLDifferent(dsl, localDSL)
    const actionDSLDifferent = isOperatorAndActionDSLDifferent(dsl, localDSL)

    return [overallDSLDifferent, segmentDSLDifferent, actionDSLDifferent]
  }, [elements, motionStore.currentMotion])

  /**
   * Returns a boolean value indicating if there are any differences between the Motion title or description and the current Motion goals.
   * @returns {boolean} A boolean value corresponding to hasTitleOrDescriptionChanged.
   */
  const hasTitleOrDescriptionChanged = useMemo(() => {
    const isTitleChanged = motionGoalsStore.currentMotionGoals.title !== motionStore.currentMotion?.title

    const isDescriptionChanged =
      motionGoalsStore.currentMotionGoals.description !== motionStore.currentMotion?.description

    return isTitleChanged || isDescriptionChanged
  }, [motionGoalsStore.currentMotionGoals, motionStore.currentMotion])

  /**
   * Returns a boolean value indicating if there are any differences between the motionGoalsStore currentMotion goals
   * and the motionStore's currentMotion goals.
   * @returns {boolean} A boolean value corresponding to hasGoalsChanged.
   */
  const hasGoalsChanged = !isEqual(motionGoalsStore.currentMotionGoals.goal, motionStore.currentMotion?.goal || {})

  /**
   * Returns a boolean value indicating if there are any differences between the Motion metrics and the current Motion goals.
   * @returns {boolean} A boolean value corresponding to hasMetricsChanged.
   */
  const hasMetricsChanged = !isEqual(
    motionGoalsStore.currentMotionGoals.metrics,
    motionStore.currentMotion?.metrics || [],
  )

  return {
    hasOverallDSLChanged,
    hasSegmentDSLChanged,
    hasOperationAndActionDSLChanged,
    hasTitleOrDescriptionChanged,
    hasGoalsChanged,
    hasMetricsChanged,
  }
}

export default useCheckMotionDifferences
