import { Skeleton } from 'antd'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useMemo } from 'react'

import { Breadcrumbs } from 'components/common/Breadcrumb'
import ViewList from 'components/common/DataSourceContainer/ViewList'
import { Search } from 'components/common/Search/Search'
import { ModalContext } from 'store/ModalContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { MetadataField } from 'models/motion/dynamicInput.model'
import type { CreateActionFields, Item } from 'models/motion/motionBuilder.model'
import { NodeTypeEnum } from 'models/motion.model'

interface DataSourceContainerProps {
  currentItem: Item | CreateActionFields
  handleSelectDataField: (item: MetadataField) => void
}

const DataSourceContainer = observer(({ currentItem, handleSelectDataField }: DataSourceContainerProps) => {
  const { segmentBuilderData } = useContext(SegmentBuilderContext)
  const { dynamicInputStore, motionStore } = useStore()
  const {
    applyLocalMetadataFilter,
    breadCrumbItems,
    dynamicInputsMetadataOptions,
    error: dynamicInputError,
    fetchSearch,
    getViewList,
    resetSearch,
    searchInput,
    searchViewList,
    setBreadCrumbItems,
    setDisplaySearchResult,
    setErrorVisibility,
    setFilteredViewList,
    setLoadingSearchMetadata,
    setSearchInput,
    viewList,
  } = dynamicInputStore
  const { setCurrentDynamicInputModalKey, setIsDynamicInputModalOpen } = useContext(ModalContext)

  const isAction = useMemo(() => segmentBuilderData.type === NodeTypeEnum.Action, [segmentBuilderData.type])
  const isLoadingList = viewList.isLoading || dynamicInputsMetadataOptions.isLoading || searchViewList.isLoading

  useEffect(() => {
    setFilteredViewList(viewList.data)
  }, [viewList.data])

  const handleResetBreadcrumbs = () => {
    if (isLoadingList) {
      return
    }

    setBreadCrumbItems([])
    getViewList({}, isAction).catch(console.error)
    setErrorVisibility(false)
  }

  const handleSelectOption = async (item: MetadataField) => {
    if (searchViewList.isVisible) {
      await getViewList(
        {
          platform: item.platform,
          object: item.object,
          solutionInstanceId: item.solutionInstanceId,
          ...(item.magnifyDisplayName && { magnifyDisplayName: item.magnifyDisplayName }),
        },
        isAction,
      )
      resetSearch()

      return
    }

    switch (item.type) {
      case 'platform':
        await getViewList({ platform: item.name, solutionInstanceId: item.solutionInstanceId }, isAction)
        break
      case 'object':
        await getViewList(
          {
            platform: breadCrumbItems[0].name,
            object: item.name,
            ...(item.magnifyDisplayName && { magnifyDisplayName: item.magnifyDisplayName }),
          },
          isAction,
        )
        break
      default:
        await getViewList({}, isAction)
        break
    }
  }

  const handleSelectBreadcrumb = async (index: number) => {
    if (isLoadingList) {
      return
    }
    breadCrumbItems.splice(index + 1)

    await getViewList(
      {
        platform: (breadCrumbItems.length && breadCrumbItems[0].name) || undefined,
        object: (breadCrumbItems.length > 1 && breadCrumbItems[1].name) || undefined,
        ...(breadCrumbItems[1]?.magnifyDisplayName && { magnifyDisplayName: breadCrumbItems[1].magnifyDisplayName }),
      },
      isAction,
    )
    setSearchInput('')
  }

  const filter = async (inputValue: string) => {
    setErrorVisibility(false)

    if (!inputValue.length) {
      setDisplaySearchResult(false)
    } else {
      await fetchSearch(inputValue)
      const displaySearchResult = !applyLocalMetadataFilter()
      setDisplaySearchResult(displaySearchResult)
    }
  }

  const setDisplay = (value: boolean) => {
    setDisplaySearchResult(value)
    setLoadingSearchMetadata(false)
    if (!value && dynamicInputError.isVisible) {
      setErrorVisibility(false)
    }
  }

  const handleClose = () => {
    setSearchInput('')
    setErrorVisibility(false)
    if (!applyLocalMetadataFilter()) {
      setBreadCrumbItems([])
      getViewList({}, isAction).catch(console.error)
    } else {
      setFilteredViewList(viewList.data)
    }

    setDisplay(false)
  }

  const handleSelectField = (item: MetadataField) => {
    handleSelectDataField({
      key: item.key,
      field: item.name,
      type: item.type,
      platform: item.platform,
      object: item.object,
      solutionInstanceId: item.solutionInstanceId,
      ...(item.magnifyDisplayName && { magnifyDisplayName: item.magnifyDisplayName }),
    })

    // Set the current DynamicInputModal key for the current field
    if (currentItem.key) {
      if (setCurrentDynamicInputModalKey) {
        setCurrentDynamicInputModalKey(currentItem.key)
      }
      // Open the DynamicInputModal with the selected field
      if (setIsDynamicInputModalOpen && segmentBuilderData.type === NodeTypeEnum.Action) {
        setIsDynamicInputModalOpen(true)
      }
    }
  }

  return (
    <div className='segment-sidebar' data-testid='data-source-container'>
      <Search
        placeholder='Search data'
        onFilter={filter}
        searchType={['onEnter', 'onClick']}
        setDisplay={setDisplay}
        searchInput={searchInput}
        setSearchInput={(value: string) => setSearchInput(value)}
        onClose={handleClose}
        isDisabled={motionStore.isSegmentBuilderEditDisabled}
        dataTestId='dynamic-input-search'
      />

      {!searchViewList.isVisible && (
        <Breadcrumbs
          disabled={isLoadingList}
          breadCrumbItems={breadCrumbItems}
          handleSelectBreadcrumb={handleSelectBreadcrumb}
          handleResetBreadcrumbs={handleResetBreadcrumbs}
        />
      )}

      {isLoadingList ? (
        [...(Array(5) as undefined[])].map((_, index) => (
          <div key={index} className='skeleton-container' data-testid='skeleton-container'>
            <Skeleton.Input active block />
          </div>
        ))
      ) : (
        <ViewList
          currentItem={currentItem}
          handleSelectOption={handleSelectOption}
          handleSelectField={handleSelectField}
          breadCrumbItems={breadCrumbItems}
        />
      )}
    </div>
  )
})

export default DataSourceContainer
