import { Input } from 'antd'

import { IconSearch } from 'components/common/Icons/Icons'

interface SidebarSearchProps {
  prefix?: React.JSX.Element
  placeholder?: string
  onChangeHandler: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const SidebarSearch = ({ prefix, placeholder, onChangeHandler }: SidebarSearchProps) => {
  const icon = prefix || <IconSearch />

  return (
    <Input
      placeholder={placeholder || 'Search'}
      prefix={icon}
      onChange={onChangeHandler}
      allowClear
      data-testid='search'
    />
  )
}
SidebarSearch.displayName = 'SidebarSearch'
