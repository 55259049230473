import { Alert, Tooltip, Spin } from 'antd'
import dayjs from 'dayjs'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import { useMemo, useState } from 'react'

import { Button, IconInfo, IconSave } from 'components/common'
import { downloadSegment } from 'components/MotionBuilder/Utils/segmentBuilder.utils'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import useStore from 'store/useStore'

import type { Motion } from 'models/motion.model'

export const SegmentExportHistory = observer(() => {
  const { demo } = useFlags<LaunchDarklyFeatureFlags>()
  const { motionStore } = useStore()
  const segmentExports = motionStore.currentMotion?.segmentExports || []
  const [isLoadingId, setIsLoadingId] = useState<string>('')

  const handleDownloadSegment = async (filename: string, segmentExportId: string) => {
    setIsLoadingId(segmentExportId)
    await downloadSegment(filename)
    setIsLoadingId('')
  }

  const handleSegmentExportsRefresh = async () => {
    if (demo) return

    const playbookId = (motionStore.currentMotion as Motion)?.playbookId
    const version = motionStore.currentMotion?.version

    if (playbookId && version) {
      await motionStore.getSegmentExports(playbookId, version)
    }
  }

  const hasMotionBeenSaved = motionStore?.currentMotion?.version && motionStore?.currentMotion?.version > 0

  const sortedSegmentExports = useMemo(() => {
    if (!segmentExports) return []

    return segmentExports.slice().sort((a, b) => {
      if (!a.timestamp || !b.timestamp) return 0
      return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    })
  }, [segmentExports])

  return (
    <div className='segment-export-history'>
      {!hasMotionBeenSaved && (
        <Alert
          className='segment-export-history__info'
          message='You must save a motion first to view Segment Export History. Segment exports in unsaved motions will appear in the Notifications page.'
          type='info'
          showIcon
          icon={<IconInfo />}
        />
      )}
      <div className='segment-export-history__heading'>
        <p className='segment-export-history__heading__title'>Calculated Segments</p>
        <p className='segment-export-history__heading__body'>
          Validate your segment configuration by selecting Export Segment to verify the participants that would be
          included with this segment definition if ran today. Refresh to view the latest state.
        </p>
      </div>

      {segmentExports.length > 0 && (
        <div className='segment-export-history__refresh'>
          <Button
            link
            text='Refresh'
            size='M'
            onClickHandler={handleSegmentExportsRefresh}
            className=''
            loading={motionStore.isSegmentExportsLoading}
          />
        </div>
      )}

      <Spin spinning={motionStore.isSegmentExportsLoading}>
        <div className='segment-export-history__table'>
          {segmentExports.length < 1 ? (
            <p>Segment exports will appear here once they have been calculated.</p>
          ) : (
            <>
              <div className='segment-export-history__table__head'>
                <p>Last calculated</p>
                <p>Executed by</p>
              </div>
              <div className='segment-export-history__table__body'>
                {sortedSegmentExports.map((segmentExport) => {
                  if (!segmentExport) return null

                  const formattedDate = segmentExport.timestamp
                    ? dayjs(segmentExport.timestamp).format('MMM DD, YYYY [at] h:mm A')
                    : ''

                  return (
                    <div className='segment-export-history__table__body__row' key={segmentExport.id}>
                      <p>{formattedDate}</p>
                      <p className='segment-export-history__table__body__row__executed-by'>
                        {segmentExport.requestedBy}
                      </p>
                      {segmentExport.status === 'FAILED' ? (
                        <Tooltip placement='topLeft' title={segmentExport.message || ''}>
                          <div className='segment-export-history__table__body__row__action segment-export-history__table__body__row__action--failed'>
                            <IconInfo />
                            <p>Failed</p>
                          </div>
                        </Tooltip>
                      ) : segmentExport.status === 'PENDING' ? (
                        <p className='segment-export-history__table__body__row__action segment-export-history__table__body__row__action--pending'>
                          Processing
                        </p>
                      ) : (
                        <Button
                          link
                          icon={{ element: <IconSave />, position: 'left' }}
                          text='Download'
                          size='L'
                          onClickHandler={() => handleDownloadSegment(segmentExport.path || '', segmentExport.id)}
                          loading={isLoadingId === segmentExport.id}
                          className='segment-export-history__table__body__row__action'
                        />
                      )}
                    </div>
                  )
                })}
              </div>
            </>
          )}
        </div>
      </Spin>
    </div>
  )
})
SegmentExportHistory.displayName = 'SegmentExportHistory'
