import { Form, Input, Spin, Alert } from 'antd'
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { Button, Card, Heading, IconMagnifyLogoBlue } from 'components/common'

export function ResetPass() {
  const [codeWasSent, setCodeWasSent] = useState<boolean>(false)
  const [newPassordWasSet, setNewPassordWasSet] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<Error | null>(null)
  const navigate = useNavigate()

  async function magnifyResetPassword(values: { email: string; code: string; newPassword: string }) {
    const { email, code, newPassword } = values

    if (codeWasSent) {
      try {
        setLoading(true)

        await confirmResetPassword({
          username: email,
          confirmationCode: code,
          newPassword,
        })

        setError(null)
        setCodeWasSent(false)
        setNewPassordWasSet(true)
        setLoading(false)

        navigate(`/signin`)
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error)
        }
        setLoading(false)
      }
    } else {
      try {
        setLoading(true)
        await resetPassword({ username: email })

        setError(null)
        setCodeWasSent(true)
        setLoading(false)
      } catch (error: unknown) {
        if (error instanceof Error) {
          setError(error)
        }
        setLoading(false)
      }
    }
  }

  return (
    <div className='login c-reset-pass' data-testid='reset-pass'>
      <IconMagnifyLogoBlue height={100} />
      <Card className='c-reset-pass__container' variant='2'>
        <Spin size='large' spinning={loading} data-testid='loading-spinner'>
          <Form layout='vertical' onFinish={magnifyResetPassword}>
            <Heading level='3' variant='3'>
              Reset Password
            </Heading>

            <Form.Item label='Email' name='email' rules={[{ required: true, message: 'Please input your email!' }]}>
              <Input placeholder='Your email address' type='email' autoComplete='username' data-testid='email' />
            </Form.Item>

            {codeWasSent && (
              <Form.Item
                label='Password reset code'
                name='code'
                rules={[{ required: true, message: 'Please input the reset code!' }]}>
                <Input placeholder='Enter the code we sent you by email' data-testid='code' />
              </Form.Item>
            )}

            {codeWasSent && (
              <Form.Item
                label='New password'
                name='newPassword'
                rules={[{ required: true, message: 'Please input your new password!' }]}>
                <Input.Password placeholder='Your new password' data-testid='newPassword' />
              </Form.Item>
            )}
            {error && <Alert message={error.message} type='error' />}
            {newPassordWasSet && (
              <Alert message='Password Reset successful!' type='success' data-testid='new-password-set-message' />
            )}
            {codeWasSent && (
              <Alert
                message='We have e-mailed your password reset code!'
                type='success'
                data-testid='code-was-sent-message'
              />
            )}
            {!newPassordWasSet && (
              <Button
                text='Reset Password'
                htmlType='submit'
                style={{ minWidth: '200px', display: 'block', margin: '0 auto' }}
                testId='submit'
              />
            )}

            <p className='c-reset-pass__sign-in'>
              <Link to='/signin'>Sign in</Link>
            </p>
          </Form>
        </Spin>
      </Card>
    </div>
  )
}
