import { Breadcrumb } from 'antd'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

import { getBreadcrumb } from 'components/common/Breadcrumb/Breadcrumb.utils'
import { IconBurgerMenu } from 'components/common/Icons/Icons'

import type { BreadcrumbInfo } from 'models/motion/motionBuilder.model'

interface BreadcrumbsProps {
  breadCrumbItems: BreadcrumbInfo[]
  handleSelectBreadcrumb: (index: number) => void
  handleResetBreadcrumbs: () => void
  disabled?: boolean
}

const breadcrumbMenu = (breadcrumbItems: BreadcrumbInfo[], handleSelectBreadcrumb: (index: number) => void) => {
  const menuItems = breadcrumbItems.map((item, index) => ({
    key: item.name,
    label: <li onClick={() => handleSelectBreadcrumb(index + 2)}>{item.name}</li>,
  }))

  return menuItems
}

export const Breadcrumbs = ({
  breadCrumbItems = [],
  handleSelectBreadcrumb,
  handleResetBreadcrumbs,
  disabled,
}: BreadcrumbsProps) => {
  const [breadcrumbs, setBreadcrumbs] = useState<React.JSX.Element[]>()
  const [isDashboardDisplayed, setIsDashboardDisplayed] = useState<boolean>(false)
  const menuItemsList: BreadcrumbInfo[] = []

  useEffect(() => {
    setIsDashboardDisplayed(!!breadCrumbItems.length)

    const uiBreadcrumbs: React.JSX.Element[] = []
    breadCrumbItems.forEach((breadcrumb, index) => {
      const isDisplayedInDropdown = breadCrumbItems.length > 3 && index >= 2 && index !== breadCrumbItems.length - 1
      const isTheLastBreadcrumbElement =
        breadCrumbItems.length > 3 && index >= 2 && index === breadCrumbItems.length - 1

      if (isDisplayedInDropdown) {
        menuItemsList.push(breadcrumb)
      } else {
        uiBreadcrumbs.push(getBreadcrumb({ breadcrumb, index, handleSelectBreadcrumb }))
      }
      if (isTheLastBreadcrumbElement) {
        uiBreadcrumbs.push(
          <Breadcrumb.Item
            menu={{ items: breadcrumbMenu(menuItemsList, handleSelectBreadcrumb), triggerSubMenuAction: 'click' }}
            key='menu-item'
            data-testid='ellipsis-item'>
            ...
          </Breadcrumb.Item>,
          getBreadcrumb({ breadcrumb, index, handleSelectBreadcrumb }),
        )
      }
    })

    setBreadcrumbs(uiBreadcrumbs)
  }, [breadCrumbItems, disabled])

  return (
    <Breadcrumb className={classNames('breadcrumb', { 'breadcrumb--disabled': disabled })} data-testid='breadcrumb'>
      {isDashboardDisplayed && (
        <Breadcrumb.Item key='dashboard-btn' className='breadcrumb__item' data-testid='breadcrumb-item'>
          <button type='button' onClick={handleResetBreadcrumbs}>
            <IconBurgerMenu />
          </button>
        </Breadcrumb.Item>
      )}
      {breadcrumbs}
    </Breadcrumb>
  )
}
Breadcrumbs.displayName = 'Breadcrumbs'
