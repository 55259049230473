import { NodeTypeEnum } from 'models/motion.model'

// The size between nodes on x y axis (pixels)
export const FRAGMENT_Y = 210
export const FRAGMENT_X = 500

// Initial segment position
export const INITIAL_X_POSITION = 380
export const INITIAL_Y_POSITION = 50

export const INITIAL_DSL = {
  startAt: 'segment1',
  states: {
    segment1: {
      type: NodeTypeEnum.Segment,
      payload: {},
      description: 'Segment',
      position: {
        x: INITIAL_X_POSITION,
        y: INITIAL_Y_POSITION,
      },
      end: true,
    },
  },
}
