/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
import { Drawer, Row, Button as AntButton, Spin, Tabs, Alert } from 'antd'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import { Fragment, useContext, useEffect, useState } from 'react'
import { useStoreState } from 'react-flow-renderer'
import { isSlackActionObject } from 'utils/type-helpers'

import {
  IconClose,
  IconCaretRight,
  IconTrash,
  IconDynamicInput,
  IconGear,
  IconInfo,
} from 'components/common/Icons/Icons'
import FakeActionCheckbox from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/common/FakeActionCheckbox'
import {
  getStatusFreeNodes,
  unmarkTargetNodes,
} from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/LoopPanel/LoopActionUtils'
import { ConfigurationPanel } from 'components/MotionBuilder/SegmentBuilder/ConfigurationPanel/ConfigurationPanel'
import { SegmentBottom } from 'components/MotionBuilder/SegmentBuilder/SegmentBottom/SegmentBottom'
import { SegmentDescription } from 'components/MotionBuilder/SegmentBuilder/SegmentDescription/SegmentDescription'
import { SegmentExportHistory } from 'components/MotionBuilder/SegmentBuilder/SegmentExportHistory/SegmentExportHistory'
import { postProcessNode } from 'components/MotionBuilder/Utils/processNodes'
import {
  getDrawerSize,
  defaultPanelSizes,
  doesCriteriaContainEmptyInputs,
  getListOfAggregationsUsedAcrossMotion,
  checkRequiredFields,
  showRequiredFieldsError,
  checkEmailFields,
  showEmailFieldsError,
} from 'components/MotionBuilder/Utils/segmentBuilder.utils'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import { BuilderIcon } from 'services/Utils/BuilderIcon'
import { openNotificationPopup } from 'services/Utils/openNotificationPopup'
import { ModalContext } from 'store/ModalContext'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { TabsProps } from 'antd'
import type { Dispatch, SetStateAction } from 'react'
import type { Node } from 'react-flow-renderer'

import type {
  ConfigPanelPayload,
  LoopPayload,
  PayloadData,
  SegmentBuilderData,
  SegmentExportData,
} from 'models/motion/motionBuilder.model'
import { ActionEnum } from 'models/motion/motionBuilder.model'
import type { Motion } from 'models/motion.model'
import { NodeTypeEnum } from 'models/motion.model'
import type { CreateSlackMessagePayload } from 'models/slack-messages'

export const SegmentBuilder = observer(() => {
  const { excludeParticipants, segmentExportHistory, demo } = useFlags<LaunchDarklyFeatureFlags>()

  const { elements, setElements, segmentBuilderData, setSegmentBuilderData, reset, setReset } =
    useContext<SegmentBuilderStore>(SegmentBuilderContext)

  const nodes: Node<SegmentBuilderData>[] = useStoreState((state) => state.nodes) as Node<SegmentBuilderData>[]
  const edges = useStoreState((state) => state.edges)
  const [loading, setLoading] = useState(false)
  const [isDynamicInputModalOpen, setIsDynamicInputModalOpen] = useState(false)
  const [currentDynamicInputModalKey, setCurrentDynamicInputModalKey] = useState('')
  const [segmentBuilderTabKey, setSegmentBuilderTabKey] = useState('1')

  const {
    aggregationsDataStore,
    metadataStore,
    loopPanelStore,
    motionStore,
    mergePanelStore,
    emailTemplatesStore,
    slackMessagesStore,
  } = useStore()
  const { metadata, get: getMetadataStore, initMetadata } = metadataStore
  const {
    data: { template },
  } = emailTemplatesStore
  const { updateMessage, createMessage } = slackMessagesStore
  // Rather than setup a duplicate structure, we use the data directly and add a wrapper to ensure the old setter still works.
  const { payload } = segmentBuilderData
  const setPayload: Dispatch<SetStateAction<ConfigPanelPayload>> = (
    newPayload: ((payload: ConfigPanelPayload) => ConfigPanelPayload) | ConfigPanelPayload,
  ) => {
    if (typeof newPayload === 'function') {
      setSegmentBuilderData((prev) => ({
        ...prev,
        payload: newPayload(prev.payload),
      }))
      return
    }
    setSegmentBuilderData((prev) => ({
      ...prev,
      payload: newPayload,
    }))
  }

  const onCloseDrawer = () => {
    motionStore.setConfigPanelNode(null)
  }

  const [isPayloadEmpty, setIsPayloadEmpty] = useState<boolean>(true)
  const [panelSize, setPanelSize] = useState(defaultPanelSizes.small)
  const [isToggleActive, setIsToggleActive] = useState(false)

  const currentNode = nodes.find((node) => node.id === segmentBuilderData.nodeId) as Node<SegmentBuilderData>
  const isSmallDrawer = getDrawerSize(segmentBuilderData)
  const isLoop = segmentBuilderData.type === NodeTypeEnum.Loop

  useEffect(() => {
    if (!metadata.data.length) {
      getMetadataStore().catch(console.error)
    }
  }, [])

  useEffect(() => {
    motionStore.setIsSegmentBuilderEditDisabled(
      motionStore.isInMotionReportingEnabled && segmentBuilderData.type !== NodeTypeEnum.Segment,
    )
  }, [motionStore.isInMotionReportingEnabled, segmentBuilderData.type])

  useMetadataDisplayErrorNotification(metadataStore)

  useEffect(() => {
    if (aggregationsDataStore.currentUuid) {
      aggregationsDataStore.setCurrentUsedAggregations(
        getListOfAggregationsUsedAcrossMotion({
          elements,
          elementPayload: (segmentBuilderData.payload as LoopPayload).exitCondition || segmentBuilderData.payload,
          payload: (payload as LoopPayload).exitCondition || payload,
          aggregationUuid: aggregationsDataStore.currentUuid,
        }),
      )

      if (aggregationsDataStore.currentUsedAggregations?.length) {
        aggregationsDataStore.setDisplayRemoveModal(true)
      } else {
        aggregationsDataStore.removeItemFromAggregationsList(aggregationsDataStore.currentUuid)
        aggregationsDataStore.setCurrentUuid(null)
      }
    }
  }, [aggregationsDataStore.currentUuid])

  useEffect(() => {
    resize(isSmallDrawer)
  }, [isSmallDrawer])

  useEffect(() => {
    if (motionStore.configPanelNode) {
      initMetadata()
    }
  }, [motionStore.configPanelNode])

  useEffect(() => {
    const disableCriteriaBtns = doesCriteriaContainEmptyInputs(payload as PayloadData)
    setIsPayloadEmpty(disableCriteriaBtns)
  }, [payload])

  useEffect(() => {
    // If we want to reset explicitly, we set the reset flag, and unset it here.
    if (reset) {
      setReset(false)
      setPayload({} as ConfigPanelPayload)
    }
  }, [reset])

  const handleCloseDrawer = () => {
    onCloseDrawer()
    motionStore.setFocusedNode(null)
    if (isSmallDrawer) {
      setPanelSize(defaultPanelSizes.small)
    } else {
      setPanelSize(defaultPanelSizes.medium)
    }
    setIsToggleActive(false)
    resetLoopState()
    resetMergeState()
  }

  const handleResize = () => {
    setIsToggleActive((prevState) => !prevState)
    resize(isSmallDrawer)
  }

  /** Save the data from the config panel to the node and close the drawer. */
  const saveConfigData = async (shouldCloseDrawerOnFinish = true) => {
    if (motionStore.isSegmentBuilderEditDisabled) return onCloseDrawer()

    const updatedNode = { ...currentNode }
    // The updatedNode.data is out of sync with the segmentBuilderData, so we need to update it.
    // These console.log calls are useful for finding out which data is being updated incorrectly.
    // If you see data in currentNode.data that does not match segmentBuilderData but should, we are not setting that data correctly.
    // Once all data is migrated to be stored in segmentBuilderData, we can remove these console.log calls, and the use of currentNode.data.
    // console.log('currentNode.data', toJS(currentNode.data))
    // console.log('segmentBuilderData', toJS(segmentBuilderData))
    updatedNode.data = {
      ...currentNode.data,
      ...segmentBuilderData,
      payload: {
        ...currentNode?.data?.payload,
        ...segmentBuilderData.payload,
      },
    }
    // console.log('updatedNode.data', toJS(updatedNode.data))

    // Validate the payload before saving by checking required fields.
    if (updatedNode.data.payload?.fields) {
      // START SLACK MESSAGE SPECIAL CASE
      const isSlackAction = updatedNode.data.platform === 'slack'

      if (!demo && isSlackAction) {
        const payloadFields = updatedNode.data.payload.fields
        const slackMessageId = payloadFields?.find((field) => field.key === 'messageId')?.value || null
        const slackActionObject = payloadFields?.find((field) => field.key === 'messageContent')
        let messageContent = null
        let dynamicInputs = {}
        if (isSlackActionObject(slackActionObject?.value)) {
          messageContent = slackActionObject.value.messageContent
          dynamicInputs = slackActionObject.value.dynamicInputs
        }

        if (
          messageContent &&
          messageContent !== payload.fields?.find((field: Record<string, any>) => field.key === 'messageContent')?.value
        ) {
          const savedTemplate = await saveMessage((slackMessageId as string) ?? '', {
            messageContent: messageContent ?? '',
            dynamicInputs,
          } as CreateSlackMessagePayload)

          const slackMessageIdRef = payloadFields?.find((field) => field.key === 'messageId')
          const slackRef = payloadFields?.find((field) => field.key === 'messageContent')
          const slackMessageVersionRef = payloadFields?.find((field) => field.key === 'messageVersion')

          if (slackMessageIdRef && slackRef && slackMessageVersionRef && savedTemplate) {
            slackMessageIdRef.value = savedTemplate.slackMessageId
            slackMessageVersionRef.value = savedTemplate.slackMessageVersion
            // Remove the Slack message from the DSL since it is stored externally.
            // However, we still require a value for this field.
            slackRef.value = `${savedTemplate.slackMessageId}-${savedTemplate.slackMessageVersion}`
          }
        }
      }
      // END SLACK MESSAGE SPECIAL CASE

      setSegmentBuilderData((prev) => ({
        ...prev,
        payload: updatedNode.data!.payload,
      }))
      const isUpdateAction = updatedNode.data.action === ActionEnum.Update

      // Validate email addresses
      const emailFieldError = checkEmailFields(updatedNode)
      if (emailFieldError.length) {
        showEmailFieldsError(emailFieldError)

        setSegmentBuilderData((prev) => ({
          ...prev,
          payload: updatedNode.data!.payload,
        }))
        return
      }

      // Update actions don't have any required field
      if (!isUpdateAction) {
        const requiredFieldsErrors = checkRequiredFields(updatedNode)
        if (requiredFieldsErrors.length) {
          showRequiredFieldsError(requiredFieldsErrors)

          setSegmentBuilderData((prev) => ({
            ...prev,
            payload: updatedNode.data!.payload,
          }))
          return
        }
      }
    }

    motionStore.setFocusedNode(null)
    // Special case for merges, when a merge is selected only the mobx store knows about it
    if (updatedNode.data.type === NodeTypeEnum.Merge) {
      const targets = mergePanelStore.merges.get(segmentBuilderData.nodeId)?.map((target) => target.nodeId)
      updatedNode.data.payload.targets = targets?.filter((target) => !!target) as string[]
    }

    // Preprocess the node data to be displayed in the config panel.
    setLoading(true)
    updatedNode.data = await postProcessNode(updatedNode.data)
    setLoading(false)

    const nodesWithoutCurrentOne = nodes.filter((node) => node.id !== currentNode?.id)
    setElements([...nodesWithoutCurrentOne, updatedNode, ...edges])
    if (shouldCloseDrawerOnFinish) onCloseDrawer()
    resetLoopState()
    resetMergeState()
  }

  /** Resize the panel between medium & small if `isSmallDrawer` is true, otherwise medium & large. */
  const resize = (isSmallDrawer: boolean) => {
    const { small, medium, large } = defaultPanelSizes
    let newPanelSize = medium
    if (isSmallDrawer && panelSize === medium) {
      newPanelSize = small
    } else if (panelSize === medium) {
      newPanelSize = large
    }
    setPanelSize(newPanelSize)
  }

  const resetLoopState = () => {
    if (isLoop && loopPanelStore.isSelectingTarget) {
      const cleanElements = getStatusFreeNodes(nodes)
      loopPanelStore.setLoop(segmentBuilderData.id)
      loopPanelStore.setIsSelectingTarget(false)
      loopPanelStore.setCurrentSourceLoopId(null)
      setElements([...cleanElements, ...edges])
    }
  }

  const resetMergeState = () => {
    if (mergePanelStore.isSelectingTarget) {
      // cleanup store and nodes state as target/invalid
      mergePanelStore.setIsSelectingTarget(false)
      const cleanElements = unmarkTargetNodes(nodes)
      const focusedMergeId = mergePanelStore.currentSourceMergeId
      const mergeState = mergePanelStore.merges.get(focusedMergeId ?? '')

      if (focusedMergeId && mergeState) {
        const cleanState = mergeState?.map((action) => {
          if (action.isActive) {
            return { ...action, isActive: false, actionText: '' }
          }
          return { ...action }
        })
        mergePanelStore.merges.set(focusedMergeId, cleanState)
      }

      setElements([...cleanElements, ...edges])
    }
  }

  const handleOpenDynamicInputs = () => {
    if (segmentBuilderData.type === NodeTypeEnum.Action) {
      setIsDynamicInputModalOpen(true)
    }
  }

  const handleRemove = () => {
    motionStore.setCurrentNode(currentNode)
    motionStore.setDisplayRemoveModal(true)
    resetMergeState()
  }

  /** Save the current template. */
  const saveMessage = async (slackMessageId: string | undefined, payload: CreateSlackMessagePayload) => {
    // Check for and remove any dynamicInputs that are no longer in the slack message.
    const dynamicInputsToRemove = Object.keys(payload.dynamicInputs).filter(
      (key) => !payload.messageContent.includes(key),
    )
    dynamicInputsToRemove.forEach((key) => delete payload.dynamicInputs[key])

    let refreshTemplateData
    if (slackMessageId) {
      const updatedTemplate = await updateMessage({
        slackMessageId,
        payload: { ...payload },
      })
      if (updatedTemplate) {
        setSegmentBuilderData((prev) => ({
          ...prev,
          payload: payload as ConfigPanelPayload,
        }))
      }

      refreshTemplateData = updatedTemplate
    } else {
      const newTemplate = await createMessage({ ...payload })

      refreshTemplateData = newTemplate
    }

    return refreshTemplateData
  }

  const handleExportSegment = async () => {
    const { playbookId, version } = motionStore.currentMotion as Motion
    const aggregations = aggregationsDataStore.aggregationsList

    const segmentExportData: SegmentExportData = {
      journeyName: motionStore.currentMotion?.title,
      type: segmentBuilderData.type,
      payload,
      playbookId,
      version,
      aggregations,
      excludedUserIds: segmentBuilderData.excludedUserIds || [],
    }

    try {
      const response = await motionStore.generateSegmentOutput(segmentExportData)

      if (motionStore.segmentOutput.status !== 200) {
        openNotificationPopup('Problem with segment export', motionStore.segmentOutput.message, 'error')
      } else {
        openNotificationPopup('Segment export in progress', motionStore.segmentOutput.message, 'success')
        motionStore.setSegmentExportsLocally(response.segmentExports)
        if (segmentExportHistory) setSegmentBuilderTabKey('2')
      }
    } catch (error: unknown) {
      openNotificationPopup('Something went wrong trying to export the segment to a CSV', '', 'error')
    }

    motionStore.setDisplaySegmentOutputModal(false)
    await saveConfigData(!segmentExportHistory)
  }

  /** If we are loading, show the spinner, otherwise render nothing. */
  const LoadingIndicator = loading ? Spin : Fragment

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Configure',
      children: (
        <LoadingIndicator {...(loading ? { tip: 'Saving...' } : {})}>
          {segmentBuilderData.type === NodeTypeEnum.Segment && (
            <div className='segment-builder__info'>
              <Alert
                message='Once you select a field from Data Source, drag and drop to define the criteria'
                type='info'
                showIcon
                icon={<IconInfo />}
              />
            </div>
          )}

          <div className='segment-builder__configuration-panel'>
            <ConfigurationPanel saveConfigData={saveConfigData} payload={payload} setPayload={setPayload} />
          </div>
        </LoadingIndicator>
      ),
    },
    {
      key: '2',
      label: 'Exports',
      children: (
        <LoadingIndicator {...(loading ? { tip: 'Saving...' } : {})}>
          <SegmentExportHistory />
        </LoadingIndicator>
      ),
    },
  ]

  return (
    <ModalContext.Provider
      value={{
        isDynamicInputModalOpen,
        setIsDynamicInputModalOpen,
        currentDynamicInputModalKey,
        setCurrentDynamicInputModalKey,
      }}>
      <div id='segment-builder' data-testid='segment-builder'>
        <Drawer
          placement='right'
          open={!!motionStore.configPanelNode}
          size='large'
          width={panelSize}
          rootClassName={classNames('segment-builder', {
            'not-expanded': !isToggleActive,
          })}
          mask={false}
          closable={false}
          footer={
            <div className='expand-trigger'>
              <div
                className={classNames('expand', {
                  'expand--active': isToggleActive,
                })}>
                <AntButton data-testid='expand__btn' onClick={handleResize} className='expand__btn'>
                  <IconCaretRight></IconCaretRight>
                </AntButton>
              </div>
            </div>
          }>
          <Row>
            <div className='heading grow'>
              <BuilderIcon name={segmentBuilderData.iconName ?? segmentBuilderData.action} />
              <h1 className='segment__name' data-testid='panel-name'>
                {segmentBuilderData.name} {segmentBuilderData.type === NodeTypeEnum.Segment && 'Builder'}
              </h1>
            </div>
            {segmentBuilderData.type === NodeTypeEnum.Action && (
              <div
                className='icon__box icon__box--square'
                onClick={handleOpenDynamicInputs}
                data-testid='panel-dynamic-inputs'>
                <IconDynamicInput />
                <IconGear />
              </div>
            )}
            {!motionStore.isSegmentBuilderEditDisabled && segmentBuilderData.type !== NodeTypeEnum.Segment && (
              <div className='icon__box icon__box--square' onClick={handleRemove} data-testid='panel-remove'>
                <IconTrash />
              </div>
            )}
            <div className='icon__box icon__box--circle' onClick={handleCloseDrawer} data-testid='panel-close'>
              <IconClose />
            </div>
          </Row>

          <div className='segment-builder__content'>
            {segmentBuilderData.type === NodeTypeEnum.Segment && (excludeParticipants || segmentExportHistory) ? (
              <Tabs
                key={segmentBuilderTabKey}
                activeKey={segmentBuilderTabKey}
                items={items}
                onChange={(key) => setSegmentBuilderTabKey(key)}
              />
            ) : (
              <LoadingIndicator {...(loading ? { tip: 'Saving...' } : {})}>
                <ConfigurationPanel saveConfigData={saveConfigData} payload={payload} setPayload={setPayload} />
              </LoadingIndicator>
            )}
          </div>

          <div className='configuration-panel__bottom'>
            <SegmentDescription />
            <FakeActionCheckbox />

            <SegmentBottom
              handleSegmentExport={handleExportSegment}
              payload={payload}
              setPayload={setPayload}
              isPayloadEmpty={isPayloadEmpty}
              template={template}
              isDynamicInputModalOpen={isDynamicInputModalOpen}
              setIsDynamicInputModalOpen={setIsDynamicInputModalOpen}
              currentDynamicInputModalKey={currentDynamicInputModalKey}
              setCurrentDynamicInputModalKey={setCurrentDynamicInputModalKey}
              handleReset={handleRemove}
              saveConfigData={saveConfigData}
            />
          </div>
        </Drawer>
      </div>
    </ModalContext.Provider>
  )
})
SegmentBuilder.displayName = 'SegmentBuilder'
