import { Button, InputNumber, Layout, Row, Select } from 'antd'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'
import { useStoreState } from 'react-flow-renderer'

import { EMPTY_GROUPS_PAYLOAD } from 'components/common'
import { DropdownArrowDown, IconActionTarget, IconRemoveTarget } from 'components/common/Icons/Icons'
import { DataSourcePanel } from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/DataSourcePanel/DataSourcePanel'
import {
  getElementsWithoutLoopStatus,
  unmarkTargetNodes,
  getValidLoopNodes,
} from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/LoopPanel/LoopActionUtils'
import { BuilderIcon } from 'services/Utils/BuilderIcon'
import { clone } from 'services/Utils/misc'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction } from 'react'
import type { Node } from 'react-flow-renderer'

import type {
  ConfigPanelPayload,
  LoopPayload,
  PayloadData,
  SegmentBuilderData,
  unitsValue,
} from 'models/motion/motionBuilder.model'
import type { NodeState } from 'models/motion.model'
import { NodeTypeEnum } from 'models/motion.model'

interface LoopPanelProps {
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

export const LoopPanel = observer(({ payload, setPayload }: LoopPanelProps) => {
  const { loopPanelStore, motionStore } = useStore()

  const { segmentBuilderData, setSegmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)

  const { setElements } = segmentBuilderData

  const [exitCondition, setExitCondition] = useState<PayloadData>(payload.exitCondition || EMPTY_GROUPS_PAYLOAD)

  const initialPayload: LoopPayload = {
    timeOfLoop: 0,
    amount: 0,
    units: 'hours',
    exitCondition,
  }

  const nodes: Node<SegmentBuilderData>[] = useStoreState((state) => state.nodes) as Node<SegmentBuilderData>[]
  const edges = useStoreState((state) => state.edges)

  const currentLoopId = segmentBuilderData.nodeId
  const loopTargetId = loopPanelStore.loops.get(currentLoopId)
  const isLoopToActionActive = loopPanelStore.isSelectingTarget && !loopTargetId

  useEffect(() => {
    // set initial payload
    setPayload({ ...initialPayload, ...clone(segmentBuilderData.payload) })
    if (segmentBuilderData.targetNodeId) {
      loopPanelStore.setLoopToActionBasedOnId(nodes, segmentBuilderData.targetNodeId)
    }
    if (segmentBuilderData.payload?.exitCondition?.groups?.length) {
      setExitCondition(clone(segmentBuilderData.payload.exitCondition))
      loopPanelStore.setShowExitCondition(true)
    } else {
      loopPanelStore.setShowExitCondition(false)
    }
  }, [])

  useEffect(() => {
    loopPanelStore.setLoop(currentLoopId, segmentBuilderData.targetNodeId || loopTargetId)
    loopPanelStore.setLoopToActionBasedOnId(nodes, segmentBuilderData.targetNodeId || loopTargetId || '')
  }, [segmentBuilderData])

  useEffect(() => {
    setPayload((prevState: ConfigPanelPayload) => {
      return { ...prevState, exitCondition: exitCondition }
    })
  }, [exitCondition])

  useEffect(() => {
    const currentLoop: Node<NodeState> | undefined = nodes.find((node) => node.id === currentLoopId)
    const currentLoopTargetId: string | undefined = currentLoop?.data?.targetNodeId

    if (currentLoopTargetId) {
      // Set button view after nodes updated
      loopPanelStore.setLoopToActionBasedOnId(nodes, currentLoopTargetId)
    } else if (loopPanelStore.isSelectingTarget) {
      loopPanelStore.setLoopToActionBasedOnCase('selecting')
    } else {
      loopPanelStore.setLoopToActionBasedOnCase('initial')
    }
  }, [nodes])

  useEffect(() => {
    if (segmentBuilderData.targetNodeId) {
      // Set button view initial
      loopPanelStore.setLoopToActionBasedOnId(nodes, segmentBuilderData.targetNodeId)
    } else if (loopTargetId) {
      loopPanelStore.setLoopToActionBasedOnId(nodes, loopTargetId)
    } else {
      loopPanelStore.setLoopToActionBasedOnCase('initial')
    }
  }, [loopPanelStore.isSelectingTarget])

  const handleToggleExitCondition = () => {
    if (motionStore.isSegmentBuilderEditDisabled) return

    if (loopPanelStore.showExitCondition) {
      setExitCondition(EMPTY_GROUPS_PAYLOAD)
    }
    loopPanelStore.setShowExitCondition(!loopPanelStore.showExitCondition)
  }

  const handleChangeTimeOfLoop = (value: number | null) => {
    setPayload((prev) => {
      return { ...prev, timeOfLoop: value as number }
    })
  }

  const handleChangeAmount = (value: number | null) => {
    setPayload((prev) => {
      return { ...prev, amount: value as number }
    })
  }

  const handleChangeDelayTimeUnits = (value: string) => {
    setPayload((prevState: ConfigPanelPayload) => {
      return { ...prevState, units: value as unitsValue }
    })
  }

  const handleChooseAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    if (!loopPanelStore.isSelectingTarget) {
      const currentNode: Node<SegmentBuilderData> | undefined = nodes.find((element) => element.id === currentLoopId)
      if (!currentNode) {
        return
      }

      const edgesWithoutLoopEdge = edges.filter((edge) => edge.type !== NodeTypeEnum.Loop)
      const elementsWithoutLoopEdge = [...nodes, ...edgesWithoutLoopEdge]
      // Display valid targets
      const updatedNodes = getValidLoopNodes({
        currentNode,
        elements: elementsWithoutLoopEdge,
        loopId: currentLoopId,
        nodes,
      })
      loopPanelStore.setLoop(currentLoopId)
      loopPanelStore.setCurrentSourceLoopId(currentLoopId)
      setElements([...updatedNodes, ...edges])
      loopPanelStore.setIsSelectingTarget(true)
    }
  }

  const handleRemoveAction = (e: React.MouseEvent<HTMLOrSVGElement>) => {
    e.preventDefault()

    const currentNode = nodes.find((element) => element.id === currentLoopId)
    if (!currentNode) {
      return
    }

    loopPanelStore.setLoop(currentLoopId)
    loopPanelStore.setIsSelectingTarget(false)
    const cleanElements = getElementsWithoutLoopStatus({
      nodes,
      edges,
      targetNodeId: loopTargetId || segmentBuilderData.targetNodeId || '',
      currentLoopId,
    })
    setElements(cleanElements)
    setSegmentBuilderData((prev) => {
      delete prev.targetNodeId
      return { ...prev }
    })
  }

  const handleChooseActionOnBlur = () => {
    const cleanElements = unmarkTargetNodes(nodes)
    loopPanelStore.setIsSelectingTarget(false)

    setElements([...cleanElements, ...edges])
    setTimeout(() => {
      loopPanelStore.setCurrentSourceLoopId(null)
    }, 300)
  }

  return (
    <Layout style={{ marginTop: 30 }}>
      <div className='config-panel-container'>
        <div className='loop-rules'>
          <div className='top-line'>
            <div className='times-container'>
              <span className='input-title'>Number of times to loop</span>
              <InputNumber
                key='timeOfLoop'
                name='timeOfLoop'
                data-testid='timeOfLoop'
                className='config-panel-container__input config-panel-container__input--hide-addon'
                controls={{
                  upIcon: <DropdownArrowDown />,
                  downIcon: <DropdownArrowDown />,
                }}
                min={0}
                step={1}
                precision={0}
                value={payload?.timeOfLoop}
                onChange={handleChangeTimeOfLoop}
                disabled={motionStore.isSegmentBuilderEditDisabled}
                addonAfter={<></>}
              />
            </div>

            <div className='interval-container'>
              <span className='input-title'>Interval</span>
              <InputNumber
                key='amount'
                name='amount'
                data-testid='amount'
                className='config-panel-container__input'
                controls={{
                  upIcon: <DropdownArrowDown />,
                  downIcon: <DropdownArrowDown />,
                }}
                min={0}
                step={1}
                precision={0}
                disabled={motionStore.isSegmentBuilderEditDisabled}
                value={payload?.amount}
                onChange={handleChangeAmount}
                addonAfter={
                  <Select
                    data-testid='amount-units'
                    defaultValue={payload?.units || 'hours'}
                    value={payload?.units}
                    suffixIcon={<DropdownArrowDown />}
                    dropdownAlign={{ offset: [0, 8] }}
                    onChange={handleChangeDelayTimeUnits}
                    popupClassName='group-container__item-container__item__select-input-range__dropdown'
                    style={{
                      width: 148,
                    }}
                    disabled={motionStore.isSegmentBuilderEditDisabled}>
                    <Select.Option value='minutes'>Minutes</Select.Option>
                    <Select.Option value='hours'>Hours</Select.Option>
                    <Select.Option value='days'>Days</Select.Option>
                    <Select.Option value='weeks'>Weeks</Select.Option>
                  </Select>
                }
              />
            </div>
          </div>

          <div className='top-line'>
            <div className='loop-action'>
              <span className='input-title'>Loop to action</span>
              <Row
                className={classNames({
                  active: isLoopToActionActive,
                  'has--target': loopPanelStore.loopAction.iconName,
                })}
                data-testid='loop-to-action-container'>
                <Button
                  onClick={handleChooseAction}
                  className='action__btn'
                  onBlur={handleChooseActionOnBlur}
                  data-testid='loop-to-action'
                  disabled={motionStore.isSegmentBuilderEditDisabled}>
                  {loopPanelStore.loopAction.iconName && (
                    <BuilderIcon
                      name={`${loopPanelStore.loopAction.iconName}`}
                      options={{ width: 20, height: 20, draggable: false, className: 'target--icon' }}
                    />
                  )}
                  {loopPanelStore.loopAction.text}
                  <IconActionTarget className='action__icon' />
                </Button>
                <IconRemoveTarget className='remove__btn' onClick={handleRemoveAction} data-testid='remove-action' />
              </Row>
            </div>
          </div>
        </div>

        <span
          className={classNames('exit-condition-toggle', {
            'exit-condition-toggle--disabled': motionStore.isSegmentBuilderEditDisabled,
            'exit-condition-toggle--destructive': loopPanelStore.showExitCondition,
          })}
          onClick={handleToggleExitCondition}>
          {`${loopPanelStore.showExitCondition ? 'Remove' : 'Add'} exit condition`}
        </span>

        {loopPanelStore.showExitCondition && (
          <DataSourcePanel
            payload={exitCondition as ConfigPanelPayload}
            setPayload={setExitCondition as Dispatch<SetStateAction<ConfigPanelPayload>>}
          />
        )}
      </div>
    </Layout>
  )
})
LoopPanel.displayName = 'LoopPanel'
