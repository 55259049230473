import { Tooltip } from 'antd'
import classNames from 'classnames'

import type { variant } from 'models'
interface CalloutProps {
  variant?: variant
  className?: string
  style?: React.CSSProperties
  tooltipText?: string
  children?: React.JSX.Element | React.JSX.Element[] | string
}

export const Callout = ({ variant, className, style, tooltipText, children }: CalloutProps) => {
  const calloutClass = classNames('c-callout', {
    [`c-callout--${variant || '1'} ${className ? className : ''}`]: true,
  })

  return (
    <Tooltip placement='right' title={tooltipText}>
      <label className={calloutClass} style={style} data-testid='callout'>
        {children}
      </label>
    </Tooltip>
  )
}
Callout.displayName = 'Callout'
