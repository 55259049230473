import { createContext, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import EmptyState from 'components/AccountDetails/EmptyState'
import Tab from 'components/AccountDetails/MachineLearningMetrics/Tab'
import TabContent from 'components/AccountDetails/MachineLearningMetrics/TabContent'
import Tabs from 'components/AccountDetails/MachineLearningMetrics/Tabs'
import TabSwitcher from 'components/AccountDetails/MachineLearningMetrics/TabSwitcher'
import { getMlMetricContent } from 'components/AccountDetails/MachineLearningMetrics/utils'
import { Heading } from 'components/common'
import MockDataCallout from 'components/common/Callout/MockDataCallout'

import type { Event, Events, MlMetricCategory, MLMetricContent } from 'models/account.model'

interface MachineLearningMetricsProps {
  data: Events
}

const MachineLearningMetrics = ({ data }: MachineLearningMetricsProps) => {
  const navigate = useNavigate()
  const isMLMetricsAvailable = Object.keys(data).length > 0

  const redirectToAccountDetails = useCallback(
    (factorName: string, driverId: string) => navigate(`/insights/${factorName}/${driverId}`),
    [navigate],
  )

  const TabContext = createContext({
    activeTab: 1,
    setTab: (tabId: number) => {},
  })

  if (!isMLMetricsAvailable) {
    return <EmptyState title='Predictive Insights' message='No Predictive Insights are available for this account' />
  }

  const mlMetricContent: MLMetricContent[] = getMlMetricContent(data, data, redirectToAccountDetails)

  return (
    <div className='ml-metrics-container' data-testid='ml-metrics-container'>
      <div className='ml-metrics-header'>
        <Heading variant='2' level='2'>
          Forecast Drivers
        </Heading>
        <MockDataCallout />
      </div>

      <Tabs TabContext={TabContext}>
        <div className='ml-metrics-tab-switcher-container'>
          {Object.entries(data).map((tab: [string, Event], index: number) => {
            return (
              <TabSwitcher key={tab[0]} tabId={index + 1} TabContext={TabContext}>
                <Tab type={tab[0] as MlMetricCategory} />
              </TabSwitcher>
            )
          })}
        </div>
        <div className='ml-metrics-tab-content' data-testid='ml-metrics-tab-content'>
          {mlMetricContent.map((item) => (
            <TabContent
              key={item.id}
              id={item.id}
              chartData={item.chartData}
              tableColumns={item.tableColumns}
              tableData={item.tableData}
              TabContext={TabContext}
            />
          ))}
        </div>
      </Tabs>
    </div>
  )
}
MachineLearningMetrics.displayName = 'MachineLearningMetrics'

export default MachineLearningMetrics
