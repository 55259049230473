import classNames from 'classnames'

import { IconClose } from 'components/common/Icons/Icons'

interface TagProps {
  icon?: React.JSX.Element | null
  text: string
  title: string
  colour?: string
  disabled?: boolean
  openPopover?: (value: boolean) => void
  removeTag?: React.MouseEventHandler<HTMLSpanElement>
}

const Tag = ({ icon, text, title, colour, disabled = false, openPopover, removeTag }: TagProps) => (
  <div
    className={classNames({
      'tag-field': true,
      [`tag-field--${colour}`]: !!colour,
    })}
    data-testid='tag-field'
    title={title}
    contentEditable={false}>
    {icon}
    <span
      className={classNames({
        'tag-field__name': true,
        'tag-field__name--no-icon': !!!icon,
      })}
      data-testid='tag-field__name'
      {...(!disabled && { onClick: () => openPopover?.(true) })}>
      {text}
    </span>
    {!disabled && (
      <span className='remove-btn' data-testid='remove-btn' onClick={removeTag}>
        <IconClose />
      </span>
    )}
  </div>
)
Tag.displayName = 'Tag'

export default Tag
