import { Editor } from '@tinymce/tinymce-react'
import { Skeleton } from 'antd'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { API } from 'api/api'
import type { LaunchDarklyFeatureFlags } from 'configs/featureFlags'
import useStore from 'store/useStore'

import type { Dayjs } from 'dayjs'

import type { CreateActionFields } from 'models/motion/motionBuilder.model'
import type { CreateSlackMessagePayload } from 'models/slack-messages'

interface RichTextProps {
  item: CreateActionFields
  updateValue: (inputType: string, newVal: number | string | Dayjs | Dayjs[] | CreateSlackMessagePayload | null) => void
  messageInfo: { messageId: string | null; messageVersion: number | null }
}

const RichText = observer(({ item, messageInfo, updateValue }: RichTextProps) => {
  const { demo } = useFlags<LaunchDarklyFeatureFlags>()
  const [isLoadingDynamicData, setIsLoadingDynamicData] = useState<boolean>(true)
  const [dynamicInputs, setDynamicInputs] = useState<Record<string, any>>({})
  const [messageContent, setMessageContent] = useState<string>('')

  const isFirstMessage = !messageInfo.messageId && !messageInfo.messageVersion

  const { dynamicInputStore, slackMessagesStore } = useStore()
  const { fetchTokenList, tokenList } = dynamicInputStore

  const {
    data: { message },
    loading: { isMessageLoading },
    getMessage,
  } = slackMessagesStore

  useEffect(() => {
    if (demo) {
      setIsLoadingDynamicData(false)
      return
    }

    fetchTokenList(item)
      .catch((error) => {
        console.error(error)
      })
      .finally(() => {
        setIsLoadingDynamicData(false)
      })
  }, [])

  useEffect(() => {
    if (isFirstMessage) {
      setDynamicInputs({})
    } else if (!isMessageLoading) {
      fetchMessage().catch((error) => {
        console.error(error)
      })
    }
  }, [])

  const fetchMessage = async () => {
    const magnifyMessageId = messageInfo.messageId ?? message.slackMessageId
    const magnifyMessageVersion = messageInfo.messageVersion ?? message.slackMessageVersion

    const existingMessage = await getMessage({
      slackMessageId: magnifyMessageId ?? '',
      slackMessageVersion: magnifyMessageVersion ?? 0,
    })

    if (existingMessage?.dynamicInputs) {
      updateValue('html', {
        messageContent: existingMessage?.messageContent ?? '',
        dynamicInputs: existingMessage.dynamicInputs,
      } as CreateSlackMessagePayload)
    }

    setMessageContent(existingMessage?.messageContent ?? '')
    setDynamicInputs(existingMessage?.dynamicInputs ?? {})
  }

  if (isLoadingDynamicData || tokenList.isLoading) {
    return <Skeleton.Input active block />
  }

  return (
    <Editor
      apiKey={import.meta.env.VITE_TINYMCE_API_KEY ?? ''}
      onEditorChange={(content, editor) => {
        const newEditor = editor as unknown as Editor & { magnifyDynamicVariables: Set<string> }

        if (content !== messageContent) {
          updateValue('html', { messageContent: content, dynamicInputs: dynamicInputs } as CreateSlackMessagePayload)
        }
        editor.on('ExecCommand', (event) => {
          // We need to extract the tokens from the TinyMCE editor so we can set them up to be replaced later on in the Motion.
          // This is the safest way to extract token at the moment as it is the least amount of text around them.
          // If a token is removed, or entered by hand in the source editor it will not be represented here.
          // We remove unused tags before saving by scanning the text for each key in the dynamicInputs object.
          if (
            event?.command === 'mceInsertContent' &&
            typeof event?.value === 'string' &&
            event?.value.startsWith('<span class="mce-mergetag"')
          ) {
            // The TinyMCE editor will insert the token with a span around it, extract the token from that.
            const [_, chunk] = event.value.split('{{</span>')
            const [tag] = chunk.split('<span class="mce-mergetag-affix"')
            // We need to pass these back to the payload.
            newEditor.magnifyDynamicVariables.add(JSON.stringify(toJS(tokenList?.lookup[tag])))
            const tokenValue = tokenList?.lookup[tag]
            setDynamicInputs((prev) => ({
              ...prev,
              [tag]: tokenValue,
            }))
            updateValue('html', { messageContent: content, dynamicInputs: dynamicInputs } as CreateSlackMessagePayload)
          }
        })
      }}
      initialValue={messageContent}
      init={{
        height: 400,
        menubar: false,
        plugins:
          'preview casechange searchreplace autolink autosave save directionality advcode visualblocks visualchars fullscreen image link media codesample charmap pagebreak nonbreaking anchor insertdatetime advlist lists checklist wordcount editimage help formatpainter linkchecker emoticons export mergetags',
        toolbar: 'undo redo | bold italic strikethrough | link mergetags',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        statusbar: false,
        mergetags_list: tokenList.data,
        mergetags_prefix: '{{',
        mergetags_suffix: '}}',
        icons_url: '/tinymce-icons-magnify.js',
        icons: 'magnify',
        content_css: '/tinymce-content-magnify.css',
        // The following rules are used to disable the default TinyMCE paste behavior due to XSS false positives.
        // https://www.tiny.cloud/docs/tinymce/latest/copy-and-paste/
        paste_as_text: true,
        paste_block_drop: true,
        paste_tab_spaces: 2,
        paste_remove_styles_if_webkit: true,
        images_upload_handler: async (blobInfo) => {
          const formData = new FormData()
          formData.append('file', blobInfo.blob(), blobInfo.filename())

          return API.fileAttachments.uploadAsset(formData)
        },
        setup: (editor) => {
          // TypeScript doesn't want to add a key to the object, so we need to cast it to any.
          const newEditor = editor as unknown as Editor & { magnifyDynamicVariables: Set<string> }
          newEditor.magnifyDynamicVariables = new Set()
        },
      }}
    />
  )
})

export default RichText

RichText.displayName = 'RichText'
