import { Table } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Heading } from 'components/common'
import type { ExecutedActions } from 'components/MotionDetails/ExecutedActionsSandbox/utils'
import { columns, handleExportOnClickHandler } from 'components/MotionDetails/ExecutedActionsSandbox/utils'
import { LoadingExecutedActionsSandbox } from 'components/MotionDetails/Loading'
import useStore from 'store/useStore'

import type { MotionExecutionStatusesItem } from 'models/motion.model'

const ExecutedActionsSandbox = observer(() => {
  const { id: journeyId, version } = useParams()
  const { reportingStore, motionStore } = useStore()
  const {
    data: { motion },
  } = reportingStore
  const { executionOperationalStats, getInternalMotionOperationalStats, isLoading } = motionStore
  const [tableData, setTableData] = useState<ExecutedActions[]>([])

  useEffect(() => {
    if (journeyId && version) {
      getInternalMotionOperationalStats({ playbookId: journeyId, version: parseInt(version) }).catch(console.error)
    }
  }, [journeyId, version])

  useEffect(() => {
    const getTableData = () => {
      const dslStateKeys = Object.keys(executionOperationalStats?.journey.dsl?.states || {})

      return executionOperationalStats?.journeyExecutionStatuses.map((executionStatus) => {
        const items = executionStatus[Object.keys(executionStatus)[0]].Items
        return items.map((item: MotionExecutionStatusesItem, index) => {
          let platform = ''
          let actionName = ''
          if (dslStateKeys.includes(item.actionId)) {
            const state = executionOperationalStats?.journey.dsl?.states[item.actionId] ?? {}
            platform = state.platform || ''
            actionName = state.description || ''
          }
          return {
            key: item.actionId + '-' + index,
            actionId: item.actionId,
            status: item.status,
            timestamp: item.timestamp,
            userId: item.userId,
            account: item.accountId,
            platform: platform,
            actionName: actionName,
          }
        })
      })
    }

    const flattenTableData = getTableData()?.flat(1) || []
    const cleanedTableData = flattenTableData.filter((item) => item.actionId && item.platform && item.actionName)

    setTableData(cleanedTableData || [])
  }, [executionOperationalStats])

  if (isLoading) return <LoadingExecutedActionsSandbox />

  if (!tableData) return null

  return (
    <section className='executed-actions-sandbox'>
      <div className='executed-actions-sandbox__header'>
        <Heading variant='7' level='2'>
          Executed Actions (Sandbox)
        </Heading>
        <Button
          text='Export CSV'
          type='secondary'
          onClickHandler={() => handleExportOnClickHandler(tableData, motion?.title || '')}
        />
      </div>

      <Table columns={columns} dataSource={tableData} />
    </section>
  )
})
ExecutedActionsSandbox.displayName = 'ExecutedActionsSandbox'

export default ExecutedActionsSandbox
