import type { MetadataStore } from 'store/metadata/metadata.store'

import type {
  CreateActionFields,
  ConfigPanelPayload,
  KeyValueField,
  SegmentBuilderData,
} from 'models/motion/motionBuilder.model'
import { ActionEnum } from 'models/motion/motionBuilder.model'

/**
 * A function that mixes metadata fields with filled (DSL) fields.
 * @param {CreateActionFields[]} dslPayloadFields - The DSL payload fields array with filled fields.
 * @param {CreateActionFields[]} metadataFields - The metadata fields array, all raw fields.
 * @returns {CreateActionFields[]} An array containing the metadata fields populated with DSL payload fields.
 */
export const populateFields = (dslPayloadFields: CreateActionFields[], metadataFields: CreateActionFields[]) => {
  const fields = metadataFields?.map((field) => {
    const dslPayloadField = dslPayloadFields?.find((obj) => obj.key === field.key)

    if (dslPayloadField) {
      return {
        ...field,
        ...dslPayloadField,
        isDynamicInput: dslPayloadField.isDynamicInput ?? false,
      }
    }

    return field
  })

  return fields
}

/**
 * Check if the field is a special prop for Gainsight CTA actions
 * @param key - The key of the field
 * @returns {boolean} True if the field is a special prop for Gainsight CTA actions, false otherwise
 */
export const isGainsightCtaSpecialProp = (key: string) =>
  ['reasonid', 'priorityid', 'statusid', 'playbookid'].includes(key?.toLowerCase() || '')

/**
 * MAGPROD-969
 * If it's a CreateCTA Action then check the field, with the key prop as 'Type', value and if present then enable reason, priority and status fields
 * @param metadata field metadata
 * @returns {boolean} True if the field is a special prop for Gainsight CTA actions, false otherwise
 */
export const hasGainsightTypeFieldValue = (isGainsightCTAAction: boolean, key: string, payload: ConfigPanelPayload) => {
  if (isGainsightCTAAction && isGainsightCtaSpecialProp(key)) {
    const typeFieldValue = payload.fields.find((field) => field.key?.toLowerCase() === 'typeid')?.value
    return !typeFieldValue
  }
  return false
}

export const handleGainsightActions = (
  segmentBuilderData: SegmentBuilderData,
  metadataStore: MetadataStore,
  field: KeyValueField,
  fieldKey: string | undefined,
  value: any,
  updatedPayload: ConfigPanelPayload,
) => {
  if (fieldKey?.toLowerCase() === 'campaign_id') {
    const itemKey = `action${segmentBuilderData.platform}${segmentBuilderData.object}${fieldKey}`
    const data = metadataStore.criteriaSelect.options.get(itemKey)?.find((option) => option.value === value)

    if (data) {
      field.displayValue = data.value as string
      field.value = data.id
    }
  }

  if (fieldKey?.toLowerCase() === 'typeid') {
    ;['reasonid', 'priorityid', 'statusid', 'playbookid'].forEach((property) => {
      const criteriaSelectItem = `action${segmentBuilderData.platform}${segmentBuilderData.object}${property}`
      metadataStore.setCriteriaSelectOptions(criteriaSelectItem, [])
      const dependentFieldIndex = updatedPayload.fields.findIndex(
        (field) => field.key?.toLowerCase() === property.toLowerCase(),
      )

      updatedPayload.fields[dependentFieldIndex] = {
        ...updatedPayload.fields[dependentFieldIndex],
        displayValue: '',
        value: '',
      }
    })
  }
}

// MAGPROD-951
/**
 * This function returns a human friendly label name based on the segment builder data
 * @param {SegmentBuilderData} segmentBuilderData The segment builder data
 * @returns {string} The provisory object name
 */
export const provisoryObjectName = (segmentBuilderData: SegmentBuilderData) => {
  const formattedObject = segmentBuilderData.object?.toString().toLowerCase()
  const formattedPlatform = segmentBuilderData.platform?.toString().toLowerCase()

  if (formattedPlatform === 'marketo' && (formattedObject === 'campaign' || formattedObject === 'sendemail')) {
    return 'email'
  }
  if (formattedPlatform === 'pendo') {
    return 'Launch Guide'
  }

  if (formattedPlatform === 'hubspot' && formattedObject === 'send_transactional_email') {
    return 'Send Transactional Email'
  }

  if (formattedPlatform === 'gainsight' && formattedObject === 'call_to_action') {
    return 'Call To Action'
  }
  if (formattedPlatform === 'gainsight' && formattedObject === 'activity_timeline') {
    return 'Create Timeline Event'
  }

  return segmentBuilderData.object
}

export const handleSpecialCases = (
  segmentBuilderData: SegmentBuilderData,
  metadataStore: MetadataStore,
  field: KeyValueField,
  fieldKey: string | undefined,
  value: any,
  updatedPayload: ConfigPanelPayload,
) => {
  const isGainsightCTAAction =
    segmentBuilderData.platform?.toLowerCase() === 'gainsight' &&
    segmentBuilderData.object?.toLowerCase() === 'call_to_action' &&
    segmentBuilderData.action === ActionEnum.Create

  if (isGainsightCTAAction) {
    handleGainsightActions(segmentBuilderData, metadataStore, field, fieldKey, value, updatedPayload)
  }
}
