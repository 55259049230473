import { Menu } from 'antd'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'

import { filterByType, filterByTypeOnSearch } from 'components/common/DataSourceContainer/index.utils'
import SearchViewList from 'components/common/DataSourceContainer/SearchViewList'
import { IconArrowTop, IconCaretRight, IconCheckMark } from 'components/common/Icons/Icons'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'
import useStore from 'store/useStore'

import type { SubMenuProps } from 'antd'
import type { ItemType } from 'antd/es/menu/interface'

import type { MetadataDescription } from 'models/metadata.model'
import type { MetadataField } from 'models/motion/dynamicInput.model'
import type { CreateActionFields, Item, BreadcrumbInfo } from 'models/motion/motionBuilder.model'

interface ViewListProps {
  currentItem: Item | CreateActionFields
  breadCrumbItems: BreadcrumbInfo[]
  handleSelectOption: (item: MetadataField) => void
  handleSelectField: (item: MetadataField) => void
}

const ViewList = observer(({ currentItem, handleSelectOption, breadCrumbItems, handleSelectField }: ViewListProps) => {
  const { dynamicInputStore } = useStore()
  const {
    apiError: dynamicInputApiError,
    currentOptions,
    filteredViewList,
    error: dynamicInputError,
    searchViewList,
    viewList,
  } = dynamicInputStore
  const isSelectable = viewList.type === 'field'
  const isSelected = (item: MetadataField) => {
    if (typeof currentItem.value === 'object' && currentItem?.value && 'field' in currentItem?.value) {
      return item.name === currentItem?.value?.field
    }
    return false
  }

  const emptyViewList = !filterByType(filteredViewList, currentItem.type).length
  const emptySearchViewList = !filterByTypeOnSearch(searchViewList.data, currentItem.type ?? '').length
  const displaySearchResult = searchViewList.isVisible
  const expandMenuIcon = (props: SubMenuProps) => {
    const noOfChildren = (props.children as Array<Element>).length
    const menuIcon = <span className='submenu-icon'>{noOfChildren}</span>
    return menuIcon
  }

  const getMenuItems = (item: MetadataDescription) => {
    const menuItem = {
      label: item.name,
      icon: (
        <>
          {getMenuItemIcon({
            name: item.name || '',
            entityType: viewList.type,
          })}
          <span className='submenu-icon icon-reverse'>
            <IconArrowTop />
          </span>
        </>
      ),
      key: item.name,
      children: [] as ItemType[],
    }

    if (item.connections?.length) {
      menuItem.children = item.connections.map((connection) => {
        return {
          label: connection.name,
          title: connection.name,
          key: connection.solutionInstanceId,
          className: 'menu-item',
          icon: (
            <>
              {getMenuItemIcon({
                name: item.name || '',
                entityType: viewList.type,
              })}
              {!item.type && <IconCaretRight />}
            </>
          ),
          'data-testid': 'menu-item',
          onClick: () =>
            handleSelectOption({
              ...item,
              type: viewList.type,
              solutionInstanceId: connection.solutionInstanceId,
              ...(item.magnifyDisplayName && { magnifyDisplayName: item.magnifyDisplayName }),
            }),
        }
      })
    }

    return [menuItem]
  }

  const dynamicInputsFilteredByType = useMemo(() => {
    if (!searchViewList.isVisible && !dynamicInputError.isVisible)
      return filterByType(filteredViewList, currentItem.type)
        .slice()
        .sort((a, b) => {
          // sort magnify data source to the top
          if (a.name === 'magnify-insights') return -1
          if (b.name === 'magnify-insights') return 1
          return 0
        })
    else return []
  }, [filteredViewList, searchViewList.isVisible, dynamicInputError.isVisible, viewList.data, currentItem.type])

  const dynamicInputsFilteredByTypeOnSearch = useMemo(() => {
    if (searchViewList.isVisible && !dynamicInputError.isVisible)
      return filterByTypeOnSearch(searchViewList.data, currentItem.type ?? '')
    else return []
  }, [searchViewList.isVisible, dynamicInputError.isVisible, searchViewList.data, currentItem.type])

  const getItemMarkup = (item: MetadataField) =>
    isSelected(item) ? (
      <IconCheckMark className='selected-data-field' data-testid='selected-data-field' />
    ) : (
      <span className='menu__item--label'>use</span>
    )

  return (
    <div className='menu' data-testid='popover-menu'>
      {((emptyViewList && !displaySearchResult) || (emptySearchViewList && displaySearchResult)) &&
        !dynamicInputError.isVisible &&
        !dynamicInputApiError?.message && (
          <span data-testid='empty-list'>
            There aren't any options available for
            <strong>
              {' '}
              {currentItem.platform} / {currentItem.object}
            </strong>
          </span>
        )}

      {dynamicInputsFilteredByType.map((item: MetadataField, index: number) => {
        if (item.connections?.length && item.connections?.length > 1) {
          return (
            <Menu
              key={item.name}
              inlineIndent={0}
              expandIcon={expandMenuIcon}
              mode='inline'
              selectable={false}
              data-testid='action-menu'
              items={getMenuItems(item as MetadataDescription)}
            />
          )
        }
        return (
          <div
            key={`${item.name}-${index}`}
            id={`${item.name}-${index}`}
            onClick={() => {
              if (isSelectable) {
                handleSelectField?.({
                  ...item,
                  platform: breadCrumbItems[0].name,
                  object: breadCrumbItems[1].name,
                  solutionInstanceId: currentOptions.solutionInstanceId,
                  ...(breadCrumbItems[1]?.magnifyDisplayName && {
                    magnifyDisplayName: breadCrumbItems[1]?.magnifyDisplayName,
                  }),
                })
              } else {
                handleSelectOption({
                  ...item,
                  type: viewList.type,
                  solutionInstanceId: item.connections?.[0].solutionInstanceId,
                  ...(item.magnifyDisplayName && { magnifyDisplayName: item.magnifyDisplayName }),
                })
              }
            }}
            className='menu__item view-list-menu-item'
            data-testid='menu__item'>
            {getMenuItemIcon({
              name: item.name ?? '',
              entityType: viewList.type,
            })}
            <span className='menu__name' data-testid='menu__name'>
              {/* TODO: Remove the replace of snowflakedb once isn't needed anymore (MAGPROD-3416) - or we entirely migrate to Airbyte */}
              {item.magnifyDisplayName ??
                item.name?.replace('snowflakedb', 'snowflake').replace('chorusai', 'Chorus AI')}
            </span>

            {isSelectable ? getItemMarkup(item) : <IconCaretRight />}
          </div>
        )
      })}

      {dynamicInputsFilteredByTypeOnSearch.map((item) => {
        return (
          <SearchViewList
            item={item}
            currentItem={currentItem}
            breadCrumbItems={breadCrumbItems}
            key={`${item.order.reduce((orderItem, current) => `${orderItem}-${current.key}`, '')}-${item.type}`}
            handleSelectField={handleSelectField}
            handleSelectOption={handleSelectOption}
          />
        )
      })}

      {dynamicInputError.isVisible && <span>{dynamicInputError.message}</span>}
    </div>
  )
})
ViewList.displayName = 'ViewList'

export default ViewList
