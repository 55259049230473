import type { MLMetricRiskLevel, MlMetricCategoryEnum } from 'models/account.model'

export enum DriverSegmentEnum {
  High = 'high',
  Medium = 'medium',
  Low = 'low',
  Custom = 'custom',
}

// Start v3

export interface RowValue {
  value: string | number | null
  segment?: MLMetricRiskLevel
}

interface UserCount extends RowValue {
  value: number | string | null
}
interface AccountName extends RowValue {
  value: string | null
}

export interface AccountRowValue {
  account_id: string
  user_count?: UserCount
  account_name: AccountName
  segment: DriverSegmentEnum
  [key: string]: RowValue | number | string | undefined
}

export enum DataFormatEnum {
  Kmb = 'kmb',
  Text = 'text',
  Percent = 'percent',
  Percentage = 'percentage',
  Comma = 'comma',
  Currency = 'currency',
  CurrencyKMB = 'currency-kmb',
}

export interface Source {
  title: string
  column: string
  aggregator: string
  format: DataFormatEnum
  decimal: number
  key?: string
  type?: 'string' | 'number'
  segment?: string
}

export interface Aggregation {
  title: string
  sources: Source[]
  key?: string
}

export interface Propensity {
  improve_from: number
  improve_to: number
  improve_by: number
  comment?: string | null
  improve_from_text: string
  improve_to_text: string
}

export interface DriverMetric {
  aggregator: string
  type: string
  format: DataFormatEnum
  decimal: number
}

export interface Accounts {
  title: string
  column: string
  aggregator: string
  type: string
  format: DataFormatEnum
  decimal: number
}

export interface Arr {
  title: string
  column: string
  aggregator: string
  type: string
  format: DataFormatEnum
  decimal: number
}
export interface Driver {
  title: string
  column: string
  min_metric: DriverMetric
  max_metric: DriverMetric
}

export interface SegmentV3 {
  title: string
  driver: Driver
  num_accounts: Accounts
  revenue_elift_improve_from: Arr
}

export interface PredefinedSegments {
  [key: string]: SegmentV3
}

// END v3

export interface InsightDriverSegmentMetricsV2 {
  time_period?: string
  improve_from: number
  improve_to: number
  comment?: string | null
  improve_from_text: string
  improve_to_text: string
  metric: string
}

export interface InsightDriverSegmentV2 {
  account_count: number
  user_count: number
  propensity: Propensity
  family: {
    platform: string
    object: string
    field: string
    standard_object?: string
    standard_field?: string
  }
  metrics: {
    [key: string]: InsightDriverSegmentMetricsV2
  }
  revenue: {
    past?: number
    elift_improve_from: number
    elift_improve_to: number
    elift_improve_by: number
  }
}

export enum InsightDriverTypeEnum {
  Insight = 'insight',
  Metric = 'metric',
}

export interface InsightDriverV2 {
  driver_id: number | string
  driver_type: InsightDriverTypeEnum
  family?: {
    platform: string
    object: string
    field: string
    standard_object?: string
    standard_field?: string
  }
  segments: {
    [key: string]: InsightDriverSegmentV2
  }
}

export enum ImpactEnum {
  Negative = 'negative',
  Positive = 'positive',
}

export interface InsightEventV2 {
  short_name?: MlMetricCategoryEnum
  full_name: string
  impact: ImpactEnum
  drivers: InsightDriverV2[]
  is_revenue_quantified?: boolean
}

type AtLeastOne<T, U = { [K in keyof T]: Pick<T, K> }> = Partial<T> & U[keyof U]

export interface InsightsV2 {
  /** Insights ML Model Training Datetime, `YYYY-MM-ddThh-mm-ssZ` */
  train_datetime: string
  /** Insights ML Model Inference Datetime, `YYYY-MM-ddThh-mm-ssZ` */
  infer_datetime: string
  /** Dataset snapshot date as of which all ML modeling features are generated, `YYYY-MM-DD` */
  dataset_date: string
  /** A product or feature dimension name, default is `overall` */
  dimension: string
  /** Denotes if the data is generated per account ID or per user ID. Previously under the `granular` key. */
  grid?: 'account' | 'user'
  /** Indicates whether we forecast revenues for the tenant. */
  is_revenue_quantified?: boolean
  events: AtLeastOne<{
    [key in MlMetricCategoryEnum]?: InsightEventV2
  }>
}

export interface SegmentRangeValue {
  account_count: number
  metrics: {
    [key: string]: {
      improve_from: number
      improve_to: number
      comment?: string | null
    }
  }
  metric_ranges: {
    [key: string]: {
      [key: string]: number
    }
  }
  revenue: {
    past: number
    elift_improve_from: number
    elift_improve_to: number
    elift_improve_by: number
  }
  propensity: Propensity
  user_count: number
}

export interface InsightSegmentValues {
  // low, medium, high
  [key: string]: SegmentRangeValue
}

export interface InsightV2 {
  grid?: string
  is_revenue_quantified?: boolean
  metric_value?: number
  event: {
    short_name: string
    full_name: string
    impact: ImpactEnum
  }
  family: {
    platform: string
    object: string
    field: string
    standard_object?: string
    standard_field?: string
  }
  aggregations?: Aggregation[]
  predefined_segments: PredefinedSegments
  driver_type?: InsightDriverTypeEnum
  /** Driver ID */
  driver_id?: string
  /** Insights ML Model Training Datetime, `YYYY-MM-ddThh-mm-ssZ` */
  train_datetime: string
  /** Insights ML Model Inference Datetime, `YYYY-MM-ddThh-mm-ssZ` */
  infer_datetime: string
  /** Dataset snapshot date as of which all ML modeling features are generated, `YYYY-MM-DD` */
  dataset_date: string
  /** A product or feature dimension name, default is `overall` */
  dimension: string
}

export enum TableColumnTypeEnum {
  String = 'string',
  Number = 'number',
  /** @deprecated */
  Percentage = 'percentage',
  /** @deprecated */
  Range = 'range',
  /** @deprecated */
  Time = 'time',
  /** @deprecated */
  Prediction = 'prediction',
  /** @deprecated */
  ImpactPercentage = 'impactPercentage',
  /** @deprecated */
  RevenueForecast = 'revenueForecast',
  /** @deprecated */
  Composite = 'composite',
  /** @deprecated */
  MotionMetrics = 'motionMetrics',
  /** @deprecated */
  Variant = 'variant',
  /** @deprecated */
  Str = 'str',
  /** @deprecated */
  Percent = 'percent',
  /** @deprecated */
  Float = 'float',
  /** @deprecated */
  Int = 'int',
}

export interface Events {
  [key: string]: {
    propensity: number
    propensity_text: string
    std_error?: number
    segment: string
  }
}

export interface Usages {
  [key: string]: {
    forecast: number
    std_error: number
    plift: number | string
  }
}

export interface InsightAccount {
  id: string
  account_name: string
  user_count: number
  events: Events
  usages: Usages
}

export interface TenantInsightAccounts {
  granular: string
  datetime: string
  dataset_date: string
  events: {
    [key: string]: {
      full_name: string
      impact: string
    }
  }
  usages: {
    [key: string]: {
      full_name: string
    }
  }
  accounts: {
    [key: string]: {
      account_name: string
      user_count: number
      events: Events
      usages: Usages
    }
  }
}

export type Impact = ImpactEnum | null

export interface TableColumn {
  title: string
  key: string
  type: `${TableColumnTypeEnum}`
  decimal?: number
  nullable?: boolean
  format?: DataFormatEnum
  impact?: Impact
}

/**
 * Represents driver segment accounts data.
 */
export interface DriverSegmentAccounts {
  grid?: string
  is_revenue_quantified?: boolean
  driver_type?: InsightDriverTypeEnum
  granular?: string
  datetime?: string
  event: {
    short_name: string
    full_name: string
    impact: ImpactEnum
  }
  table_columns: TableColumn[]
  table_rows: AccountRowValue[]
  /** Insights ML Model Training Datetime, `YYYY-MM-ddThh-mm-ssZ` */
  train_datetime: string
  /** Insights ML Model Inference Datetime, `YYYY-MM-ddThh-mm-ssZ` */
  infer_datetime: string
  /** Dataset snapshot date as of which all ML modeling features are generated, `YYYY-MM-DD` */
  dataset_date: string
  /** A product or feature dimension name, default is `overall` */
  dimension: string
}

export interface SegmentRow {
  id: number
  level: number
  accounts: number
  users: number
  [key: string]: number[] | number
}

export type RangeValue = [number, number]

export interface AccountData {
  tableColumns: TableColumn[]
  tableRows: AccountRowValue[]
  impact: ImpactEnum
  // variant?: AccountTableVariant
}

export interface TargetImpact {
  arrTotal?: number
}

export interface UISpeedometerDriver extends InsightDriverSegmentV2 {
  level: string
}

export interface RangeSource extends Source {
  min?: number
  max?: number
  driverColumn: string
}

/** Insight Dimensions */
export interface InsightDimensionFilters {
  is_won: boolean[]
}

export interface InsightDimensionEvent {
  display: boolean
  impact: string
  plift_lower?: number
  plift_upper?: number
}

export interface InsightDimensionSource {
  start_date_col: string
  acv_col: string
  end_date_col: string
  filters: InsightDimensionFilters
  platform: string
  object: string
}

export interface InsightDimensionEvents {
  [key: string]: InsightDimensionEvent
}

export interface InsightDimension {
  alias: string
  events?: InsightDimensionEvents
  source?: InsightDimensionSource
}

export interface InsightDimensions {
  [key: string]: InsightDimension
}

export interface SetTargetPost {
  /** Insights ML Model Training Datetime, `YYYY-MM-ddThh-mm-ssZ` */
  train_datetime: string
  /** Insights ML Model Inference Datetime, `YYYY-MM-ddThh-mm-ssZ` */
  infer_datetime: string
  /** Dataset snapshot date as of which all ML modeling features are generated, `YYYY-MM-DD` */
  dataset_date: string
  /** A product or feature dimension name, default is `overall` */
  dimension: string
  event: MlMetricCategoryEnum
  driver_id: string
  metric_value: number
}

// Revenue Retention Forecast
export interface Percent {
  value: number
  decimal: number
  type?: TableColumnTypeEnum
  format: DataFormatEnum
}

export interface FutureRevenue {
  name: string
  value: Percent
  percent: Percent
  short_name?: string
}

export interface RevenueTrend {
  label: string
  type?: string
  past_revenue: FutureRevenue
  future_revenue: FutureRevenue
  events: FutureRevenue[]
}

export interface RevenueRetentionForecast {
  revenue_trend: RevenueTrend[]
}

// Account Forecast
export interface AccountForecastFilterValues {
  contract_end_period: string[]
  revenue_period: string[]
}

export interface AccountForecastString {
  value: null | string
}

export interface AccountForecastNumber {
  value: number
  segment: number
}

export interface AccountForecastNumberOrString {
  value: number | string
  segment: number | string
}

export interface AccountForecastTableRow {
  account_id: string
  accountId: string
  account_name: AccountForecastString
  accountName: string
  contract_end_date: AccountForecastString
  contract_end_period: AccountForecastString
  contractEndDate: string
  contractEndPeriod: string
  churn: string
  churnSegment: string
  conversion: AccountForecastNumberOrString
  expansion: string
  expansionSegment: string
  revenue_period: AccountForecastString
  revenue: number | string
  [key: string]: any
}

export interface AccountForecast {
  grid: string
  dimension: string
  train_datetime: string
  infer_datetime: string
  dataset_date: string
  is_revenue_quantified: boolean
  filter_columns: TableColumn[]
  filter_values: AccountForecastFilterValues
  table_columns: TableColumn[]
  table_rows: AccountForecastTableRow[]
  // Postgres Data
  results?: AccountForecastTableRow[]
  contractPeriodEnds?: string[]
  revenuePeriods?: string[]
  totalSize?: string | number
}

export interface AccountForecastTableState {
  selectedRenewal: string
  selectedForecast: string
  searchInput: string
  currentPage: number
  pageSize: number
  sortKey: string
  sortDirection: string
}
