import { Input, Modal, Radio } from 'antd'
import { useEffect, useState } from 'react'

import { Button, IconDynamicInput, IconGear } from 'components/common'
import {
  convertPayloadToDynamicInputs,
  handleSaveSegmentBuilderDynamicInput,
  handleSaveEmailDynamicInput,
  displayKey,
  getDisplayName,
} from 'components/common/DynamicInputModal/utils'
import { LogoService } from 'services/Utils/logo'

import type { RadioChangeEvent } from 'antd'

import type { CreateActionFields, CreateActionsPayload } from 'models/motion/motionBuilder.model'

interface DynamicInputModalProps {
  isModalOpen: boolean
  setIsModalOpen: (isOpen: boolean) => void
  setDynamicInputs?: React.Dispatch<React.SetStateAction<Record<string, any>>>
  dynamicInputs?: Record<string, CreateActionFields>
  handleSaveTemplate?: () => void
  setPayload?: React.Dispatch<React.SetStateAction<CreateActionsPayload>>
  payload?: CreateActionsPayload
  /** The key to open the modal with. */
  defaultCurrentKey?: string
  setCurrentDynamicInputModalKey?: React.Dispatch<React.SetStateAction<string>>
}

const DynamicInputModal = ({
  isModalOpen,
  setIsModalOpen,
  setDynamicInputs,
  dynamicInputs = {},
  handleSaveTemplate = () => {},
  setPayload,
  payload,
  defaultCurrentKey = '',
  setCurrentDynamicInputModalKey,
}: DynamicInputModalProps) => {
  const [currentKey, setCurrentKey] = useState(defaultCurrentKey)
  const [currentField, setCurrentField] = useState(dynamicInputs[currentKey])

  useEffect(() => {
    setCurrentKey(defaultCurrentKey)
  }, [defaultCurrentKey])

  useEffect(() => {
    setCurrentField(dynamicInputs[currentKey])
  }, [currentKey])

  /** Used for pluralizing the modal title and checking the count. */
  let dynamicInputCount = Object.keys(dynamicInputs).length

  // Check for payload and convert it to the dynamicInputs formar if it exists
  if (dynamicInputCount === 0 && payload) {
    // eslint-disable-next-line no-param-reassign
    dynamicInputs = convertPayloadToDynamicInputs(payload)
    dynamicInputCount = Object.keys(dynamicInputs).length
  }

  /** Handle chaning the radiop options. */
  const [radioOption, setRadioOption] = useState('error')
  const onChangeRadioOption = (event: RadioChangeEvent) => {
    setRadioOption(event.target.value as string)
  }

  /** Select a dynamic input to edit and prepopulate the radio button and input. */
  const [defaultValue, setDefaultValue] = useState('')
  const handleChangeDefaultValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDefaultValue(event?.target.value || '')
  }

  /** Closing the modal with OK. */
  const handleOk = () => {
    setCurrentKey('')
    if (setCurrentDynamicInputModalKey) {
      setCurrentDynamicInputModalKey('')
    }
    if (setPayload) {
      handleSaveSegmentBuilderDynamicInput({
        currentKey,
        defaultValue,
        radioOption,
        isMessageContent: dynamicInputs[currentKey]?.isMessageContent,
        setPayload,
      })
    } else if (setDynamicInputs) {
      handleSaveEmailDynamicInput({ currentKey, defaultValue, radioOption, setDynamicInputs })
    }
    handleSaveTemplate()
    setDefaultValue('')
    setRadioOption('error')
    setIsModalOpen(false)
  }

  /** Closing the modal with Cancel. */
  const handleCancel = () => {
    setCurrentKey('')
    if (setCurrentDynamicInputModalKey) {
      setCurrentDynamicInputModalKey('')
    }
    setRadioOption('error')
    setDefaultValue('')
    setIsModalOpen(false)
  }

  /** Switch to edit view for a particular dynamic input. */
  const handleEditDynamicInput = (key: string) => () => {
    if (dynamicInputs[key]?.defaultValue) {
      setRadioOption('default')
      setDefaultValue(dynamicInputs[key].defaultValue || '')
    } else {
      setRadioOption('error')
      setDefaultValue('')
    }
    setCurrentKey(key)
  }

  return (
    <Modal
      title={
        <>
          <IconDynamicInput />
          {currentKey
            ? 'Configure Error Behavior'
            : `Dynamic Input${dynamicInputCount > 1 ? 's' : ''} in Use (${dynamicInputCount})`}
        </>
      }
      centered
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      className='dynamic-input-modal'
      footer={
        currentKey
          ? [
              <Button key='cancel' text='Cancel' type='secondary' testId='cancel-btn' onClickHandler={handleCancel} />,
              <Button
                key='choose-condition'
                text='Confirm'
                onClickHandler={handleOk}
                testId='button-choose-condition'
              />,
            ]
          : [<Button key='close-modal' text='Close' onClickHandler={handleCancel} testId='button-close-modal' />]
      }>
      {currentKey ? (
        <div className='dynamic-input-editor'>
          <div className='token-container'>
            {getDisplayName(currentKey, payload)}:
            <div className='token'>
              <div className='dynamic-input-icon'>{LogoService.getSVGIcon(currentField?.platform ?? '')}</div>
              <span>{currentField?.field}</span>
            </div>
          </div>
          <p className='modal__text'>Sometimes a participant may be missing this property or have an invalid value.</p>
          <p className='modal__text modal__text--bold'>How would you like to handle this case?</p>
          <div className='dynamic-input-options'>
            <Radio.Group onChange={onChangeRadioOption} value={radioOption}>
              <div className='dynamic-input-option stop-action'>
                <Radio value='error' data-testid='radio-error-value'>
                  Stop and report error
                  <br />
                  <span className='description'>
                    Select if the property is required, like a user's email address when sending an email
                  </span>
                </Radio>
              </div>
              <div className='dynamic-input-option default-value'>
                <Radio value='default' data-testid='radio-default-value'>
                  Continue and replace with a default value
                  <br />
                  <span className='description'>Enter the default value below</span>
                  <Input
                    size='middle'
                    data-testid='default-value-input'
                    onChange={handleChangeDefaultValue}
                    value={defaultValue}
                    className='default-value-input'
                  />
                </Radio>
              </div>
            </Radio.Group>
          </div>
        </div>
      ) : (
        <div className='dynamic-input-rows'>
          {Object.entries(dynamicInputs).map(([key, value], i) => (
            <div key={key} className='dynamic-input-row'>
              <div className='dynamic-input-key'>
                <div className='dynamic-input-icon'>{LogoService.getSVGIcon(value?.platform ?? '')}</div>
                <span>{displayKey(key, value, payload)}</span>
              </div>
              <div className='dynamic-input-value'>
                <button
                  type='button'
                  className='normalize-button'
                  onClick={handleEditDynamicInput(key)}
                  data-testid={`select-dynamic-input-${i}`}>
                  <IconGear />
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </Modal>
  )
}
DynamicInputModal.displayName = 'DynamicInputModal'

export default DynamicInputModal
