export const demoGetPlatformsMetadataDefault = {
  data: [
    {
      name: 'gainsight',
      connections: [
        {
          name: 'MagnifyDemo',
          date: 1677268867262,
          solutionInstanceId: '1',
        },
      ],
    },
    {
      name: 'salesforce',
      connections: [
        {
          name: 'MagnifyDemo',
          date: 1677254320908,
          solutionInstanceId: '1',
        },
      ],
    },
    {
      name: 'snowflake',
      connections: [
        {
          name: 'MagnifyDemo',
          date: 1679521481778,
          solutionInstanceId: '1',
        },
      ],
    },
    {
      name: 'pendo',
      connections: [
        {
          name: 'MagnifyDemo',
          date: 1679521481778,
          solutionInstanceId: '1',
        },
      ],
    },
    {
      name: 'intercom',
      connections: [
        {
          name: 'MagnifyDemo',
          date: 1679521481778,
          solutionInstanceId: '1',
        },
      ],
    },
    {
      name: 'hubspot',
      connections: [
        {
          name: 'MagnifyDemo',
          date: 1679521481778,
          solutionInstanceId: '1',
        },
      ],
    },
    {
      name: 'magnify-insights',
      connections: [
        {
          name: 'MagnifyDemo',
          date: 1718749973249,
          solutionInstanceId: '1',
        },
      ],
    },
    {
      name: 'chorusai',
      connections: [
        {
          name: 'MagnifyDemo',
          date: 1718749973249,
          solutionInstanceId: '1',
        },
      ],
    },
  ],
}

export const demoGetPlatformsMetadata = [
  {
    platform: 'gainsight',
    data: [
      {
        name: 'Activity_Timeline',
        magnifyDisplayName: 'Activity Timeline',
      },
      {
        name: 'Call_To_Action',
        magnifyDisplayName: 'Call To Action',
      },
      {
        name: 'Company',
        magnifyDisplayName: 'Company',
      },
      {
        name: 'Company_Person',
        magnifyDisplayName: 'Company Person',
      },
      {
        name: 'Survey_Participant',
        magnifyDisplayName: 'Survey Participant',
      },
    ],
  },
  {
    platform: 'zendesk',
    data: [
      {
        name: 'Organization',
        magnifyDisplayName: 'Organization',
      },
      {
        name: 'Request',
        magnifyDisplayName: 'Request',
      },
      {
        name: 'Ticket',
        magnifyDisplayName: 'Ticket',
      },
      {
        name: 'User',
        magnifyDisplayName: 'User',
      },
    ],
  },
  {
    platform: 'salesforce',
    data: [
      {
        name: 'Account',
        magnifyDisplayName: 'Account',
      },
      {
        name: 'Case',
        magnifyDisplayName: 'Case',
      },
      {
        name: 'Contact',
        magnifyDisplayName: 'Contact',
      },
      {
        name: 'Contract',
        magnifyDisplayName: 'Contract',
      },
      {
        name: 'Lead',
        magnifyDisplayName: 'Lead',
      },
      {
        name: 'Opportunity',
        magnifyDisplayName: 'Opportunity',
      },
      {
        name: 'Task',
        magnifyDisplayName: 'Task',
      },
    ],
  },
  {
    platform: 'snowflake',
    data: [
      {
        name: 'CLOUD_INSTANCE_USAGE',
        magnifyDisplayName: 'CLOUD_INSTANCE_USAGE',
      },
    ],
  },
  {
    platform: 'pendo',
    data: [
      {
        name: 'Account',
        magnifyDisplayName: 'Account',
      },
      {
        name: 'Feature_events',
        magnifyDisplayName: 'Feature Events',
      },
      {
        name: 'Guide_events',
        magnifyDisplayName: 'Guide Events',
      },
      {
        name: 'Page_events',
        magnifyDisplayName: 'Page Events',
      },
      {
        name: 'Visitor',
        magnifyDisplayName: 'Visitor',
      },
    ],
  },
  {
    platform: 'intercom',
    data: [
      {
        name: 'Companies',
        magnifyDisplayName: 'Companies',
      },
      {
        name: 'Contacts',
        magnifyDisplayName: 'Contacts',
      },
      {
        name: 'Tickets',
        magnifyDisplayName: 'tickets',
      },
    ],
  },
  {
    platform: 'hubspot',
    data: [
      {
        name: 'Contact',
        magnifyDisplayName: 'Contact',
      },
      {
        name: 'Email_event',
        magnifyDisplayName: 'Email Event',
      },
      {
        name: 'Email_campaign',
        magnifyDisplayName: 'Email Campaign',
      },
      {
        name: 'Marketing_email',
        magnifyDisplayName: 'Marketing Email',
      },
      {
        name: 'Send_Transactional_Email',
        magnifyDisplayName: 'Send Transactional Email',
      },
    ],
  },
  {
    platform: 'magnify-insights',
    data: [
      {
        name: 'account_insights',
        magnifyDisplayName: 'Account Drivers',
      },
      {
        name: 'trend_revenues',
        magnifyDisplayName: 'Account Forecast',
      },
      {
        name: 'event_revenues',
        magnifyDisplayName: 'Predictive Insights',
      },
      {
        name: 'retention_revenues',
        magnifyDisplayName: 'Revenue Forecast',
      },
    ],
  },
  {
    platform: 'chorusai',
    data: [
      {
        name: 'scorecard_score',
        magnifyDisplayName: 'Scorecard Score',
      },
      {
        name: 'scorecard',
        magnifyDisplayName: 'Scorecard',
      },
      {
        name: 'engagement_participant',
        magnifyDisplayName: 'Engagement Participant',
      },
      {
        name: 'tracker',
        magnifyDisplayName: 'Tracker',
      },
      {
        name: 'engagement',
        magnifyDisplayName: 'Engagement',
      },
      {
        name: 'user_team',
        magnifyDisplayName: 'User Team',
      },
      {
        name: 'users',
        magnifyDisplayName: 'Users',
      },
    ],
  },
]

export const demoGetPlatformsObjectsMetadata = [
  {
    platform: 'gainsight',
    data: [
      {
        object: 'Activity_Timeline',
        data: [
          {
            key: 'Account Id',
            name: 'Account Id',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Activity Date',
            name: 'Activity Date',
            type: 'datetime',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Activity Id',
            name: 'Activity Id',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Author Id',
            name: 'Author Id',
            type: 'string',
            required: true,
            updateable: false,
            createable: true,
          },
          {
            key: 'Context Id',
            name: 'Context Id',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Context Name',
            name: 'Context Name',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Created By',
            name: 'Created By',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Created Date',
            name: 'Created Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Duration (in Mins)',
            name: 'Duration (in Mins)',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'External Attendees',
            name: 'External Attendees',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'External Id',
            name: 'External Id',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'External Source',
            name: 'External Source',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'GS Company Id',
            name: 'GS Company Id',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'GS Created By User ID',
            name: 'GS Created By User ID',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'GS LastModified By User ID',
            name: 'GS LastModified By User ID',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'GS Relationship Id',
            name: 'GS Relationship Id',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'GS Relationship Type Id',
            name: 'GS Relationship Type Id',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Gsid',
            name: 'Gsid',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Internal Attendees',
            name: 'Internal Attendees',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Last Modified By',
            name: 'Last Modified By',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Last Modified Date',
            name: 'Last Modified Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Media Url',
            name: 'Media Url',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Milestone Type Name',
            name: 'Milestone Type Name',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Notes',
            name: 'Notes',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Notes Plain-Text',
            name: 'Notes Plain-Text',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Relationship Id',
            name: 'Relationship Id',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Relationship Type Id',
            name: 'Relationship Type Id',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Reporting Category',
            name: 'Reporting Category',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'SF Event Id',
            name: 'SF Event Id',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'SF Task Id',
            name: 'SF Task Id',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'SFDC CTA Id',
            name: 'SFDC CTA Id',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Score Details',
            name: 'Score Details',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Scorecard',
            name: 'Scorecard',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Scorecard Measure',
            name: 'Scorecard Measure',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Source',
            name: 'Source',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Subject',
            name: 'Subject',
            type: 'string',
            required: true,
            updateable: false,
            createable: true,
          },
          {
            key: 'Trackers',
            name: 'Trackers',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Type Name',
            name: 'Type Name',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'User Email',
            name: 'User Email',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'User Id',
            name: 'User Id',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'User Name',
            name: 'User Name',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
        ],
      },
      {
        object: 'Call_To_Action',
        data: [
          {
            key: 'Age',
            name: 'Age',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Close Source',
            name: 'Close Source',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Closed Date',
            name: 'Closed Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Closed Task Count',
            name: 'Closed Task Count',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Comments',
            name: 'Comments',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Company',
            name: 'Company',
            type: 'lookup',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Created By',
            name: 'Created By',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Created Date',
            name: 'Created Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Days Allocated',
            name: 'Days Allocated',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Days Due',
            name: 'Days Due',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Days Past Due Date',
            name: 'Days Past Due Date',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Dependency Meta',
            name: 'Dependency Meta',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Due Date',
            name: 'Due Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Due Date Variance',
            name: 'Due Date Variance',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Entity Type',
            name: 'Entity Type',
            type: 'picklist',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Event Properties',
            name: 'Event Properties',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'GSID',
            name: 'GSID',
            type: 'string',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Is Closed',
            name: 'Is Closed',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Is Closed Overdue',
            name: 'Is Closed Overdue',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Is Draft',
            name: 'Is Draft',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Is Important',
            name: 'Is Important',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Is Overdue',
            name: 'Is Overdue',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Is Recurring',
            name: 'Is Recurring',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Is Snoozed',
            name: 'Is Snoozed',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Modified By',
            name: 'Modified By',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Modified Date',
            name: 'Modified Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Name',
            name: 'Name',
            type: 'string',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Next Action Due Date',
            name: 'Next Action Due Date',
            type: 'date',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Objective Category',
            name: 'Objective Category',
            type: 'picklist',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Open Task Count',
            name: 'Open Task Count',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Original Due Date',
            name: 'Original Due Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'OverDue Type',
            name: 'OverDue Type',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Owner',
            name: 'Owner',
            type: 'lookup',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Parent Id',
            name: 'Parent Id',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Percent Complete',
            name: 'Percent Complete',
            type: 'decimal',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Playbook',
            name: 'Playbook',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Priority',
            name: 'Priority',
            type: 'picklist',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Reason',
            name: 'Reason',
            type: 'picklist',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Relationship',
            name: 'Relationship',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Relationship Type',
            name: 'Relationship Type',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Rule Action Id',
            name: 'Rule Action Id',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Sharing Type',
            name: 'Sharing Type',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Snooze Reason',
            name: 'Snooze Reason',
            type: 'picklist',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Snoozed On',
            name: 'Snoozed On',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Snoozed Until',
            name: 'Snoozed Until',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Source',
            name: 'Source',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Start Date',
            name: 'Start Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Status',
            name: 'Status',
            type: 'picklist',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Success Criteria',
            name: 'Success Criteria',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Success plan',
            name: 'Success plan',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Template Display Order',
            name: 'Template Display Order',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Total Task Count',
            name: 'Total Task Count',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Type',
            name: 'Type',
            type: 'picklist',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'User Pool Id',
            name: 'User Pool Id',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
        ],
      },
      {
        object: 'Company',
        data: [
          {
            key: 'ARR',
            name: 'ARR',
            type: 'decimal',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Average NPS®',
            name: 'Average NPS®',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Billing Address',
            name: 'Billing Address',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CSAT',
            name: 'CSAT',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'CSAT Last Updated',
            name: 'CSAT Last Updated',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'CSM',
            name: 'CSM',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Company Type',
            name: 'Company Type',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Created By',
            name: 'Created By',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Created Date',
            name: 'Created Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Current Score',
            name: 'Current Score',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Customer Lifetime in Months',
            name: 'Customer Lifetime in Months',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Employees',
            name: 'Employees',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'GS Ingestion Source',
            name: 'GS Ingestion Source',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'GSID',
            name: 'GSID',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Industry',
            name: 'Industry',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Industry (Dropdown)',
            name: 'Industry (Dropdown)',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Lifecycle in weeks',
            name: 'Lifecycle in weeks',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Logo',
            name: 'Logo',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MRR',
            name: 'MRR',
            type: 'decimal',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Magnify Added',
            name: 'Magnify Added',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Magnify Dropdown',
            name: 'Magnify Dropdown',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Managed As',
            name: 'Managed As',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Modified By',
            name: 'Modified By',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Modified Date',
            name: 'Modified Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'NPS',
            name: 'NPS',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'NPS® Last Updated',
            name: 'NPS® Last Updated',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Name',
            name: 'Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'November 18 Picklist',
            name: 'November 18 Picklist',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'November 18 String',
            name: 'November 18 String',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Original Contract Date',
            name: 'Original Contract Date',
            type: 'date',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Overall Score Comments',
            name: 'Overall Score Comments',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Parent Company',
            name: 'Parent Company',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Previous Score',
            name: 'Previous Score',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Renewal Date',
            name: 'Renewal Date',
            type: 'date',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SFDC Account Id',
            name: 'SFDC Account Id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Scorecard Id',
            name: 'Scorecard Id',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Stage',
            name: 'Stage',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Status',
            name: 'Status',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Summary',
            name: 'Summary',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Tags',
            name: 'Tags',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Ticker Symbol',
            name: 'Ticker Symbol',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Trend',
            name: 'Trend',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Users',
            name: 'Users',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Company_Person',
        data: [
          {
            key: 'Active',
            name: 'Active',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Company ID',
            name: 'Company ID',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Created By',
            name: 'Created By',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Created Date',
            name: 'Created Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Email (Company)',
            name: 'Email (Company)',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'External Account ID',
            name: 'External Account ID',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'External Contact ID',
            name: 'External Contact ID',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'GS Ingestion Source',
            name: 'GS Ingestion Source',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'GS Modified By',
            name: 'GS Modified By',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'GSID',
            name: 'GSID',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Manager',
            name: 'Manager',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Modified Date',
            name: 'Modified Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Person ID',
            name: 'Person ID',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Primary Company',
            name: 'Primary Company',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Role',
            name: 'Role',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Title',
            name: 'Title',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'isActive',
            name: 'isActive',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Survey_Participant',
        data: [
          {
            key: 'AO Participant Id',
            name: 'AO Participant Id',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Association Id',
            name: 'Association Id',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Company Id',
            name: 'Company Id',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Created By',
            name: 'Created By',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Created Date',
            name: 'Created Date',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Deleted',
            name: 'Deleted',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Distribution Channel',
            name: 'Distribution Channel',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Email',
            name: 'Email',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'External Association Id',
            name: 'External Association Id',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'External Id',
            name: 'External Id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'External Source',
            name: 'External Source',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'First Name',
            name: 'First Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Full Name',
            name: 'Full Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'GSID',
            name: 'GSID',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Group Id',
            name: 'Group Id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Internal Question Submission Date',
            name: 'Internal Question Submission Date',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Internal Question Submitted',
            name: 'Internal Question Submitted',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Internal Question Submitted By',
            name: 'Internal Question Submitted By',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Language Id',
            name: 'Language Id',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Last Name',
            name: 'Last Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Last Response Record Time',
            name: 'Last Response Record Time',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Link Id',
            name: 'Link Id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Modified By',
            name: 'Modified By',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Modified Date',
            name: 'Modified Date',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Person Id',
            name: 'Person Id',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Relationship Id',
            name: 'Relationship Id',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Responded',
            name: 'Responded',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Responded By',
            name: 'Responded By',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Responded Date',
            name: 'Responded Date',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Response Status',
            name: 'Response Status',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Response URL',
            name: 'Response URL',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Role',
            name: 'Role',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Source Id',
            name: 'Source Id',
            type: 'lookup',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Survey Id',
            name: 'Survey Id',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Survey Opened Date',
            name: 'Survey Opened Date',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Survey Sent Date',
            name: 'Survey Sent Date',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SurveyOpened',
            name: 'SurveyOpened',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Test Participant',
            name: 'Test Participant',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Token',
            name: 'Token',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
    ],
  },
  {
    platform: 'zendesk',
    data: [
      {
        object: 'Organization',
        data: [
          {
            key: 'Created At',
            name: 'Created At',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Details',
            name: 'Details',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Domain Names',
            name: 'Domain Names',
            type: 'array',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'External Id',
            name: 'External Id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Group Id',
            name: 'Group Id',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Id',
            name: 'Id',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Name',
            name: 'Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Notes',
            name: 'Notes',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Organization Fields',
            name: 'Organization Fields',
            type: 'object',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Shared Comments',
            name: 'Shared Comments',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Shared Tickets',
            name: 'Shared Tickets',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Tags',
            name: 'Tags',
            type: 'array',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Updated At',
            name: 'Updated At',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Url',
            name: 'Url',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Request',
        data: [
          {
            key: 'Can Be Marked As Solved',
            name: 'Can Be Marked As Solved',
            type: 'boolean',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Created At',
            name: 'Created At',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Description',
            name: 'Description',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Due At',
            name: 'Due At',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Due Date',
            name: 'Due Date',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Followup Source Id',
            name: 'Followup Source Id',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Id',
            name: 'Id',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Priority',
            name: 'Priority',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Priority Name',
            name: 'Priority Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Priority Title',
            name: 'Priority Title',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Requester',
            name: 'Requester',
            type: 'object',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Status',
            name: 'Status',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Status Description',
            name: 'Status Description',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Status Name',
            name: 'Status Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Subject',
            name: 'Subject',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Type',
            name: 'Type',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Type Name',
            name: 'Type Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Type Title',
            name: 'Type Title',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Updated At',
            name: 'Updated At',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Url',
            name: 'Url',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Ticket',
        data: [
          {
            key: 'subject',
            name: 'Subject',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'comment',
            name: 'Comment',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'priority',
            name: 'Priority',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ccs',
            name: 'CCs',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'status',
            name: 'Status',
            type: 'lookup',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'tags',
            name: 'Tags',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'User',
        data: [
          {
            key: 'Active',
            name: 'Active',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Alias',
            name: 'Alias',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Chat Only',
            name: 'Chat Only',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Created At',
            name: 'Created At',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Custom Role Id',
            name: 'Custom Role Id',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Default Group Id',
            name: 'Default Group Id',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Details',
            name: 'Details',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Email',
            name: 'Email',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'External Id',
            name: 'External Id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Iana Time Zone',
            name: 'Iana Time Zone',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Id',
            name: 'Id',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Last Login At',
            name: 'Last Login At',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Locale',
            name: 'Locale',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Locale Id',
            name: 'Locale Id',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Moderator',
            name: 'Moderator',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Name',
            name: 'Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Notes',
            name: 'Notes',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Only Private Comments',
            name: 'Only Private Comments',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Organization Id',
            name: 'Organization Id',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Phone',
            name: 'Phone',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Photo',
            name: 'Photo',
            type: 'object',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Remote Photo Url',
            name: 'Remote Photo Url',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Report Csv',
            name: 'Report Csv',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Restricted Agent',
            name: 'Restricted Agent',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Role',
            name: 'Role',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Role Type',
            name: 'Role Type',
            type: 'integer',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Shared',
            name: 'Shared',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Shared Agent',
            name: 'Shared Agent',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Shared Phone Number',
            name: 'Shared Phone Number',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Signature',
            name: 'Signature',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Suspended',
            name: 'Suspended',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Tags',
            name: 'Tags',
            type: 'array',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Ticket Restriction',
            name: 'Ticket Restriction',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Time Zone',
            name: 'Time Zone',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Two Factor Auth Enabled',
            name: 'Two Factor Auth Enabled',
            type: 'boolean',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Updated At',
            name: 'Updated At',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Url',
            name: 'Url',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'User Fields',
            name: 'User Fields',
            type: 'object',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Verified',
            name: 'Verified',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Ticket.Comment',
        data: [
          {
            key: 'ticketID',
            name: 'Ticket ID',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'comment',
            name: 'Comment',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
        ],
      },
    ],
  },
  {
    platform: 'salesforce',
    data: [
      {
        object: 'Account',
        data: [
          {
            key: 'Description',
            name: 'Account Description',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Fax',
            name: 'Account Fax',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Id',
            name: 'Account ID',
            type: 'id',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Name',
            name: 'Account Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'AccountNumber',
            name: 'Account Number',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Phone',
            name: 'Account Phone',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Rating',
            name: 'Account Rating',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Site',
            name: 'Account Site',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'AccountSource',
            name: 'Account Source',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Type',
            name: 'Account Type',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Active__c',
            name: 'Active',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'AnnualRevenue',
            name: 'Annual Revenue',
            type: 'currency',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingAddress',
            name: 'Billing Address',
            type: 'address',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'BillingCity',
            name: 'Billing City',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingCountry',
            name: 'Billing Country',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingGeocodeAccuracy',
            name: 'Billing Geocode Accuracy',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingLatitude',
            name: 'Billing Latitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingLongitude',
            name: 'Billing Longitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingState',
            name: 'Billing State/Province',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingStreet',
            name: 'Billing Street',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingPostalCode',
            name: 'Billing Zip/Postal Code',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CleanStatus',
            name: 'Clean Status',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CreatedById',
            name: 'Created By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CreatedDate',
            name: 'Created Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CustomerPriority__c',
            name: 'Customer Priority',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'DandbCompanyId',
            name: 'D&B Company ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'DunsNumber',
            name: 'D-U-N-S Number',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Jigsaw',
            name: 'Data.com Key',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsDeleted',
            name: 'Deleted',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'NumberOfEmployees',
            name: 'Employees',
            type: 'int',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Industry',
            name: 'Industry',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'JigsawCompanyId',
            name: 'Jigsaw Company ID',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastActivityDate',
            name: 'Last Activity',
            type: 'date',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedById',
            name: 'Last Modified By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedDate',
            name: 'Last Modified Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastReferencedDate',
            name: 'Last Referenced Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastViewedDate',
            name: 'Last Viewed Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'M_Account_Manager__c',
            name: 'M_Account_Manager',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'M_Billing_Cycle__c',
            name: 'M_Billing_Cycle',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'M_Billing_Method__c',
            name: 'M_Billing_Method',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'M_Comments__c',
            name: 'M_Comments',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'M_Contact_Date__c',
            name: 'M_Contact_Date',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'M_Employee_Tier__c',
            name: 'M_Employee_Tier',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'M_LastModifiedDate__c',
            name: 'M_LastModifiedDate',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'M_Stage__c',
            name: 'M_Stage',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MasterRecordId',
            name: 'Master Record ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'NaicsCode',
            name: 'NAICS Code',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'NaicsDesc',
            name: 'NAICS Description',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'November_15_Date_Field__c',
            name: 'November 15 Date Field',
            type: 'date',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'November_15_checkbox__c',
            name: 'November 15 checkbox',
            type: 'boolean',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'NumberofLocations__c',
            name: 'Number of Locations',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OperatingHoursId',
            name: 'Operating Hour ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OwnerId',
            name: 'Owner ID',
            type: 'reference',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Ownership',
            name: 'Ownership',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ParentId',
            name: 'Parent Account ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'PhotoUrl',
            name: 'Photo URL',
            type: 'url',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Sic',
            name: 'SIC Code',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SicDesc',
            name: 'SIC Description',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SLA__c',
            name: 'SLA',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SLAExpirationDate__c',
            name: 'SLA Expiration Date',
            type: 'date',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SLASerialNumber__c',
            name: 'SLA Serial Number',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ShippingAddress',
            name: 'Shipping Address',
            type: 'address',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'ShippingCity',
            name: 'Shipping City',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ShippingCountry',
            name: 'Shipping Country',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ShippingGeocodeAccuracy',
            name: 'Shipping Geocode Accuracy',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ShippingLatitude',
            name: 'Shipping Latitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ShippingLongitude',
            name: 'Shipping Longitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ShippingState',
            name: 'Shipping State/Province',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ShippingStreet',
            name: 'Shipping Street',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ShippingPostalCode',
            name: 'Shipping Zip/Postal Code',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SystemModstamp',
            name: 'System Modstamp',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'TickerSymbol',
            name: 'Ticker Symbol',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Tradestyle',
            name: 'Tradestyle',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'UpsellOpportunity__c',
            name: 'Upsell Opportunity',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Website',
            name: 'Website',
            type: 'url',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'YearStarted',
            name: 'Year Started',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Case',
        data: [
          {
            key: 'AccountId',
            name: 'Account ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'AssetId',
            name: 'Asset ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Id',
            name: 'Case ID',
            type: 'id',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CaseNumber',
            name: 'Case Number',
            type: 'string',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Origin',
            name: 'Case Origin',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Reason',
            name: 'Case Reason',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SourceId',
            name: 'Case Source ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Type',
            name: 'Case Type',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsClosed',
            name: 'Closed',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'ClosedDate',
            name: 'Closed Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'SuppliedCompany',
            name: 'Company',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ContactEmail',
            name: 'Contact Email',
            type: 'email',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'ContactFax',
            name: 'Contact Fax',
            type: 'phone',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'ContactId',
            name: 'Contact ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ContactMobile',
            name: 'Contact Mobile',
            type: 'phone',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'ContactPhone',
            name: 'Contact Phone',
            type: 'phone',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'CreatedById',
            name: 'Created By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CreatedDate',
            name: 'Created Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'IsDeleted',
            name: 'Deleted',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Description',
            name: 'Description',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SuppliedEmail',
            name: 'Email Address',
            type: 'email',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'EngineeringReqNumber__c',
            name: 'Engineering Req Number',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsEscalated',
            name: 'Escalated',
            type: 'boolean',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Comments',
            name: 'Internal Comments',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'LastModifiedById',
            name: 'Last Modified By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedDate',
            name: 'Last Modified Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastReferencedDate',
            name: 'Last Referenced Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastViewedDate',
            name: 'Last Viewed Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'MasterRecordId',
            name: 'Master Record ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'SuppliedName',
            name: 'Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OwnerId',
            name: 'Owner ID',
            type: 'reference',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'ParentId',
            name: 'Parent Case ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SuppliedPhone',
            name: 'Phone',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'PotentialLiability__c',
            name: 'Potential Liability',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Priority',
            name: 'Priority',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Product__c',
            name: 'Product',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SLAViolation__c',
            name: 'SLA Violation',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Status',
            name: 'Status',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Subject',
            name: 'Subject',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SystemModstamp',
            name: 'System Modstamp',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Zendesk_Support_Ticket_Assignee_Name__c',
            name: 'Zendesk Support Ticket Assignee Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Brand_Name__c',
            name: 'Zendesk Support Ticket Brand Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Created_At__c',
            name: 'Zendesk Support Ticket Created At',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Form_Name__c',
            name: 'Zendesk Support Ticket Form Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Group_Name__c',
            name: 'Zendesk Support Ticket Group Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_ID__c',
            name: 'Zendesk Support Ticket ID',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Initial_Assigned__c',
            name: 'Zendesk Support Ticket Initial Assigned',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Organization_Name__c',
            name: 'Zendesk Support Ticket Organization Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Requester_As_Lead__c',
            name: 'Zendesk Support Ticket Requester As Lead',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Requester_Email__c',
            name: 'Zendesk Support Ticket Requester Email',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Requester_Name__c',
            name: 'Zendesk Support Ticket Requester Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Resolution_Bus__c',
            name: 'Zendesk Support Ticket Resolution (Bus)',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Resolution_Cal__c',
            name: 'Zendesk Support Ticket Resolution (Cal)',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Solved_At__c',
            name: 'Zendesk Support Ticket Solved At',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Tags__c',
            name: 'Zendesk Support Ticket Tag',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_URL__c',
            name: 'Zendesk Support Ticket URL',
            type: 'url',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Zendesk_Support_Ticket_Updated_At__c',
            name: 'Zendesk Support Ticket Updated At',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Contact',
        data: [
          {
            key: 'AccountId',
            name: 'Account ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'AssistantName',
            name: "Assistant's Name",
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'AssistantPhone',
            name: 'Asst. Phone',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Birthdate',
            name: 'Birthdate',
            type: 'date',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Fax',
            name: 'Business Fax',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Phone',
            name: 'Business Phone',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CleanStatus',
            name: 'Clean Status',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Description',
            name: 'Contact Description',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Id',
            name: 'Contact ID',
            type: 'id',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CreatedById',
            name: 'Created By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CreatedDate',
            name: 'Created Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Jigsaw',
            name: 'Data.com Key',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsDeleted',
            name: 'Deleted',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Department',
            name: 'Department',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Email',
            name: 'Email',
            type: 'email',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'EmailBouncedDate',
            name: 'Email Bounced Date',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'EmailBouncedReason',
            name: 'Email Bounced Reason',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'FirstName',
            name: 'First Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Name',
            name: 'Full Name',
            type: 'string',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'HomePhone',
            name: 'Home Phone',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IndividualId',
            name: 'Individual ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsEmailBounced',
            name: 'Is Email Bounced',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'JigsawContactId',
            name: 'Jigsaw Contact ID',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Languages__c',
            name: 'Languages',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'LastActivityDate',
            name: 'Last Activity',
            type: 'date',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedById',
            name: 'Last Modified By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedDate',
            name: 'Last Modified Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastName',
            name: 'Last Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'LastReferencedDate',
            name: 'Last Referenced Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastCURequestDate',
            name: 'Last Stay-in-Touch Request Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastCUUpdateDate',
            name: 'Last Stay-in-Touch Save Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastViewedDate',
            name: 'Last Viewed Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LeadSource',
            name: 'Lead Source',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Level__c',
            name: 'Level',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MailingAddress',
            name: 'Mailing Address',
            type: 'address',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'MailingCity',
            name: 'Mailing City',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MailingCountry',
            name: 'Mailing Country',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MailingGeocodeAccuracy',
            name: 'Mailing Geocode Accuracy',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MailingLatitude',
            name: 'Mailing Latitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MailingLongitude',
            name: 'Mailing Longitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MailingState',
            name: 'Mailing State/Province',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MailingStreet',
            name: 'Mailing Street',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MailingPostalCode',
            name: 'Mailing Zip/Postal Code',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MasterRecordId',
            name: 'Master Record ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'MobilePhone',
            name: 'Mobile Phone',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OtherAddress',
            name: 'Other Address',
            type: 'address',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'OtherCity',
            name: 'Other City',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OtherCountry',
            name: 'Other Country',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OtherGeocodeAccuracy',
            name: 'Other Geocode Accuracy',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OtherLatitude',
            name: 'Other Latitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OtherLongitude',
            name: 'Other Longitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OtherPhone',
            name: 'Other Phone',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OtherState',
            name: 'Other State/Province',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OtherStreet',
            name: 'Other Street',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OtherPostalCode',
            name: 'Other Zip/Postal Code',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OwnerId',
            name: 'Owner ID',
            type: 'reference',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'PhotoUrl',
            name: 'Photo URL',
            type: 'url',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'ReportsToId',
            name: 'Reports To ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Salutation',
            name: 'Salutation',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SystemModstamp',
            name: 'System Modstamp',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Title',
            name: 'Title',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Contract',
        data: [
          {
            key: 'AccountId',
            name: 'Account ID',
            type: 'reference',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'ActivatedById',
            name: 'Activated By ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: false,
          },
          {
            key: 'ActivatedDate',
            name: 'Activated Date',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: false,
          },
          {
            key: 'BillingAddress',
            name: 'Billing Address',
            type: 'address',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'BillingCity',
            name: 'Billing City',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingCountry',
            name: 'Billing Country',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingGeocodeAccuracy',
            name: 'Billing Geocode Accuracy',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingLatitude',
            name: 'Billing Latitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingLongitude',
            name: 'Billing Longitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingState',
            name: 'Billing State/Province',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingStreet',
            name: 'Billing Street',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'BillingPostalCode',
            name: 'Billing Zip/Postal Code',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CompanySignedId',
            name: 'Company Signed By ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CompanySignedDate',
            name: 'Company Signed Date',
            type: 'date',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'EndDate',
            name: 'Contract End Date',
            type: 'date',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Id',
            name: 'Contract ID',
            type: 'id',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'ContractNumber',
            name: 'Contract Number',
            type: 'string',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'StartDate',
            name: 'Contract Start Date',
            type: 'date',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ContractTerm',
            name: 'Contract Term',
            type: 'int',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CreatedById',
            name: 'Created By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CreatedDate',
            name: 'Created Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CustomerSignedId',
            name: 'Customer Signed By ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CustomerSignedDate',
            name: 'Customer Signed Date',
            type: 'date',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CustomerSignedTitle',
            name: 'Customer Signed Title',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsDeleted',
            name: 'Deleted',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Description',
            name: 'Description',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'LastActivityDate',
            name: 'Last Activity',
            type: 'date',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastApprovedDate',
            name: 'Last Approved Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedById',
            name: 'Last Modified By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedDate',
            name: 'Last Modified Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastReferencedDate',
            name: 'Last Referenced Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastViewedDate',
            name: 'Last Viewed Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'OwnerExpirationNotice',
            name: 'Owner Expiration Notice',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OwnerId',
            name: 'Owner ID',
            type: 'reference',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Pricebook2Id',
            name: 'Price Book ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SpecialTerms',
            name: 'Special Terms',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Status',
            name: 'Status',
            type: 'picklist',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'StatusCode',
            name: 'Status Category',
            type: 'picklist',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'SystemModstamp',
            name: 'System Modstamp',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
        ],
      },
      {
        object: 'Lead',
        data: [
          {
            key: 'Address',
            name: 'Address',
            type: 'address',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'AnnualRevenue',
            name: 'Annual Revenue',
            type: 'currency',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'City',
            name: 'City',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CleanStatus',
            name: 'Clean Status',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Company',
            name: 'Company',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'CompanyDunsNumber',
            name: 'Company D-U-N-S Number',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsConverted',
            name: 'Converted',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: true,
          },
          {
            key: 'ConvertedAccountId',
            name: 'Converted Account ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'ConvertedContactId',
            name: 'Converted Contact ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'ConvertedDate',
            name: 'Converted Date',
            type: 'date',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'ConvertedOpportunityId',
            name: 'Converted Opportunity ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Country',
            name: 'Country',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CreatedById',
            name: 'Created By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CreatedDate',
            name: 'Created Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CurrentGenerators__c',
            name: 'Current Generator(s)',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'DandbCompanyId',
            name: 'D&B Company ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Jigsaw',
            name: 'Data.com Key',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsDeleted',
            name: 'Deleted',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Description',
            name: 'Description',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Email',
            name: 'Email',
            type: 'email',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'EmailBouncedDate',
            name: 'Email Bounced Date',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: false,
          },
          {
            key: 'EmailBouncedReason',
            name: 'Email Bounced Reason',
            type: 'string',
            required: false,
            updateable: true,
            createable: false,
          },
          {
            key: 'NumberOfEmployees',
            name: 'Employees',
            type: 'int',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Fax',
            name: 'Fax',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'FirstName',
            name: 'First Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Name',
            name: 'Full Name',
            type: 'string',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'GeocodeAccuracy',
            name: 'Geocode Accuracy',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IndividualId',
            name: 'Individual ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Industry',
            name: 'Industry',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'JigsawContactId',
            name: 'Jigsaw Contact ID',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastActivityDate',
            name: 'Last Activity',
            type: 'date',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedById',
            name: 'Last Modified By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedDate',
            name: 'Last Modified Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastName',
            name: 'Last Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'LastReferencedDate',
            name: 'Last Referenced Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastViewedDate',
            name: 'Last Viewed Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Latitude',
            name: 'Latitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Id',
            name: 'Lead ID',
            type: 'id',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LeadSource',
            name: 'Lead Source',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Longitude',
            name: 'Longitude',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MasterRecordId',
            name: 'Master Record ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'MobilePhone',
            name: 'Mobile Phone',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'NumberofLocations__c',
            name: 'Number of Locations',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OwnerId',
            name: 'Owner ID',
            type: 'reference',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Phone',
            name: 'Phone',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'PhotoUrl',
            name: 'Photo URL',
            type: 'url',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Primary__c',
            name: 'Primary',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ProductInterest__c',
            name: 'Product Interest',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Rating',
            name: 'Rating',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SICCode__c',
            name: 'SIC Code',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Salutation',
            name: 'Salutation',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'State',
            name: 'State/Province',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Status',
            name: 'Status',
            type: 'picklist',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Street',
            name: 'Street',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SystemModstamp',
            name: 'System Modstamp',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Title',
            name: 'Title',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsUnreadByOwner',
            name: 'Unread By Owner',
            type: 'boolean',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Website',
            name: 'Website',
            type: 'url',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'PostalCode',
            name: 'Zip/Postal Code',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Opportunity',
        data: [
          {
            key: 'AccountId',
            name: 'Account ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Amount',
            name: 'Amount',
            type: 'currency',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CampaignId',
            name: 'Campaign ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CloseDate',
            name: 'Close Date',
            type: 'date',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsClosed',
            name: 'Closed',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'ContactId',
            name: 'Contact ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: true,
          },
          {
            key: 'CreatedById',
            name: 'Created By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CreatedDate',
            name: 'Created Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CurrentGenerators__c',
            name: 'Current Generator(s)',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsDeleted',
            name: 'Deleted',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'DeliveryInstallationStatus__c',
            name: 'Delivery/Installation Status',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Description',
            name: 'Description',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ExpectedRevenue',
            name: 'Expected Amount',
            type: 'currency',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Fiscal',
            name: 'Fiscal Period',
            type: 'string',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'FiscalQuarter',
            name: 'Fiscal Quarter',
            type: 'int',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'FiscalYear',
            name: 'Fiscal Year',
            type: 'int',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'ForecastCategory',
            name: 'Forecast Category',
            type: 'picklist',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'ForecastCategoryName',
            name: 'Forecast Category',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'HasOpportunityLineItem',
            name: 'Has Line Item',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'HasOpenActivity',
            name: 'Has Open Activity',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'HasOverdueTask',
            name: 'Has Overdue Task',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastActivityDate',
            name: 'Last Activity',
            type: 'date',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedById',
            name: 'Last Modified By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedDate',
            name: 'Last Modified Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastReferencedDate',
            name: 'Last Referenced Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastStageChangeDate',
            name: 'Last Stage Change Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastViewedDate',
            name: 'Last Viewed Date',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LeadSource',
            name: 'Lead Source',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'MainCompetitors__c',
            name: 'Main Competitor(s)',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Name',
            name: 'Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'NextStep',
            name: 'Next Step',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'November_15_Text_Field__c',
            name: 'November 15 12 Char Text',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'LastAmountChangedHistoryId',
            name: 'Opportunity History ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastCloseDateChangedHistoryId',
            name: 'Opportunity History ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Id',
            name: 'Opportunity ID',
            type: 'id',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Type',
            name: 'Opportunity Type',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OrderNumber__c',
            name: 'Order Number',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'OwnerId',
            name: 'Owner ID',
            type: 'reference',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Pricebook2Id',
            name: 'Price Book ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsPrivate',
            name: 'Private',
            type: 'boolean',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Probability',
            name: 'Probability (%)',
            type: 'percent',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'PushCount',
            name: 'Push Count',
            type: 'int',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'TotalOpportunityQuantity',
            name: 'Quantity',
            type: 'double',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'StageName',
            name: 'Stage',
            type: 'picklist',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'SystemModstamp',
            name: 'System Modstamp',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'TrackingNumber__c',
            name: 'Tracking Number',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'White_Glove__c',
            name: 'White Glove',
            type: 'picklist',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsWon',
            name: 'Won',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
        ],
      },
      {
        object: 'Task',
        data: [
          {
            key: 'AccountId',
            name: 'Account ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'Id',
            name: 'Activity ID',
            type: 'id',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'IsArchived',
            name: 'Archived',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'OwnerId',
            name: 'Assigned To ID',
            type: 'reference',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'CallDurationInSeconds',
            name: 'Call Duration',
            type: 'int',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CallObject',
            name: 'Call Object Identifier',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CallDisposition',
            name: 'Call Result',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'CallType',
            name: 'Call Type',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsClosed',
            name: 'Closed',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CompletedDateTime',
            name: 'Completed Date/Time',
            type: 'datetime',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'IsRecurrence',
            name: 'Create Recurring Series of Tasks',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: true,
          },
          {
            key: 'CreatedById',
            name: 'Created By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'CreatedDate',
            name: 'Created Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'IsDeleted',
            name: 'Deleted',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'Description',
            name: 'Description',
            type: 'textarea',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ActivityDate',
            name: 'Due Date Only',
            type: 'date',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsHighPriority',
            name: 'High Priority',
            type: 'boolean',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedById',
            name: 'Last Modified By ID',
            type: 'reference',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'LastModifiedDate',
            name: 'Last Modified Date',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'WhoId',
            name: 'Name ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Priority',
            name: 'Priority',
            type: 'picklist',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'RecurrenceActivityId',
            name: 'Recurrence Activity ID',
            type: 'reference',
            required: false,
            updateable: false,
            createable: false,
          },
          {
            key: 'RecurrenceDayOfMonth',
            name: 'Recurrence Day of Month',
            type: 'int',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'RecurrenceDayOfWeekMask',
            name: 'Recurrence Day of Week Mask',
            type: 'int',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'RecurrenceEndDateOnly',
            name: 'Recurrence End',
            type: 'date',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'RecurrenceInstance',
            name: 'Recurrence Instance',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'RecurrenceInterval',
            name: 'Recurrence Interval',
            type: 'int',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'RecurrenceMonthOfYear',
            name: 'Recurrence Month of Year',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'RecurrenceStartDateOnly',
            name: 'Recurrence Start',
            type: 'date',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'RecurrenceTimeZoneSidKey',
            name: 'Recurrence Time Zone',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'RecurrenceType',
            name: 'Recurrence Type',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'WhatId',
            name: 'Related To ID',
            type: 'reference',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ReminderDateTime',
            name: 'Reminder Date/Time',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'IsReminderSet',
            name: 'Reminder Set',
            type: 'boolean',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'RecurrenceRegeneratedType',
            name: 'Repeat This Task',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Status',
            name: 'Status',
            type: 'picklist',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Subject',
            name: 'Subject',
            type: 'combobox',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'SystemModstamp',
            name: 'System Modstamp',
            type: 'datetime',
            required: true,
            updateable: false,
            createable: false,
          },
          {
            key: 'TaskSubtype',
            name: 'Task Subtype',
            type: 'picklist',
            required: false,
            updateable: false,
            createable: true,
          },
        ],
      },
    ],
  },
  {
    platform: 'snowflake',
    data: [
      {
        object: 'CLOUD_INSTANCE_USAGE',
        data: [
          {
            key: 'account_id',
            name: 'ACCOUNT_ID',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'account_name',
            name: 'ACCOUNT_NAME',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'category',
            name: 'CATEGORY',
            type: '',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'close_date',
            name: 'CLOSE_DATE',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Free Tier',
            name: 'FREE_TIER',
            type: 'boolean',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Successful Actions',
            name: 'IS_SUCCESSFUL',
            type: 'boolean',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'provision_date',
            name: 'PROVISION_DATE',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Provision Time',
            name: 'PROVISION_TIME',
            type: 'double',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'record_date',
            name: 'RECORD_DATE',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'status',
            name: 'STATUS',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'tag',
            name: 'TAG',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'usage',
            name: 'USAGE',
            type: 'double',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'usage_date',
            name: 'USAGE_DATE',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'Usage Minutes',
            name: 'USAGE_TIME',
            type: 'double',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'user_id',
            name: 'USER_ID',
            type: 'double',
            required: true,
            updateable: true,
            createable: true,
          },
        ],
      },
    ],
  },
  {
    platform: 'magnify',
    data: [
      {
        object: 'Email',
        data: [
          {
            key: 'senderName',
            name: 'Sender Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'senderEmail',
            name: 'Sender Email Address',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'recipientName',
            name: 'Recipient Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'recipientEmail',
            name: 'Recipient Email Address',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'replyToName',
            name: 'Reply To Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'replyToEmail',
            name: 'Reply To Email Address',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'subject',
            name: 'Subject',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'template',
            name: 'Email',
            type: 'html',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'templateId',
            name: 'Template ID',
            type: 'hidden',
            required: false,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'templateVersion',
            name: 'Template Version',
            type: 'hidden',
            required: false,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
        ],
      },
    ],
  },
  {
    platform: 'intercom',
    data: [
      {
        object: 'Tickets',
        data: [
          {
            key: 'ticketName',
            name: 'Ticket Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'ticketTitle',
            name: 'Ticket Title',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'ticketDescription',
            name: 'Ticket Description',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
        ],
      },
      {
        object: 'Companies',
        data: [
          {
            key: 'companyId',
            name: 'Company ID',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'companyName',
            name: 'Company Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'companyDesciption',
            name: 'Company Decsription',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'tags',
            name: 'Tags',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
        ],
      },
      {
        object: 'Contacts',
        data: [
          {
            key: 'android_app_version',
            name: 'android_app_version',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'android_last_seen_at',
            name: 'android_last_seen_at',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'android_os_version',
            name: 'android_os_version',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'android_sdk_version',
            name: 'android_sdk_version',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'avatar',
            name: 'avatar',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'browser',
            name: 'browser',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'browser_version',
            name: 'browser_version',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'created_at',
            name: 'created_at',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'email',
            name: 'email',
            type: 'email',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'has_hard_bounced',
            name: 'has_hard_bounced',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ios_app_version',
            name: 'ios_app_version',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ios_last_seen_at',
            name: 'ios_last_seen_at',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ios_os_version',
            name: 'ios_os_version',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'ios_sdk_version',
            name: 'ios_sdk_version',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'last_contacted_at',
            name: 'last_contacted_at',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'last_email_clicked_at',
            name: 'last_email_clicked_at',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'last_email_opened_at',
            name: 'last_email_opened_at',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'last_replied_at',
            name: 'last_replied_at',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'last_seen_at',
            name: 'last_seen_at',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'location__city',
            name: 'location__city',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'location__country',
            name: 'location__country',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'marked_email_as_spam',
            name: 'marked_email_as_spam',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'name',
            name: 'name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'os',
            name: 'os',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'phone',
            name: 'phone',
            type: 'phone',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'signed_up_at',
            name: 'signed_up_at',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'sms_consent',
            name: 'sms_consent',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'unsubscribed_from_emails',
            name: 'unsubscribed_from_emails',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'unsubscribed_from_sms',
            name: 'unsubscribed_from_sms',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'updated_at',
            name: 'updated_at',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
    ],
  },
  {
    platform: 'slack',
    data: [
      {
        object: 'Message',
        data: [
          {
            key: 'to',
            name: 'To (Recipient)',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'from',
            name: 'From (On Behalf Of)',
            type: 'select',
            required: true,
            updateable: true,
            createable: true,
            tooltip:
              'To send messages on behalf of a user, the user must explicitly grant the Magnify app permissions in Slack or else this action will fail',
          },
          {
            key: 'messageContent',
            name: 'Message',
            type: 'html',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'unfurlLinks',
            name: 'Unfurl Media & Links',
            type: 'boolean',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'messageId',
            name: 'Message Id',
            type: 'hidden',
            required: false,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'messageVersion',
            name: 'Message Version',
            type: 'hidden',
            required: false,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
        ],
      },
      {
        object: 'Channel',
        data: [
          {
            key: 'to',
            name: 'Slack Channel',
            type: 'picklist',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'from',
            name: 'From (On Behalf Of)',
            type: 'select',
            required: true,
            updateable: true,
            createable: true,
            tooltip:
              'To send messages on behalf of a user, the user must belong to the specified channel and explicitly grant the Magnify app permissions in Slack or else this action will fail',
          },
          {
            key: 'messageContent',
            name: 'Message',
            type: 'html',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'unfurlLinks',
            name: 'Unfurl Media & Links',
            type: 'boolean',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'messageId',
            name: 'Message Id',
            type: 'hidden',
            required: false,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
          {
            key: 'messageVersion',
            name: 'Message Version',
            type: 'hidden',
            required: false,
            updateable: true,
            createable: true,
            onlyDynamicInput: false,
          },
        ],
      },
    ],
  },
  {
    platform: 'marketo',
    data: [
      {
        object: 'Campaign',
        data: [
          {
            key: 'campaign_id',
            name: 'Campaign',
            type: 'lookup',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'email',
            name: 'Email Address Field',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
            onlyDynamicInput: true,
          },
          {
            key: 'firstName',
            name: 'First Name Field',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
            onlyDynamicInput: true,
          },
          {
            key: 'lastName',
            name: 'Last Name Field',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
            onlyDynamicInput: true,
          },
        ],
      },
      {
        object: 'sendemail',
        data: [
          {
            key: 'campaign_id',
            name: 'Campaign',
            type: 'lookup',
            required: true,
            updateable: true,
            createable: true,
          },
        ],
      },
    ],
  },
  {
    platform: 'pendo',
    data: [
      {
        object: 'Account',
        data: [
          {
            key: 'metadata__agent__name',
            name: 'metadata__agent__name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__pendo__blacklistguides',
            name: 'metadata__pendo__blacklistguides',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__pendo__donotprocess',
            name: 'metadata__pendo__donotprocess',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'name',
            name: 'name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Guide_events',
        data: [
          {
            key: 'browsertime',
            name: 'browsertime',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'country',
            name: 'country',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'latitude',
            name: 'latitude',
            type: 'decimal',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'loadtime',
            name: 'loadtime',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'longitude',
            name: 'longitude',
            type: 'decimal',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'url',
            name: 'url',
            type: 'url',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'useragent',
            name: 'useragent',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Feature_events',
        data: [
          {
            key: 'numevents',
            name: 'numevents',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'numminutes',
            name: 'numminutes',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'useragent',
            name: 'useragent',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Page_events',
        data: [
          {
            key: 'numevents',
            name: 'numevents',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'numminutes',
            name: 'numminutes',
            type: 'integer',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'useragent character',
            name: 'useragent character',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Visitor',
        data: [
          {
            key: 'metadata__agent__company_name',
            name: 'metadata__agent__company_name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__agent__email',
            name: 'metadata__agent__email',
            type: 'email',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__agent__first_name',
            name: 'metadata__agent__first_name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__agent__is_forecast_premium',
            name: 'metadata__agent__is_forecast_premium',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__agent__is_staff',
            name: 'metadata__agent__is_staff',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__agent__last_name',
            name: 'metadata__agent__last_name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__agent__uuid',
            name: 'metadata__agent__uuid',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__auto__firstvisit',
            name: 'metadata__auto__firstvisit',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__auto__lastbrowsername',
            name: 'metadata__auto__lastbrowsername',
            type: 'picklist',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__auto__lastbrowserversion',
            name: 'metadata__auto__lastbrowserversion',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__auto__lastupdated',
            name: 'metadata__auto__lastupdated',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__auto__lastupdated ',
            name: 'metadata__auto__lastupdated ',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__auto__lastuseragent',
            name: 'metadata__auto__lastuseragent',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__auto__lastvisit',
            name: 'metadata__auto__lastvisit',
            type: 'datetime',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__pendo__blacklistguides',
            name: 'metadata__pendo__blacklistguides',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__pendo__designerenabled',
            name: 'metadata__pendo__designerenabled',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'metadata__pendo__donotprocess',
            name: 'metadata__pendo__donotprocess',
            type: 'boolean',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'name',
            name: 'name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
    ],
  },
  {
    platform: 'hubspot',
    data: [
      {
        object: 'Send_Transactional_Email',
        data: [
          {
            key: 'emailId',
            name: 'Email Template',
            type: 'lookup',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'senderName',
            name: 'Sender Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'senderEmail',
            name: 'Sender Email Address',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'recipientEmail',
            name: 'Recipient Email Address',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Contact',
        data: [
          {
            key: 'user_id',
            name: 'User ID',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'email',
            name: 'Email Address',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'first_name',
            name: 'First Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'last_name',
            name: 'Last Name',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'title',
            name: 'Title',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'location',
            name: 'Location',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Email_event',
        data: [
          {
            key: 'id',
            name: 'id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'app_id',
            name: 'App_id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'portal_id',
            name: 'Portal_id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'Sent_by_id',
            name: 'Sent_by_id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'type',
            name: 'Type',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Email_campaign',
        data: [
          {
            key: 'id',
            name: 'ID',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'app_id',
            name: 'App_id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'type',
            name: 'Type',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'Marketing_email',
        data: [
          {
            key: 'id',
            name: 'id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'campaign',
            name: 'campaign',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'author_user_id',
            name: 'author_user_id',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
          {
            key: 'email_type',
            name: 'email_type',
            type: 'string',
            required: false,
            updateable: true,
            createable: true,
          },
        ],
      },
    ],
  },
  {
    platform: 'magnify-insights',
    data: [
      {
        object: 'account_insights',
        data: [
          {
            key: 'arr_elift_improve_by',
            name: 'ARR Improvement',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'account_id',
            name: 'Account ID',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'account_name',
            name: 'Account Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'dimension',
            name: 'Dimension',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'driver_feature',
            name: 'Driver Feature',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'driver_id',
            name: 'Driver ID',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'driver_need_improve_to',
            name: 'Driver Target Value',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'driver_value',
            name: 'Driver Value',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'arr_event',
            name: 'Forecasted ARR',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'dataset_date',
            name: 'Insight Created Date',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'event',
            name: 'Insight Type',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'prob',
            name: 'Probability',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'prob_improve_by',
            name: 'Probability Improvement',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'quarter_short',
            name: 'Quarter',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'user_count',
            name: 'User Count',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'trend_revenues',
        data: [
          {
            key: 'arr',
            name: 'ARR',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'arr_type',
            name: 'ARR Type',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'account_id',
            name: 'Account ID',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'account_name',
            name: 'Account Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'dimension',
            name: 'Dimension',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'dataset_date',
            name: 'Insight Created Date',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'quarter_short',
            name: 'Quarter',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'user_count',
            name: 'User Count',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'event_revenues',
        data: [
          {
            key: 'account_id',
            name: 'Account ID',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'account_name',
            name: 'Account Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'dimension',
            name: 'Dimension',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'arr_forecast',
            name: 'Forecasted ARR',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'dataset_date',
            name: 'Insight Created Date',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'event',
            name: 'Insight Type',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'prob',
            name: 'Probability',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'prob_segment',
            name: 'Probability Segment',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'quarter_short',
            name: 'Quarter',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'user_count',
            name: 'User Count',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
        ],
      },
      {
        object: 'retention_revenues',
        data: [
          {
            key: 'arr_churn',
            name: 'ARR Churn',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'arr_expand',
            name: 'ARR Expansion',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'arr_renew',
            name: 'ARR Renewal',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'account_id',
            name: 'Account ID',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'account_name',
            name: 'Account Name',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'contract_renew_date',
            name: 'Contract Renewal Date',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'dimension',
            name: 'Dimension',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'dataset_date',
            name: 'Insight Created Date',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'quarter_short',
            name: 'Quarter',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'user_count',
            name: 'User Count',
            type: 'integer',
            required: true,
            updateable: true,
            createable: true,
          },
        ],
      },
    ],
  },
  {
    platform: 'chorusai',
    data: [
      {
        object: 'scorecard_score',
        data: [
          {
            key: 'question_id',
            name: 'Question ID',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'scorecard_id',
            name: 'Scorecard ID',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'comment_text',
            name: 'Comment Text',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'question_text',
            name: 'Question Text',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
          {
            key: 'score',
            name: 'Score',
            type: 'string',
            required: true,
            updateable: true,
            createable: true,
          },
        ],
      },
    ],
  },
]

export const demoGetDynamicInputsMetadataOptions = {
  data: [
    {
      platform: 'salesforce',
      objects: ['Account', 'Case', 'Contact', 'Contract', 'Lead', 'Opportunity', 'Task'],
    },
    {
      platform: 'Gainsight',
      objects: ['Activity_Timeline', 'Company_Person', 'CTA', 'Company', 'Survey_Participant'],
    },
    {
      platform: 'snowflake',
      objects: ['CLOUD_INSTANCE_USAGE'],
    },
    {
      platform: 'pendo',
      objects: ['Account', 'Feature_events', 'Feature_usage', 'Guide_events', 'Guide_usage'],
    },
    {
      platform: 'intercom',
      objects: ['Companies', 'Contacts', 'Tickets'],
    },
    {
      platform: 'hubspot',
      objects: ['Contact', 'Email_event', 'Email_campaign', 'Marketing_email', 'Send_Transactional_Email'],
    },
    {
      platform: 'magnify-insights',
      objects: ['account_insights', 'trend_revenues', 'event_revenues', 'retention_revenues'],
    },
    {
      platform: 'chorusai',
      objects: [
        'scorecard_score',
        'scorecard',
        'engagement_participant',
        'tracker',
        'engagement',
        'user_team',
        'users',
      ],
    },
  ],
}
