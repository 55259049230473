import { Col, Dropdown, Form, Input, Row, Button as AntBtn } from 'antd'
import Modal from 'antd/lib/modal/Modal'
import classNames from 'classnames'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import EllipsisVertical from 'assets/images/ellipsis-vertical.svg?react'
import {
  Button,
  Callout,
  IconClose,
  IconFeedbackAlert,
  IconPenUnderlined,
  IconScheduleExecution,
} from 'components/common'
import { displayMotionErrorPopUp } from 'components/MotionBuilder/MotionErrorPopUp'
import { archiveMotion, getMotionCalloutMessage, cancelMotion, cloneMotion } from 'pages/Motions/Motion.utils'
import useStore from 'store/useStore'

import type { FormInstance, MenuProps } from 'antd'

import type { Motion } from 'models/motion.model'
import { MotionStateEnum } from 'models/motion.model'

interface MotionTopBarProps {
  isEditingMotionTitle: boolean
  form: FormInstance<any>
  setIsEditingMotionTitle: (isEditing: boolean) => void
  handleOnSave: (options: { updateMotionUrl: boolean; confirmCloseModal: boolean }) => void
  isSaveDisabled: boolean
  closeRedirectPath: string
}

const MotionTopBar = ({
  isEditingMotionTitle,
  form,
  setIsEditingMotionTitle,
  handleOnSave,
  isSaveDisabled,
  closeRedirectPath,
}: MotionTopBarProps) => {
  const [showDeletConfirmationModal, setShowDeletConfirmationModal] = useState(false)
  const [isDeleteMotionLoading, setIsDeleteMotionLoading] = useState(false)

  const { motionStore, motionGoalsStore } = useStore()
  const navigate = useNavigate()

  const motionTitle = motionGoalsStore.currentMotionGoals.title

  // Update the form with the most recent title value if it is changed outside of the form.
  useEffect(() => {
    form.setFieldsValue({ title: motionTitle })
  }, [form, motionTitle])

  const changeMotionName = (title: string) => {
    setIsEditingMotionTitle(false)
    motionGoalsStore.setCurrentMotionGoals({ title })
  }

  const handleOnBlurTitle = (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.target.value) {
      changeMotionName(event.target.value)
      form.setFieldsValue({ title: event.target.value })
    } else {
      event.stopPropagation()
    }
  }

  const handleValidationDisplay = () => {
    if (motionStore.currentMotion?.validationErrors) {
      displayMotionErrorPopUp(motionStore.currentMotion?.validationErrors)
    }
  }

  const handleMotionScheduleClick = () => {
    // Handles scenario that it is a new Motion that hasn't been persisted to the backend
    if (motionStore.currentMotion && motionStore.currentMotion.createdBy !== undefined) {
      // Validates that the Motion is of type `Motion` and has been persisted to the backend
      motionStore.setDisplayExecuteModal(true, motionStore.currentMotion as Motion)
    }
  }

  const handleViewReportingClick = () => {
    motionStore.setIsInMotionReportingEnabled(false)

    const motionId = (motionStore.currentMotion as Motion).playbookId
    const motionVersion = (motionStore.currentMotion as Motion).version
    navigate(`/journeys/journey/${motionId}/${motionVersion}/details`)
  }

  const handleDeleteMotionConfirm = () => {
    setIsDeleteMotionLoading(true)

    archiveMotion(motionStore?.currentMotion as Motion, motionStore.archive, motionStore.getAll)
      .catch(console.error)
      .finally(() => {
        setShowDeletConfirmationModal(false)
        setIsDeleteMotionLoading(true)
        navigate(`/motions`)
      })
  }

  const handleDeleteModalCancel = () => {
    setShowDeletConfirmationModal(false)
  }

  const items: MenuProps['items'] = [
    {
      label: 'Clone',
      key: 'clone',
      className: classNames('dropdown__item'),
      onClick: () => cloneMotion(motionStore.currentMotion as Motion, motionStore.clone, motionStore.getAll),
    },
    {
      label: 'Cancel',
      key: 'cancel',
      className: classNames('dropdown__item'),
      onClick: () => cancelMotion(motionStore.currentMotion as Motion, motionStore.cancel, motionStore.getAll),
      disabled: motionStore.currentMotion?.currState !== MotionStateEnum.Executing,
    },
    {
      label: 'Delete',
      key: 'delete',
      className: classNames('dropdown__item'),
      onClick: () => setShowDeletConfirmationModal(true),
    },
  ]

  return (
    <>
      <Row className='motion'>
        <Col md={14}>
          {isEditingMotionTitle ? (
            <Form
              form={form}
              name='motion_title_form'
              labelCol={{
                span: 10,
              }}
              autoComplete='off'
              style={{ display: 'flex' }}
              initialValues={{
                title: motionTitle,
              }}>
              <Form.Item
                name='title'
                colon={false}
                rules={[
                  {
                    required: true,
                    message: "Name can't be empty",
                  },
                ]}
                validateStatus={motionTitle ? 'success' : 'error'}
                data-testid='motion__title__input'
                className='motion__title__item'>
                <Input autoFocus={true} onBlur={handleOnBlurTitle} className='motion-title__input' />
              </Form.Item>
            </Form>
          ) : (
            <Row align='middle'>
              <span className='motion__name m-r-16' onClick={() => setIsEditingMotionTitle(true)}>
                {motionTitle}
              </span>
              {!motionStore.isInMotionReportingEnabled && (
                <IconPenUnderlined
                  className='motion__edit-btn m-r-10'
                  data-testid='edit-title-btn'
                  onClick={() => setIsEditingMotionTitle(true)}
                />
              )}

              <Callout variant='2'>{getMotionCalloutMessage(motionStore.currentMotion)}</Callout>

              {!!motionStore.currentMotion?.validationErrors?.length && (
                <IconFeedbackAlert
                  onClick={handleValidationDisplay}
                  className='clickable m-l-15'
                  data-testid='errors-icon'
                />
              )}
            </Row>
          )}
        </Col>

        <Col md={10}>
          <Row align='middle' justify='end'>
            <Dropdown
              overlayClassName='motions-table-row-actions'
              menu={{ items }}
              placement='bottomRight'
              trigger={['click']}>
              <AntBtn data-testid='motion-actions-button' className='motions-table-row-actions-button m-r-5'>
                <EllipsisVertical />
              </AntBtn>
            </Dropdown>
            {motionStore.currentMotion &&
              !motionStore.isInMotionReportingEnabled &&
              // Validates that the Motion is of type `Motion` and has been persisted to the backend
              (motionStore.currentMotion as Motion).playbookId !== undefined &&
              motionStore.currentMotion.currState === MotionStateEnum.Draft && (
                <div
                  className='motion__schedule-execution-container'
                  data-testid='motion-schedule-execution'
                  onClick={handleMotionScheduleClick}>
                  <IconScheduleExecution />
                </div>
              )}
            {motionStore.isInMotionReportingEnabled ? (
              <>
                <Button
                  text='View Reporting'
                  testId='view-reporting'
                  type='secondary'
                  onClickHandler={handleViewReportingClick}
                  style={{ marginRight: '12px' }}
                />
                <Button
                  text={'Save Motion'}
                  testId='save-motion'
                  onClickHandler={() => {
                    handleOnSave({ updateMotionUrl: true, confirmCloseModal: false })
                  }}
                  disabled={isSaveDisabled}
                />
              </>
            ) : (
              <Button
                text={'Save Motion'}
                testId='save-motion'
                onClickHandler={() => {
                  handleOnSave({ updateMotionUrl: true, confirmCloseModal: false })
                }}
                disabled={isSaveDisabled}
              />
            )}

            <Link
              className='c-close m-l-16'
              to={closeRedirectPath}
              onClick={() => motionStore.setIsInMotionReportingEnabled(false)}
              data-testid='close-motion'>
              <IconClose />
            </Link>
          </Row>
        </Col>
      </Row>

      <Modal
        title='Delete Motion?'
        centered
        open={showDeletConfirmationModal}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button
            key='cancel'
            text='Cancel'
            type='secondary'
            testId='cancel-btn'
            onClickHandler={handleDeleteModalCancel}
            disabled={isDeleteMotionLoading}
          />,
          <Button
            key='confirm'
            text='Confirm'
            type='danger'
            testId='confirm-btn'
            onClickHandler={handleDeleteMotionConfirm}
            disabled={isDeleteMotionLoading}
            loading={isDeleteMotionLoading}
          />,
        ]}>
        <p>Are you sure you want to delete this Motion? This action can not be undone.</p>
      </Modal>
    </>
  )
}
MotionTopBar.displayName = 'MotionTopBar'

export default MotionTopBar
