import type { CarouselRef } from 'antd/lib/carousel'

import type { TenantInMotionReportingStatistics } from 'models/observability.model'

export const SEND_EMAIL_ALLOWED_EVENTS_ORDERED = [
  'magnify_send_email',
  'magnify_send_email_succeeded',
  'magnify_send_email_processed',
  'magnify_send_email_delivered',
  'magnify_send_email_open',
  'magnify_send_email_click',
  'magnify_send_email_bounce',
]

export const OPERATIONAL_STATISTICS_METRICS = {
  ACTIONS: 'actions',
  EXECUTION_ID: 'executionId',
  HUBSPOT_SEND_EMAIL: 'hubspot_send_transactional_email',
  INTERCOM_CREATE_UPDATE_COMPANY: 'intercom_create_update_company',
  INTERCOM_CREATE_TICKET: 'intercom_create_ticket',
  JOURNEY_ID: 'journeyId',
  JOURNEY: 'journey',
  MAGNIFY_SEND_EMAIL: 'magnify_send_email',
  MAGNIFY_SEND_EMAIL_SUCCEEDED: 'magnify_send_email_succeeded',
  MAGNIFY_SEND_EMAIL_PROCESSED: 'magnify_send_email_processed',
  MAGNIFY_SEND_EMAIL_DELIVERED: 'magnify_send_email_delivered',
  MAGNIFY_SEND_EMAIL_OPEN: 'magnify_send_email_open',
  MAGNIFY_SEND_EMAIL_CLICK: 'magnify_send_email_click',
  MAGNIFY_SEND_EMAIL_BOUNCE: 'magnify_send_email_bounce',
  MARKETO_SEND_EMAIL: 'marketo_send_email',
  MARKETO_OPEN_EMAIL: 'marketo_open_email',
  MARKETO_CLICKED_LINK_EMAIL: 'marketo_clicked_link_email',
  SALESFORCE_CREATE_OPPORTUNITY: 'salesforce_create_opportunity',
  SALESFORCE_UPDATE_OPPORTUNITY: 'salesforce_update_opportunity',
  SALESFORCE_UPDATE_ACCOUNT: 'salesforce_update_account',
  SALESFORCE_CREATE_CASE: 'salesforce_create_case',
  SALESFORCE_CREATE_TASK: 'salesforce_create_task',
  GAINSIGHT_CREATE_CTA: 'gainsight_create_cta',
  GAINSIGHT_CREATE_TIMELINE_EVENT: 'gainsight_create_timeline_event',
  GAINSIGHT_TRIGGER_EVENT: 'gainsight_trigger_event',
  SLACK_SEND_MESSAGE: 'slack_send_message',
  SLACK_POST_TO_CHANNEL: 'slack_post_to_channel',
  PENDO_SEND_MESSAGE: 'pendo_send_message',
  PENDO_LAUNCH_GUIDE: 'pendo_launch_guide',
  ZENDESK_CREATE_TICKET: 'zendesk_create_ticket',
}

export const getMaxSlide = (slider: React.MutableRefObject<CarouselRef | null>) => {
  // AntD provides an any type for innerSlider
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
  const numOfSlidesToShow = slider.current?.innerSlider.props.slidesToShow
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
  const numOfSlides = slider.current?.innerSlider.state.slideCount
  return numOfSlides - numOfSlidesToShow
}

export const handleSlideChange = (
  slidesToShow: number,
  slideCount: number,
  current: number,
  next: number,
  setShowArrow: React.Dispatch<
    React.SetStateAction<{
      left: boolean
      right: boolean
    }>
  >,
) => {
  const maxSlide = slideCount - slidesToShow

  if (next < 1) {
    setShowArrow({ left: false, right: true })
    return
  }
  if (current === next || next === maxSlide) {
    setShowArrow({ left: true, right: false })
    return
  }
  setShowArrow({ left: true, right: true })
}

export const sortStatisticCards = (statisticCards: React.JSX.Element[]) => {
  // First separate JOURNEY card from others
  const journeyCard = statisticCards.find((card) => card.key?.toLowerCase() === 'journey')
  const otherCards = statisticCards.filter((card) => card.key?.toLowerCase() !== 'journey')

  // Sort the other cards
  const sortedOtherCards = otherCards.sort((a, b) => {
    const aKey = String(a.key).toLowerCase()
    const bKey = String(b.key).toLowerCase()

    // Group by prefix (e.g., 'salesforce_', 'magnify_', etc.)
    const aPrefix = aKey.split('_')[0]
    const bPrefix = bKey.split('_')[0]

    if (aPrefix !== bPrefix) {
      return aPrefix.localeCompare(bPrefix)
    }

    // For magnify_send_email events, maintain specific ordering
    const isAMagnifyEmail = aKey.startsWith('magnify_send_email')
    const isBMagnifyEmail = bKey.startsWith('magnify_send_email')

    if (isAMagnifyEmail && isBMagnifyEmail) {
      const indexA = SEND_EMAIL_ALLOWED_EVENTS_ORDERED.indexOf(aKey)
      const indexB = SEND_EMAIL_ALLOWED_EVENTS_ORDERED.indexOf(bKey)
      return indexA - indexB
    }

    // For other groups, sort alphabetically within the group
    return aKey.localeCompare(bKey)
  })

  // Return array with JOURNEY first if it exists, followed by sorted other cards
  return journeyCard ? [journeyCard, ...sortedOtherCards] : sortedOtherCards
}

export const getOperationStatisticsCardDescription = (metric: string) => {
  let description = ''

  switch (metric) {
    case OPERATIONAL_STATISTICS_METRICS.JOURNEY:
      description = `Accounts | Users`
      break
    case OPERATIONAL_STATISTICS_METRICS.HUBSPOT_SEND_EMAIL:
      description = 'Hubspot emails sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.INTERCOM_CREATE_TICKET:
      description = 'Intercom tickets created'
      break
    case OPERATIONAL_STATISTICS_METRICS.INTERCOM_CREATE_UPDATE_COMPANY:
      description = 'Intercom companies updated'
      break
    // Note: this is specifically only for Sandbox, since we process them as fake actions
    case OPERATIONAL_STATISTICS_METRICS.MAGNIFY_SEND_EMAIL:
    case OPERATIONAL_STATISTICS_METRICS.MAGNIFY_SEND_EMAIL_SUCCEEDED:
      description = 'Magnify emails attempted'
      break
    case OPERATIONAL_STATISTICS_METRICS.MAGNIFY_SEND_EMAIL_PROCESSED:
      description = 'Magnify emails sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.MAGNIFY_SEND_EMAIL_DELIVERED:
      description = 'Magnify emails delivered'
      break
    case OPERATIONAL_STATISTICS_METRICS.MAGNIFY_SEND_EMAIL_OPEN:
      description = 'Magnify emails opened'
      break
    case OPERATIONAL_STATISTICS_METRICS.MAGNIFY_SEND_EMAIL_CLICK:
      description = 'Magnify email links clicked'
      break
    case OPERATIONAL_STATISTICS_METRICS.MAGNIFY_SEND_EMAIL_BOUNCE:
      description = 'Magnify emails bounced'
      break
    case OPERATIONAL_STATISTICS_METRICS.MARKETO_SEND_EMAIL:
      description = 'Marketo emails sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.MARKETO_OPEN_EMAIL:
      description = 'Marketo emails opened'
      break
    case OPERATIONAL_STATISTICS_METRICS.MARKETO_CLICKED_LINK_EMAIL:
      description = 'Marketo email links clicked'
      break
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_CREATE_OPPORTUNITY:
      description = 'Salesforce opportunities created'
      break
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_UPDATE_OPPORTUNITY:
      description = 'Salesforce opportunities updated'
      break
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_UPDATE_ACCOUNT:
      description = 'Salesforce accounts updated'
      break
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_CREATE_CASE:
      description = 'Salesforce cases created'
      break
    case OPERATIONAL_STATISTICS_METRICS.SALESFORCE_CREATE_TASK:
      description = 'Salesforce tasks created'
      break
    case OPERATIONAL_STATISTICS_METRICS.GAINSIGHT_CREATE_CTA:
      description = 'Gainsight CTAs created'
      break
    case OPERATIONAL_STATISTICS_METRICS.GAINSIGHT_CREATE_TIMELINE_EVENT:
      description = 'Gainsight timeline events created'
      break
    case OPERATIONAL_STATISTICS_METRICS.GAINSIGHT_TRIGGER_EVENT:
      description = 'Gainsight events triggered'
      break
    case OPERATIONAL_STATISTICS_METRICS.SLACK_SEND_MESSAGE:
      description = 'Slack messages sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.SLACK_POST_TO_CHANNEL:
      description = 'Slack channel messages sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.PENDO_SEND_MESSAGE:
      description = 'In-APP message sent'
      break
    case OPERATIONAL_STATISTICS_METRICS.PENDO_LAUNCH_GUIDE:
      description = 'Pendo guides launched'
      break
    case OPERATIONAL_STATISTICS_METRICS.ZENDESK_CREATE_TICKET:
      description = 'Zendesk tickets created'
      break
    default:
      description = 'Actions Performed'
  }

  return description
}

export const getAccountsAndUsersValue = (stats: TenantInMotionReportingStatistics) => {
  const accounts = numberFormatter(stats.accounts)
  const users = numberFormatter(stats.users)
  return `${accounts} | ${users}`
}

const numberFormatter = (num: number | undefined) => {
  if (num === undefined) return num

  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
  ]
  const removeTrailingZerosAfterDecimalPointRegex = /\.0+$|(\.\d*[1-9])0+$/
  const item = lookup
    .slice()
    .reverse()
    .find((item) => num >= item.value)

  return item
    ? (num / item.value).toFixed(1).replace(removeTrailingZerosAfterDecimalPointRegex, '$1') + item.symbol
    : '0'
}
