import type { CoreAPIErrorResponse } from 'api/errors'

import type { AxiosError } from 'axios'

/** This is the actual response from Amazon WAF */
const AMAZON_WAF_ERROR_MESSAGE = `<html>
<head><title>403 Forbidden</title></head>
<body>
<center><h1>403 Forbidden</h1></center>
</body>
</html>
<!-- a padding to disable MSIE and Chrome friendly error page -->
<!-- a padding to disable MSIE and Chrome friendly error page -->
<!-- a padding to disable MSIE and Chrome friendly error page -->
<!-- a padding to disable MSIE and Chrome friendly error page -->
<!-- a padding to disable MSIE and Chrome friendly error page -->
<!-- a padding to disable MSIE and Chrome friendly error page -->`

/**
 * Generate a WAF error to pass into the CoreApiError constructor
 * @param {object} params The params object of instance and message
 * @returns {object} The WAF error message
 */
const generateWAFErrorMessage = ({ instance, message }: { instance?: string; message?: string } = {}) => {
  return {
    message: `${message ?? 'Error'}`,
    status: 403,
    detail:
      'One or more fields contains invalid HTML. Please check any rich text fields for potentially dangerous HTML.',
    instance: `${instance ?? ''}`,
    type: 'WAF_ERROR',
  }
}

/**
 * Check if the error is a WAF error
 * @param {AxiosError<CoreAPIErrorResponse, any>} error The error to check
 * @returns {boolean} Whether the error is a WAF error
 */
const isWAFError = (error: AxiosError<CoreAPIErrorResponse, any>) => {
  return (
    typeof error?.response?.data === 'string' &&
    (error?.response?.data as unknown as string).startsWith('<html') &&
    error?.response?.status === 403
  )
}

export { AMAZON_WAF_ERROR_MESSAGE, generateWAFErrorMessage, isWAFError }
