const demoHubspotEmailDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Welcome Onboarding_Unmanaged',
        validFor: null,
        value: 144323884413,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Welcome Onboarding_Managed',
        validFor: null,
        value: 144323884414,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Monthly Checkin',
        validFor: null,
        value: 144323884415,
      },
      {
        active: true,
        defaultValue: false,
        label: 'NPS Survey_Managed',
        validFor: null,
        value: 144323884416,
      },
      {
        active: true,
        defaultValue: false,
        label: 'NPS Survey_Unmanaged',
        validFor: null,
        value: 144323884416,
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Guide',
  },
}

const sandboxHubspotEmailDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Welcome Email',
        validFor: null,
        value: 144323884413,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Renewal Email',
        validFor: null,
        value: 144323884414,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Churn Prevention',
        validFor: null,
        value: 144323884415,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Increase licenses',
        validFor: null,
        value: 144323884416,
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Guide',
  },
}

const sandboxZendeskPriorityDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Urgent',
        validFor: null,
        value: 'urgent',
      },
      {
        active: true,
        defaultValue: false,
        label: 'High',
        validFor: null,
        value: 'high',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Normal',
        validFor: null,
        value: 'normal',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Low',
        validFor: null,
        value: 'low',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

// For values see status in this table: https://developer.zendesk.com/api-reference/ticketing/tickets/tickets/#json-format
const sandboxZendeskStatusDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'New',
        validFor: null,
        value: 'new',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Open',
        validFor: null,
        value: 'open',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Pending',
        validFor: null,
        value: 'pending',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Hold',
        validFor: null,
        value: 'hold',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Solved',
        validFor: null,
        value: 'solved',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Closed',
        validFor: null,
        value: 'closed',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

const demoMarketoEmailCampaignDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Welcome Email',
        validFor: null,
        value: 'Welcome Email',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Renewal Reminder',
        validFor: null,
        value: 'Renewal Reminder',
      },
      {
        active: true,
        defaultValue: false,
        label: 'NPS Survey',
        validFor: null,
        value: 'NPS Survey',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

const demoSalesforceForecastCategoryDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Pipeline',
        validFor: null,
        value: 'Pipeline',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Best Case',
        validFor: null,
        value: 'Best Case',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Commit',
        validFor: null,
        value: 'Commit',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Closed',
        validFor: null,
        value: 'Closed',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

const demoSalesforceStageDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Assessment Needed-25',
        validFor: null,
        value: 'Assessment Needed-25',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Develop Proposal-50',
        validFor: null,
        value: 'Develop Proposal-50',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Client Presentation-75',
        validFor: null,
        value: 'Client Presentation-75',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Initiate Transfer-90',
        validFor: null,
        value: 'Initiate Transfer-90',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

const demoSalesforceWhiteGloveDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Yes',
        validFor: null,
        value: 'Yes',
      },
      {
        active: true,
        defaultValue: false,
        label: 'No',
        validFor: null,
        value: 'No',
      },
    ],
    type: 'picklist',
    required: true,
    updateable: true,
    createable: true,
    displayName: 'Priority',
  },
}

const demoSalesforceStatusDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: true,
        label: 'Not Started',
        validFor: null,
        value: 'Not Started',
      },
      {
        active: true,
        defaultValue: false,
        label: 'In Progress',
        validFor: null,
        value: 'In Progress',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Completed',
        validFor: null,
        value: 'Completed',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Waiting on someone else',
        validFor: null,
        value: 'Waiting on someone else',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Deferred',
        validFor: null,
        value: 'Deferred',
      },
    ],
    type: 'picklist',
    required: false,
    displayName: 'Status',
    updateable: true,
    createable: true,
  },
}

const demoSalesforcePriorityDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'High',
        validFor: null,
        value: 'High',
      },
      {
        active: true,
        defaultValue: true,
        label: 'Normal',
        validFor: null,
        value: 'Normal',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Low',
        validFor: null,
        value: 'Low',
      },
    ],
    type: 'picklist',
    required: false,
    displayName: 'Priority',
    updateable: true,
    createable: true,
  },
}

const demoSalesforceActiveDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Yes',
        validFor: null,
        value: 'Yes',
      },
      {
        active: true,
        defaultValue: true,
        label: 'No',
        validFor: null,
        value: 'No',
      },
    ],
    type: 'picklist',
    required: false,
    displayName: 'Active',
    updateable: true,
    createable: true,
  },
}

const demoGainsightCompanyDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Foster Inc',
        validFor: null,
        value: 'Foster Inc',
      },
      {
        active: true,
        defaultValue: true,
        label: 'Turner Group',
        validFor: null,
        value: 'Turner Group',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Oneill, King and Fleming',
        validFor: null,
        value: 'Oneill, King and Fleming',
      },
    ],
    type: 'picklist',
    required: false,
    displayName: 'Company',
    updateable: true,
    createable: true,
  },
}

const demoGainsightEntityTypeDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Account',
        validFor: null,
        value: 'Account',
      },
      {
        active: true,
        defaultValue: true,
        label: 'User',
        validFor: null,
        value: 'User',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Relationship',
        validFor: null,
        value: 'Relationship',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Person',
        validFor: null,
        value: 'Person',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Company',
        validFor: null,
        value: 'Company',
      },
    ],
    type: 'picklist',
    required: false,
    displayName: 'Entity Type',
    updateable: true,
    createable: true,
  },
}

const demoGainsightPriorityDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'High',
        validFor: null,
        value: 'High',
      },
      {
        active: true,
        defaultValue: true,
        label: 'Normal',
        validFor: null,
        value: 'Normal',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Low',
        validFor: null,
        value: 'Low',
      },
    ],
    type: 'picklist',
    required: false,
    displayName: 'Priority',
    updateable: true,
    createable: true,
  },
}

const demoGainsightStatusDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: true,
        label: 'Not Started',
        validFor: null,
        value: 'Not Started',
      },
      {
        active: true,
        defaultValue: false,
        label: 'In Progress',
        validFor: null,
        value: 'In Progress',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Completed',
        validFor: null,
        value: 'Completed',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Waiting on someone else',
        validFor: null,
        value: 'Waiting on someone else',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Deferred',
        validFor: null,
        value: 'Deferred',
      },
    ],
    type: 'picklist',
    required: false,
    displayName: 'Status',
    updateable: true,
    createable: true,
  },
}

const demoGainsightOwnerDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Josh Crossman',
        validFor: null,
        value: '1P01547XY4BJOWGJ5EGIMJSYS33K5NE0SNHJ',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Carolyn Vasilevski',
        validFor: null,
        value: '1P01547XY4BJOWGJ5ELPID1WCEOB5C143G5F',
      },
      {
        active: true,
        defaultValue: false,
        label: 'Survey Site Guest User',
        validFor: null,
        value: '1P01547XY4BJOWGJ5ESBJMERSJNR7SB2O5OB',
      },
    ],
    type: 'picklist',
    displayName: 'Owner',
  },
}

const demoGainsightTypeDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Objective',
        validFor: null,
        value: '1I00650WACZ7F0X9HR24QPN3LJJ9735J1XWV',
        displayOrder: 4,
        group: 'GLOBAL',
        groupId: null,
        color: '#DDF0FF',
        controllerValues: null,
        description: null,
        systemName: null,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Lifecycle',
        validFor: null,
        value: '1I00650WACZ7F0X9HR7BD364LH86OG45OJOO',
        displayOrder: 3,
        group: 'GLOBAL',
        groupId: null,
        color: '#DDFF95',
        controllerValues: null,
        description: null,
        systemName: null,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Risk',
        validFor: null,
        value: '1I00650WACZ7F0X9HRGYB8YE7VH82PUT1W06',
        displayOrder: 1,
        group: 'GLOBAL',
        groupId: null,
        color: '#FFDBDB',
        controllerValues: null,
        description: null,
        systemName: null,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Activity',
        validFor: null,
        value: '1I00650WACZ7F0X9HRWB9Y58K1EV0X0CEEAT',
        displayOrder: 5,
        group: 'GLOBAL',
        groupId: null,
        color: '#E3CFF4',
        controllerValues: null,
        description: null,
        systemName: null,
      },
      {
        active: true,
        defaultValue: false,
        label: 'Expansion',
        validFor: null,
        value: '1I00650WACZ7F0X9HRY83MKSTZ9P2SM9W9FN',
        displayOrder: 2,
        group: 'GLOBAL',
        groupId: null,
        color: '#C2FFE6',
        controllerValues: null,
        description: null,
        systemName: null,
      },
    ],
    type: 'lookup',
    required: true,
    displayName: 'Type',
    updateable: false,
    createable: false,
  },
}

const demoSlackFromDropdown = {
  data: {
    dropdownValues: [
      {
        active: true,
        defaultValue: false,
        label: 'Josh Crossman',
        validFor: null,
        value: 'U06CFT8PK1R',
      },
    ],
  },
}

export {
  demoHubspotEmailDropdown,
  sandboxHubspotEmailDropdown,
  sandboxZendeskPriorityDropdown,
  sandboxZendeskStatusDropdown,
  demoMarketoEmailCampaignDropdown,
  demoSalesforceForecastCategoryDropdown,
  demoSalesforceStageDropdown,
  demoSalesforceWhiteGloveDropdown,
  demoSalesforceStatusDropdown,
  demoSalesforcePriorityDropdown,
  demoSalesforceActiveDropdown,
  demoGainsightCompanyDropdown,
  demoGainsightEntityTypeDropdown,
  demoGainsightPriorityDropdown,
  demoGainsightStatusDropdown,
  demoGainsightOwnerDropdown,
  demoGainsightTypeDropdown,
  demoSlackFromDropdown,
}
