import classNames from 'classnames'
import { observer } from 'mobx-react-lite'

import { IconCaretRight, IconCheckMark, IconGripper } from 'components/common/Icons/Icons'
import { criteriaItemKey } from 'components/MotionBuilder/SegmentBuilder/SegmentCriteria/CriteriaInput/FieldInput/SingleSelect/utils'
import SearchItem from 'components/MotionBuilder/SegmentBuilder/SegmentSidebar/DataSource/SearchListView'
import {
  filterByType,
  filterByTypeOnSearch,
} from 'components/MotionBuilder/SegmentBuilder/SegmentSidebar/DataSource/SegmentSidebarUtils'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import useStore from 'store/useStore'

import type { Dispatch, SetStateAction, DragEvent } from 'react'

import type { MetadataDescription } from 'models/metadata.model'
import type { BreadcrumbInfo, Item, SourceData } from 'models/motion/motionBuilder.model'

interface SidebarListViewProps {
  breadCrumbItems: BreadcrumbInfo[]
  displayHeader?: boolean
  handleSelectOption: (item: MetadataDescription) => void
  handleDragStart?: (e: DragEvent<HTMLDivElement>, item: SourceData, breadCrumbItem: any[], position: any) => void
  handleDragEnd?: (e: DragEvent<HTMLDivElement>, item?: SourceData, position?: any) => void
  setSearchInput: Dispatch<SetStateAction<string>>
  handleSelectField?: (item: MetadataDescription) => void
}

const SidebarListView = observer(
  ({
    breadCrumbItems,
    displayHeader = true,
    handleSelectOption,
    handleDragStart,
    handleDragEnd,
    setSearchInput,
    handleSelectField,
  }: SidebarListViewProps) => {
    const { metadataStore, motionGoalsStore, motionStore } = useStore()
    const selectTypes: (string | undefined)[] = ['picklist', 'collection']
    const isSelectable = motionGoalsStore.motionGoalsModal.isOpen
    const isEmptyViewList =
      !metadataStore.displaySearchResult &&
      !filterByType(metadataStore.filteredMetadataList, motionGoalsStore.filterByType).length
    const isEmptySearchList =
      metadataStore.displaySearchResult &&
      !filterByTypeOnSearch(metadataStore.searchOutput, motionGoalsStore.filterByType).length
    const isEmptyCriteriaLocatorList =
      metadataStore.criteriaLocatorMetadata.isOpen && metadataStore.criteriaLocatorMetadata.hasError
    const displayEmptyListMessage = isEmptyViewList || isEmptySearchList || isEmptyCriteriaLocatorList

    useMetadataDisplayErrorNotification(metadataStore)

    const displayCaret = (item: MetadataDescription): boolean => {
      return item.type === undefined
    }

    const isDraggable = (item: MetadataDescription): boolean => {
      return item.type !== undefined
    }

    const onDragStart = async (e: DragEvent<HTMLDivElement>, item: MetadataDescription) => {
      const { key, type } = item
      const { platform, object, solutionInstanceId, magnifyDisplayName } = metadataStore.currentItem

      const options = {
        ...((platform ?? item.platform) && { platform: (platform ?? item.platform) as string }),
        ...((object ?? item.object) && { object: (object ?? item.object) as string }),
        field: key,
        solutionInstanceId: item.solutionInstanceId ?? solutionInstanceId,
        ...(magnifyDisplayName && { magnifyDisplayName }),
      }

      const itemKey = criteriaItemKey({ item: options as Item })

      const isSelect = selectTypes.includes(type)
      if (isSelect && !metadataStore.criteriaSelect.options.get(itemKey)) {
        // set null an get new metadata only when the story is empty
        metadataStore.setCriteriaSelectOptions(itemKey, [])
        await metadataStore.loadCriteriaInputOptions(options as Item, itemKey)
      }

      const dataSource = {
        ...options,
        ...item,
      }

      handleDragStart?.(e, dataSource, breadCrumbItems, key)
    }

    return (
      <div className='menu' data-testid='menu'>
        {!metadataStore.displaySearchResult &&
          !displayEmptyListMessage &&
          filterByType(metadataStore.filteredMetadataList, motionGoalsStore.filterByType)
            .slice()
            .sort((a, b) => {
              // sort magnify data source to the top
              if (a.name === 'magnify-insights') return -1
              if (b.name === 'magnify-insights') return 1
              return 0
            })
            .map((item: MetadataDescription, index: number) => (
              <div
                key={`${item.name}-${index}`}
                id={`${item.name}-${index}`}
                {...(!isSelectable && {
                  onDragStart: async (e: DragEvent<HTMLDivElement>) => {
                    await onDragStart(e, item)
                  },
                  onDragEnd: (e: DragEvent<HTMLDivElement>) => {
                    handleDragEnd?.(e)
                  },
                })}
                draggable={isDraggable(item)}
                onClick={() => {
                  if (!isDraggable(item)) {
                    handleSelectOption({
                      ...item,
                      solutionInstanceId:
                        (item.connections?.length && item.connections?.[0].solutionInstanceId) ||
                        item.solutionInstanceId,
                      ...(breadCrumbItems[1]?.magnifyDisplayName && {
                        magnifyDisplayName: breadCrumbItems[1].magnifyDisplayName,
                      }),
                    })
                  } else if (isDraggable(item) && isSelectable) {
                    handleSelectField?.({
                      ...item,
                      platform: breadCrumbItems[0].name,
                      object: breadCrumbItems[1].name,
                      ...(breadCrumbItems[1]?.magnifyDisplayName && {
                        magnifyDisplayName: breadCrumbItems[1].magnifyDisplayName,
                      }),
                    })
                  }
                }}
                className={classNames('menu__item', {
                  'menu__item--disabled': motionStore.isSegmentBuilderEditDisabled,
                })}
                data-testid='menu__item'>
                {getMenuItemIcon(item)}
                <span className='menu__name'>
                  {/* TODO: Remove the replace of snowflakedb once isn't needed anymore (MAGPROD-3416) - or we entirely migrate to Airbyte */}
                  {item.magnifyDisplayName ??
                    item.name.replace('snowflakedb', 'snowflake').replace('chorusai', 'Chorus AI')}
                </span>
                {displayCaret(item) && <IconCaretRight />}
                {isDraggable(item) && !isSelectable && <IconGripper />}
                {isDraggable(item) &&
                  isSelectable &&
                  motionGoalsStore.motionMetrics[motionGoalsStore.dataFieldIndex]?.field === item.key && (
                    <IconCheckMark className='selected-data-field' data-testid='selected-data-field' />
                  )}
              </div>
            ))}

        {displayEmptyListMessage && (
          <div className='menu__empty' data-testid='menu__empty'>
            <span>No data fields available</span>
          </div>
        )}

        {/* search output */}
        {metadataStore.displaySearchResult &&
          !displayEmptyListMessage &&
          filterByTypeOnSearch(metadataStore.searchOutput, motionGoalsStore.filterByType).map((item) => {
            return (
              <SearchItem
                item={item}
                {...(!isSelectable && {
                  onDragStart,
                  handleDragEnd,
                })}
                breadCrumbItems={breadCrumbItems}
                key={`${item.order[item.order.length - 1].key}-${item.order[0].name}-${item.order
                  .map((orderItem) => orderItem.name)
                  .splice(0, item.order.length - 1)
                  .join('/')}`}
                setSearchInput={setSearchInput}
                {...(isSelectable && {
                  handleSelectField,
                })}
              />
            )
          })}
      </div>
    )
  },
)
SidebarListView.displayName = 'SidebarListView'

export default SidebarListView
