import {
  defaultFieldsList,
  generateTriggerEventPayload,
  matchKeyValueFields,
} from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/SpecialActions/TriggerEvent/utils'
import { clone } from 'services/Utils/misc'

import { ActionTypeEnum, type CreateActionFields, type SegmentBuilderData } from 'models/motion/motionBuilder.model'

/**
 * Extract the value and index of a field from a list of Action.CreateActionsPayload.CreateActionFields.
 * @param {CreateActionFields[]} fields The list of fields to extract from.
 * @param fieldKey The key of the field to extract.
 * @returns {[string | boolean, number]} The value and index of the field.
 */
export const getValueAndIndex = (fields: CreateActionFields[], fieldKey: string) => {
  if (!fields || fields.length === 0 || !fieldKey) {
    return [undefined, undefined]
  }
  const index = fields.findIndex(({ key = '' }) => key === fieldKey)
  const value = fields[index]
  return [value, index]
}

/**
 * Process an Action node before rendering it.
 * @param {SegmentBuilderData} data The node to process.
 * @returns {SegmentBuilderData} The processed node.
 */
// eslint-disable-next-line @typescript-eslint/require-await
export const preProcessNode = async (data: SegmentBuilderData): Promise<SegmentBuilderData> => {
  if (data.payload && data.actionType === ActionTypeEnum.TriggerEvent && data.platform === 'gainsight') {
    // Ensure we still have our default fields and the eventPayload field.
    const fields = matchKeyValueFields(data.payload.fields || clone(defaultFieldsList))
    const defaultValueFields = data.payload?.fields?.filter((field) => field.defaultValue)
    data.payload.fields = generateTriggerEventPayload(fields, defaultValueFields)
  }

  return data
}

/**
 * Process an Action node before saving it.
 * @param {SegmentBuilderData} data The node to process.
 * @returns {SegmentBuilderData} The processed node.
 */
// eslint-disable-next-line @typescript-eslint/require-await
export const postProcessNode = async (data: SegmentBuilderData): Promise<SegmentBuilderData> => {
  if (data.payload && data.actionType === ActionTypeEnum.TriggerEvent && data.platform === 'gainsight') {
    // Ensure we still have the eventPayload field.
    const fields = matchKeyValueFields(data.payload.fields || clone(defaultFieldsList))
    const defaultValueFields = data.payload.fields.filter((field) => field.defaultValue)
    data.payload.fields = generateTriggerEventPayload(fields, defaultValueFields)
  }

  return data
}
