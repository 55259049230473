import { useLDClient } from 'launchdarkly-react-client-sdk'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'

import type { LDEvaluationDetail } from 'launchdarkly-react-client-sdk'

import type { APIOptions } from 'models/admin'

/**
 * Set the API override to the selected value.
 * @param {APIOptions} apiOptions The API options to set the override for
 * @param {string} value The value to set the override to
 */
export function updateApiOverride(apiOptions: APIOptions, value: string) {
  Object.entries(apiOptions[value] ?? {}).forEach(([key, value]: [string, string]) => {
    if (key && value) {
      localStorage.setItem(`API_URL_${key}`, value)
    }
  })
}

const AdminBar: React.FunctionComponent = observer(() => {
  const apiOptions = JSON.parse(localStorage.getItem('API_OPTIONS') || '{}') as APIOptions
  const [flags, setFlags] = useState<Record<string, LDEvaluationDetail>>({})
  const [showPopover, setShowPopover] = useState(false)
  const ldClient = useLDClient()

  useEffect(() => {
    updateApiOverride(apiOptions, Object.keys(apiOptions)[0])
  }, [])

  useEffect(() => {
    if (ldClient) {
      // Get all feature flag keys
      const allFlags = ldClient.allFlags()
      // Get the details for each flag
      for (const key of Object.keys(allFlags)) {
        const details = ldClient.variationDetail(key, false)
        allFlags[key] = {
          ...details,
        }
      }
      setFlags(allFlags)
    }
  }, [ldClient])

  return (
    <div className='admin-bar' data-testid='admin-bar'>
      <div className='title'>
        <span className='brand'>Magnify</span>
        <span className='subtitle'>Admin</span>
      </div>
      <div className='controls'>
        <div className='feature-flags'>
          <span onClick={() => setShowPopover((show) => !show)}>Show Feature Flags</span>
          {showPopover && (
            <div className='feature-flags-popover'>
              {Object.keys(flags).map((key) => (
                <div key={key}>
                  <code>{key}</code>:{' '}
                  <span className={`flag-value-${flags[key].value ? 'true' : 'false'}`}>{`${flags[key].value}`}</span>
                  <p>
                    <em>Reason:</em> {flags[key].reason?.errorKind || flags[key].reason?.kind}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
        <label htmlFor='api-options'>
          Mock API:
          <select
            id='api-options'
            name='api-options'
            data-testid='api-options'
            onChange={(e) => {
              updateApiOverride(apiOptions, e.target.value)
            }}>
            {Object.keys(apiOptions).map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </label>
      </div>
    </div>
  )
})
AdminBar.displayName = 'AdminBar'
export default AdminBar
