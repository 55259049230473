import { Skeleton } from 'antd'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useContext, useEffect, useState } from 'react'

import { Button, DYNAMIC_INPUT } from 'components/common'
import { checkTypeEquality } from 'components/common/DataSourceContainer/index.utils'
import { IconArrowTop } from 'components/common/Icons/Icons'
import {
  handleSpecialCases,
  hasGainsightTypeFieldValue,
  isGainsightCtaSpecialProp,
  populateFields,
  provisoryObjectName,
} from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/common/InputFields/utils'
import ProvisoryMock from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/common/ProvisoryMock'
import SingleInputField from 'components/MotionBuilder/SegmentBuilder/ConfigPanelTypes/Actions/common/SingleInputField'
import { getMenuItemIcon } from 'components/MotionBuilder/Utils/serviceUtils'
import useDemoFeature from 'hooks/useDemoFeature'
import { useMetadataDisplayErrorNotification } from 'hooks/useDisplayErrorNotification'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { Dayjs } from 'dayjs'
import type { Dispatch, SetStateAction } from 'react'

import type { CreateActionFields, ConfigPanelPayload, ValueType, Item } from 'models/motion/motionBuilder.model'
import { ActionEnum, ActionTypeEnum } from 'models/motion/motionBuilder.model'

interface InputFieldsProps {
  actionType: ActionTypeEnum
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
}

const InputFields = observer(({ actionType, payload, setPayload }: InputFieldsProps) => {
  const { segmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)

  const { metadataStore, dynamicInputStore, motionStore } = useStore()
  const { dynamicInputPosition } = dynamicInputStore
  const [showOptionalFields, setShowOptionalFields] = useState(false)

  const { isMockApiEnabled } = useDemoFeature()

  const payloadHasFields = !metadataStore.metadataActionFields.isLoading && !!payload?.fields?.length
  const payloadHasOptionalFields = payloadHasFields && payload.fields.some((field) => !field.required)
  const isEmptyPayload = !metadataStore.metadataActionFields.isLoading && !!!payload?.fields?.length
  const isFulfilledPayload = payloadHasOptionalFields && actionType !== ActionTypeEnum.Update
  const isKeyValueField =
    !!segmentBuilderData.payload.fields?.length && segmentBuilderData.payload.fields[0].hasOwnProperty('key')
  const isGainsightCTAAction =
    segmentBuilderData.platform?.toLowerCase() === 'gainsight' &&
    segmentBuilderData.object?.toLowerCase() === 'call_to_action' &&
    segmentBuilderData.action === ActionEnum.Create

  useEffect(() => {
    metadataStore
      .getMetadataActionFields({
        platform: segmentBuilderData.platform,
        object: segmentBuilderData.object,
        action: segmentBuilderData.action,
        solutionInstanceId: segmentBuilderData.solutionInstanceId,
      })
      .catch(console.error)
  }, [])

  useMetadataDisplayErrorNotification(metadataStore)

  useEffect(() => {
    const data = returnMetadataFields()
    if (data?.length) {
      setConfigPanelPayload(data)
    } else {
      /* IF THE REQUEST IS FAILING, SET THE PAYLOAD TO BE UNDEFINED(DATA) */
      setPayload({ ...segmentBuilderData.payload })
    }
  }, [metadataStore.metadataActionFields.data])

  const setConfigPanelPayload = (data: CreateActionFields[]) => {
    if (segmentBuilderData.payload.fields?.length) {
      if (isKeyValueField) {
        setPayload((prev) => ({
          ...prev,
          fields: populateFields(prev.fields, data),
        }))
      } else {
        /* IF THE CREATE ACTION IS ALREADY SAVED ON THE NODE */
        setPayload(segmentBuilderData.payload)
      }
    } else {
      /* IF THE CREATE ACTION IS DROPED, ADD FIELDS FROM METADATA STORE */
      setPayload({
        ...segmentBuilderData.payload,
        fields: data,
      })
    }
  }

  const isRequiredField = (metadata: CreateActionFields, required: boolean) => {
    if (actionType === ActionTypeEnum.Create) {
      return metadata.required === required
    } else if (actionType === ActionTypeEnum.Update) {
      return true
    }
  }

  const returnMetadataFields = () => {
    const object = metadataStore.metadataActionFields.data?.[segmentBuilderData.platform]
    const action = object?.[segmentBuilderData.object]?.[actionType]

    return toJS(action)
  }

  const changeFieldValue = (
    value: boolean | number | number[] | string | string[] | Dayjs | Dayjs[] | CreateActionFields | null,
    fieldKey: string | undefined,
    index?: number,
  ) => {
    const fieldIndex = payload.fields.findIndex((field) => field.key === fieldKey)

    setPayload((prevPayload) => {
      const updatedPayload = { ...prevPayload }
      // Fallback to payload field if the field is not found in updatedPayload
      const field = {
        ...(updatedPayload?.fields?.[fieldIndex] || payload.fields[fieldIndex]),
      }

      // Update field value
      if (typeof index === 'number') {
        field.value =
          index === 0
            ? [value as number, (field.value as number[])?.[1]]
            : [(field.value as number[])?.[0], value as number]
      } else {
        field.value = value
      }

      // Update dynamic input status
      field.isDynamicInput =
        !!(field.value as CreateActionFields)?.platform || !!(value as CreateActionFields)?.platform

      // Update display value if needed
      if (metadataStore.criteriaSelect.options.size) {
        for (const options of metadataStore.criteriaSelect.options.values()) {
          for (const option of Object.values(options)) {
            if (option.value === value) {
              field.displayValue = option.label
              break
            }
          }
        }
      }

      // Special Cases
      handleSpecialCases(segmentBuilderData, metadataStore, field, fieldKey, value, updatedPayload)

      updatedPayload.fields[fieldIndex] = field
      return updatedPayload
    })
  }

  const addDynamicValue = (value: ValueType, fieldKey: string | undefined) => {
    const fieldIndex = payload.fields.findIndex((field) => field.key === fieldKey)

    if (fieldIndex === -1) {
      return
    }

    setPayload((prevPayload) => {
      const updatedPayload = { ...prevPayload }
      const field = { ...updatedPayload.fields[fieldIndex] }
      const currentValue = (field.value as CreateActionFields)?.value
      const currentPlatform = (field.value as CreateActionFields)?.platform
      const fieldType = field.type

      const newValue = {
        ...(value as object),
        ...(currentValue && { value: currentValue }),
        ...(currentPlatform &&
          currentValue &&
          !(currentValue as string).includes(DYNAMIC_INPUT) &&
          checkTypeEquality(fieldType, 'string') && {
            value: (currentValue as string) + DYNAMIC_INPUT,
          }),
        ...(!currentPlatform &&
          checkTypeEquality(fieldType, 'string') &&
          (value as CreateActionFields).platform && {
            value: addDynamicInputOnCaretPosition(field),
          }),
        ...(!checkTypeEquality(fieldType, 'string') &&
          (value as CreateActionFields).platform && {
            value: DYNAMIC_INPUT,
          }),
      }

      field.value = newValue as CreateActionFields
      field.isDynamicInput = !!currentPlatform || !!(value as CreateActionFields).platform
      updatedPayload.fields[fieldIndex] = field

      return updatedPayload
    })
  }

  const addDynamicInputOnCaretPosition = (item: Item | CreateActionFields): string => {
    if (
      item.value &&
      typeof item.value === 'string' &&
      item.key === dynamicInputPosition?.key &&
      (dynamicInputPosition?.position || dynamicInputPosition?.position === 0)
    ) {
      return (
        item.value.slice(0, dynamicInputPosition?.position) +
        DYNAMIC_INPUT +
        item.value.slice(dynamicInputPosition?.position)
      )
    }
    return (item.value as string) || '' + DYNAMIC_INPUT
  }

  const removeTag = (fieldKey: string | undefined) => {
    const fieldIndex = payload.fields.findIndex((field) => field.key === fieldKey)

    if (fieldIndex === -1) {
      console.error('Cannot find fieldIndex for fieldKey:', fieldKey)
      return
    }

    setPayload((prevPayload) => {
      const updatedPayload = { ...prevPayload }
      const field = { ...updatedPayload.fields[fieldIndex] }

      if ((field.value as CreateActionFields)?.value) {
        field.value = ((field.value as CreateActionFields).value as string).replace(DYNAMIC_INPUT, '')
        field.isDynamicInput = false
        updatedPayload.fields[fieldIndex] = field
      }

      return updatedPayload
    })
  }

  const displayMocks = () => segmentBuilderData.platform === 'pendo'

  const displayDefaultView = (!displayMocks() && isMockApiEnabled) || !isMockApiEnabled

  // Get the count of unpopulated optional fields that are not hidden
  const unpopulatedOptionalFieldCount =
    payload?.fields?.filter(
      (metadata: CreateActionFields) =>
        isRequiredField(metadata, false) && metadata.type !== 'hidden' && metadata.value === undefined,
    ).length ?? 0

  return (
    <div className='action-input-fields' data-testid='action-input-fields'>
      <div className='subtitle' data-testid='subtitle'>
        {getMenuItemIcon({ entityType: 'platform', name: segmentBuilderData.platform }, true)}
        <span>
          {segmentBuilderData.platform} {provisoryObjectName(segmentBuilderData)} Inputs
        </span>
      </div>

      {/* TODO:Rever these provisory changes */}
      {/* MAGPROD-951 & MAGPROD-2001 */}
      {isMockApiEnabled && displayMocks() && <ProvisoryMock />}

      {displayDefaultView && (
        <div className='action-inputs' data-testid='action-inputs'>
          {metadataStore.metadataActionFields.isLoading &&
            [...(Array(5) as undefined[])].map((_, index) => (
              <div key={index} className='skeleton-container' data-testid='skeleton-container'>
                <Skeleton.Input className='title' active size='small' />
                <Skeleton.Input active block />
              </div>
            ))}

          {/* DISPLAY REQUIRED FIELDS FIRST */}
          {payloadHasFields &&
            payload?.fields?.map((metadata: CreateActionFields, index: number) => {
              metadata.platform = segmentBuilderData.platform
              metadata.object = segmentBuilderData.object
              metadata.field = metadata.key
              metadata.solutionInstanceId = segmentBuilderData.solutionInstanceId
              if (segmentBuilderData.magnifyDisplayName) {
                metadata.magnifyDisplayName = segmentBuilderData.magnifyDisplayName
              }

              // MAGPROD-969
              if (isGainsightCTAAction && isGainsightCtaSpecialProp(metadata.key)) {
                const ctaTypeValue = payload.fields.find((field) => field.key?.toLowerCase() === 'typeid')?.value
                if (ctaTypeValue) {
                  metadata.ctaTypeValue = ctaTypeValue as string
                }
              }

              if (['picklist', 'lookup', 'combobox'].includes(metadata.type)) {
                metadata.type = 'select'
              }

              return (
                isRequiredField(metadata, true) && (
                  <SingleInputField
                    key={`${metadata.key}-${index}`}
                    index={index}
                    item={metadata}
                    actionType={actionType}
                    removeTag={removeTag}
                    addDynamicValue={addDynamicValue}
                    changeFieldValue={changeFieldValue}
                    disabled={
                      hasGainsightTypeFieldValue(isGainsightCTAAction, metadata.key, payload) ||
                      motionStore.isSegmentBuilderEditDisabled
                    }
                    payloadFields={segmentBuilderData.payload.fields}
                  />
                )
              )
            })}

          {isFulfilledPayload && unpopulatedOptionalFieldCount > 0 && (
            <Button
              text={`${showOptionalFields ? 'Hide' : 'Show'} unpopulated optional fields`}
              link
              size='L'
              className={`show-more-button ${showOptionalFields ? '--toggle-up' : '--toggle-down'}`}
              onClickHandler={() => setShowOptionalFields(!showOptionalFields)}
              icon={{ element: <IconArrowTop /> }}
              testId='toggle-optional-fields'
              disabled={motionStore.isSegmentBuilderEditDisabled}
            />
          )}

          {/* Show optional fields that have a value set, but hide them when we show all optional values so they are shown in order. */}
          {!showOptionalFields &&
            payload?.fields?.map((metadata: CreateActionFields, index: number) => {
              return (
                isRequiredField(metadata, false) &&
                metadata.value !== undefined && (
                  <SingleInputField
                    key={`${metadata.key}-${index}`}
                    index={index}
                    item={metadata}
                    actionType={actionType}
                    removeTag={removeTag}
                    addDynamicValue={addDynamicValue}
                    changeFieldValue={changeFieldValue}
                    payloadFields={segmentBuilderData.payload.fields}
                    disabled={motionStore.isSegmentBuilderEditDisabled}
                  />
                )
              )
            })}

          {/* Show all optional fields when showOptionalFields is set to true. */}
          {showOptionalFields &&
            payload?.fields?.map((metadata: CreateActionFields, index: number) => {
              return (
                isRequiredField(metadata, false) && (
                  <SingleInputField
                    key={`${metadata.key}-${index}`}
                    index={index}
                    item={metadata}
                    actionType={actionType}
                    removeTag={removeTag}
                    addDynamicValue={addDynamicValue}
                    changeFieldValue={changeFieldValue}
                    payloadFields={segmentBuilderData.payload.fields}
                    disabled={motionStore.isSegmentBuilderEditDisabled}
                  />
                )
              )
            })}

          {isEmptyPayload && <div>No data</div>}
        </div>
      )}
    </div>
  )
})
InputFields.displayName = 'InputFields'

export default InputFields
