import { createContext } from 'react'

import type { ReactNode } from 'react'

import type { SegmentBuilderData } from 'models/motion/motionBuilder.model'

import type { Elements } from 'react-flow-renderer/dist/types'

export interface SegmentBuilderStore {
  elements: Elements
  setElements: React.Dispatch<React.SetStateAction<Elements>>
  setElementsWithOptimizedPositions: (elements: Elements) => void
  segmentBuilderData: SegmentBuilderData
  setSegmentBuilderData: React.Dispatch<React.SetStateAction<SegmentBuilderData>>
  reset: boolean
  setReset: React.Dispatch<React.SetStateAction<boolean>>
}

export interface SegmentBuilderProviderProps {
  store: SegmentBuilderStore
  children: ReactNode
}

export const SegmentBuilderContext = createContext<SegmentBuilderStore>({} as SegmentBuilderStore)

export const SegmentBuilderProvider = ({ store, children }: SegmentBuilderProviderProps) => {
  return <SegmentBuilderContext.Provider value={store}>{children}</SegmentBuilderContext.Provider>
}
