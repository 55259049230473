import { isJsonString, isPlainObject } from 'utils/type-helpers'

import { PAYLOAD_STRUCTURE_KEY, DATETIME_TYPE } from 'components/common'

import type { CreateActionFields, KeyValueField } from 'models/motion/motionBuilder.model'

export const defaultFieldsList = [
  {
    key: 'topicName',
    name: 'Topic',
    required: true,
    type: 'string',
    value: '',
  },
  {
    key: 'eventName',
    name: 'Event',
    required: true,
    type: 'string',
    value: '',
  },
  {
    key: 'eventVersion',
    name: 'Version',
    required: true,
    type: 'string',
    value: '',
  },
  {
    key: 'Payload Structure',
    name: 'Payload Structure',
    required: true,
    type: 'payload',
    value: '',
  },
]

export const actionLabels = {
  edit: 'Edit payload structure',
  load: 'Load field structure',
  payloadErrorMessages: {
    empty: 'Payload Structure is required',
    invalid: 'Invalid Payload Structure format',
  },
}

/**
 * Parse a JSON payload into an array of CreateActionFields.
 * @param {object} payload
 * @returns {object[]} An array of CreateActionFields.
 */
export const parsePayloadFields = (payload: { [key: string]: string }) => {
  const fields: CreateActionFields[] = []

  for (const [key, type] of Object.entries(payload)) {
    fields.push({
      key,
      name: key,
      type,
      required: true,
      value: '',
    })
  }

  return fields
}

/**
 * Filter out the `eventPayload` field and optionally default fields from an array of CreateActionFields.
 * @param {CreateActionFields[]} fields The array of CreateActionFields to filter.
 * @param {boolean} matchDefaultPayload When true keep the default fields only, otherwise remove default fields.
 * @returns {CreateActionFields[]} The filtered array of CreateActionFields.
 */
export const filterDefaultFields = (fields: CreateActionFields[], matchDefaultPayload: boolean) => {
  const modifiedFields = fields && fields.filter((field) => field.key !== 'eventPayload')
  if (modifiedFields?.length) {
    if (matchDefaultPayload) {
      return modifiedFields.filter(
        (field) => field.key && defaultFieldsList.find((defaultField) => defaultField.key === field.key),
      )
    } else {
      return modifiedFields.filter(
        (field) => field.key && !defaultFieldsList.find((defaultField) => defaultField.key === field.key),
      )
    }
  }

  return []
}

/**
 * Set the value for the fields in fieldsList from the  matching key value that is found in the keyValueFields.
 * @param {CreateActionFields[]} fieldsList
 * @param {KeyValueField[]} keyValueFields
 * @returns {KeyValueField[]} The array of KeyValueField with the value set for the matching key.
 */
export const setValueForFoundFields = (fieldsList: CreateActionFields[], keyValueFields: KeyValueField[]) => {
  const list: CreateActionFields[] = []

  fieldsList.forEach((defaultField) => {
    const fieldIndex = keyValueFields.findIndex((field) => field.key === defaultField.key)

    if (fieldIndex > -1) {
      list.push({
        ...defaultField,
        value: keyValueFields[fieldIndex].value,
        isDynamicInput: keyValueFields[fieldIndex].isDynamicInput || false,
      })
    } else {
      list.push(defaultField)
    }
  })

  return list
}

/**
 * Ensure default fields are present in the payload with all their keys present and spread any eventPayload fields into the top level.
 * Has the unintentional side effect of removing the `eventPayload` field from the payload.
 * @param {KeyValueField[]} fields The array of KeyValueFields to check.
 * @returns {CreateActionFields[]} The array of CreateActionFields with the default fields added and payload fields spread into the top level.
 */
export const matchKeyValueFields = (fields: KeyValueField[]): CreateActionFields[] => {
  let keyValueFieldsList: CreateActionFields[] = setValueForFoundFields(defaultFieldsList, fields)

  // Add the eventPayload fields into the top level structure if they are present.
  const fieldStructure = fields.find((field) => field.key === PAYLOAD_STRUCTURE_KEY)
  if (fieldStructure?.value && isJsonString(fieldStructure?.value)) {
    const parsedPayload = parsePayloadFields(
      JSON.parse(fieldStructure.value) as {
        [key: string]: string
      },
    )
    keyValueFieldsList = keyValueFieldsList.concat(setValueForFoundFields(parsedPayload, fields))
  }

  return keyValueFieldsList
}

/**
 * Add the `eventPayload` field to an array of CreateActionFields.
 * @param {CreateActionFields[]} fields The array of CreateActionFields to add the `eventPayload` field to.
 * @returns {CreateActionFields[]} The array of CreateActionFields with the `eventPayload` field added.
 * @see {@link https://github.com/Encore-Post-Sales/action-service/blob/56e90ea90597c76ea01dc85c6319473a9a1d6d62/functions/actions/common/dynamicInputs.ts#L98 | resolveDynamicInputsForGainsightTriggerEvent}
 */
export const generateTriggerEventPayload = (
  fields: CreateActionFields[],
  defaultValueFields?: CreateActionFields[],
): CreateActionFields[] => {
  if (!fields || fields.length < 1) return []

  // Check if we have defaultValueFields, is so, loop through defaultValueFields and add the key "defaultValue" to the matching field in fields
  if (defaultValueFields && defaultValueFields.length > 0) {
    defaultValueFields.forEach((defaultValueField) => {
      const index = fields.findIndex((item) => item.key === defaultValueField.key)
      if (index > -1) {
        fields[index].defaultValue = defaultValueField.defaultValue
      }
    })
  }

  // Check if we already have an eventPayload field
  const found = fields.find((field) => field.key === 'eventPayload')
  if (found) {
    return fields
  }

  const eventPayload = {
    key: 'eventPayload',
    value: {} as Record<string, any>,
    isDynamicInput: false,
    type: 'eventPayload', // this type was added just to meet the data structure with other fields
  }

  if (eventPayload.value == null || eventPayload.value === undefined) {
    eventPayload.value ||= {}
  }

  fields.forEach((field: CreateActionFields) => {
    if (defaultFieldsList.find((item) => item.key === field.key) || field.key === undefined) return null

    if (field.value && isPlainObject(field.value) && 'value' in field.value) {
      const type = field.value.type === 'date' ? DATETIME_TYPE : field.value.type
      eventPayload.value[field.key] = {
        ...field.value,
        type,
        isDynamicInput: field.isDynamicInput,
      }
    } else {
      eventPayload.value[field.key] = { ...field }
    }
  })

  // Ensure we actually added the eventPayload field with values
  if (Object.keys(eventPayload.value || {}).length === 0) {
    return fields
  }

  return [...fields, eventPayload]
}
