import { Tooltip } from 'antd'
import { observer } from 'mobx-react-lite'
import { useContext, useState, type Dispatch, type SetStateAction } from 'react'

import { Button } from 'components/common'
import DynamicInputModal from 'components/common/DynamicInputModal'
import SendTestMessageModal from 'components/common/SendTestMessageModal'
import SendTestEmailModal from 'components/EmailTemplates/modals/SendTestEmailModal'
import { SegmentExportModal } from 'components/MotionBuilder/SegmentBuilder/SegmentExportModal/SegmentExportModal'
import { openNotificationPopup } from 'services/Utils/openNotificationPopup'
import type { SegmentBuilderStore } from 'store/SegmentBuilderContext'
import { SegmentBuilderContext } from 'store/SegmentBuilderContext'
import useStore from 'store/useStore'

import type { EmailTemplate } from 'models/email-templates.model'
import type { ConfigPanelPayload, CreateActionsPayload } from 'models/motion/motionBuilder.model'
import { ActionTypeEnum } from 'models/motion/motionBuilder.model'
import { NodeTypeEnum } from 'models/motion.model'

interface SegmentBottomProps {
  handleSegmentExport: () => void
  payload: ConfigPanelPayload
  setPayload: Dispatch<SetStateAction<ConfigPanelPayload>>
  isPayloadEmpty: boolean
  template: EmailTemplate
  isDynamicInputModalOpen: boolean
  setIsDynamicInputModalOpen: Dispatch<SetStateAction<boolean>>
  currentDynamicInputModalKey: string
  setCurrentDynamicInputModalKey: Dispatch<SetStateAction<string>>
  handleReset: () => void
  saveConfigData: () => void
}

export const SegmentBottom = observer(
  ({
    handleSegmentExport,
    payload,
    setPayload,
    isPayloadEmpty,
    template,
    isDynamicInputModalOpen,
    setIsDynamicInputModalOpen,
    currentDynamicInputModalKey,
    setCurrentDynamicInputModalKey,
    handleReset,
    saveConfigData,
  }: SegmentBottomProps) => {
    const { segmentBuilderData } = useContext<SegmentBuilderStore>(SegmentBuilderContext)

    const { motionStore } = useStore()
    const [isSendTestEmailModalOpen, setIsSendTestEmailModalOpen] = useState(false)
    const [isSentTestMesageModalOpen, setIsSentTestMesageModalOpen] = useState(false)

    const isSlackAction = segmentBuilderData.platform === 'slack'
    const isEmaiTemplate =
      segmentBuilderData.platform === 'magnify' && segmentBuilderData.actionType === ActionTypeEnum.SendEmail

    const showExportSegmentModal = () => {
      if (isPayloadEmpty) {
        openNotificationPopup('Empty segment', 'You cannot export an empty segment', 'warning')
      } else {
        motionStore.setDisplaySegmentOutputModal(true)
      }
    }

    const handleExportSegmentCancel = () => {
      motionStore.setDisplaySegmentOutputModal(false)
    }

    const isSendTestBtnDisabled = (): boolean => {
      if (!segmentBuilderData.payload || segmentBuilderData.payload === undefined) return true
      if (Object.keys(segmentBuilderData.payload).length < 1) return true
      if ((segmentBuilderData.payload as CreateActionsPayload).fields === undefined) return true

      const templateField = (segmentBuilderData.payload as CreateActionsPayload).fields.filter(
        (field) => field.key === 'template' || field.key === 'messageContent',
      )
      // Check for empty fields
      if (!templateField || templateField.length === 0) {
        return true
      }
      const isMessageObject =
        typeof templateField[0].value === 'object' &&
        templateField[0].value != null &&
        'messageContent' in templateField[0].value &&
        templateField[0].value.messageContent !== ''

      if (isMessageObject || (templateField[0].value && typeof templateField[0].value !== 'object')) return false

      return (
        !(segmentBuilderData.payload as CreateActionsPayload).fields.filter(
          (field) => field.key === 'slackMessageId',
        )[0]?.value ||
        !(segmentBuilderData.payload as CreateActionsPayload).fields.filter(
          (field) => field.key === 'slackMessageVersion',
        )[0]?.value ||
        !(segmentBuilderData.payload as CreateActionsPayload).fields.filter((field) => field.key === 'templateId')[0]
          ?.value ||
        !(segmentBuilderData.payload as CreateActionsPayload).fields.filter(
          (field) => field.key === 'templateVersion',
        )[0]?.value
      )
    }

    const tooltipTitle = () => {
      if (isSlackAction) {
        return 'Type a message to send a test message'
      }

      return 'Select a template to send a test email'
    }

    const testButtonText = () => {
      if (isSlackAction) {
        return 'Send Test Message'
      }

      return 'Send Test Email'
    }

    const handleClickOnActionTest = () => {
      if (isSlackAction) {
        setIsSentTestMesageModalOpen(true)
      }

      setIsSendTestEmailModalOpen(true)
    }

    return (
      <div className='segment-bottom'>
        {segmentBuilderData.name === 'Segment' ? (
          <>
            <Button
              type='secondary'
              text={'Reset'}
              size='XL'
              onClickHandler={handleReset}
              testId='reset-panel'
              disabled={isPayloadEmpty}
            />
            <div style={{ display: 'flex', gap: '12px' }}>
              <span>
                <Button
                  type='secondary'
                  text='Export segment (.csv)'
                  size='XL'
                  htmlType='submit'
                  onClickHandler={showExportSegmentModal}
                  testId='segment-output-btn'
                  disabled={isPayloadEmpty}
                />
              </span>
              <Button
                type='primary'
                text={'Save'}
                size='XL'
                htmlType='submit'
                onClickHandler={saveConfigData}
                testId='save-panel'
                disabled={isPayloadEmpty}
              />
              <SegmentExportModal
                isVisable={motionStore.segmentOutput.isModalDisplayed}
                handleCancelModal={handleExportSegmentCancel}
                handleSegmentExport={handleSegmentExport}
                isLoading={motionStore.segmentOutput.isLoading}
              />
            </div>
          </>
        ) : (
          <Button
            type='primary'
            text={'Close'}
            size='XL'
            htmlType='submit'
            onClickHandler={saveConfigData}
            testId='save-panel'
            disabled={isPayloadEmpty}
          />
        )}

        {(isEmaiTemplate || isSlackAction) && (
          <Tooltip title={isSendTestBtnDisabled() && tooltipTitle()} placement='topLeft'>
            <span>
              <Button
                type='secondary'
                text={testButtonText()}
                size='XL'
                htmlType='button'
                onClickHandler={handleClickOnActionTest}
                testId='send-test-email-btn'
                disabled={isSendTestBtnDisabled()}
              />
            </span>
          </Tooltip>
        )}
        {isEmaiTemplate && (
          <SendTestEmailModal
            isModalOpen={isSendTestEmailModalOpen}
            setIsModalOpen={setIsSendTestEmailModalOpen}
            htmlContent={template.htmlContent || ''}
            templateName={template.name || ''}
          />
        )}
        {isSlackAction && (
          <SendTestMessageModal
            isModalOpen={isSentTestMesageModalOpen}
            setIsModalOpen={setIsSentTestMesageModalOpen}
            configPanelPayload={payload as CreateActionsPayload}></SendTestMessageModal>
        )}

        {segmentBuilderData.type === NodeTypeEnum.Action && (
          <DynamicInputModal
            isModalOpen={isDynamicInputModalOpen}
            setIsModalOpen={setIsDynamicInputModalOpen}
            setPayload={setPayload as Dispatch<SetStateAction<CreateActionsPayload>>}
            payload={payload as CreateActionsPayload}
            defaultCurrentKey={currentDynamicInputModalKey}
            setCurrentDynamicInputModalKey={setCurrentDynamicInputModalKey}
          />
        )}
      </div>
    )
  },
)
SegmentBottom.displayName = 'SegmentBottom'
